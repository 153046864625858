import { SelectChangeEvent, Stack, SxProps, Theme } from '@mui/material';
import MinMaxFilter from './MinMaxFilter';
import SliderFilter from './SliderFilter';
import { useRecoilValue } from 'recoil';
import {
  Aggressor,
  FilterItem,
  FilterOperator,
  FilterValueFormat,
  OptionSaleType,
  OptionsFeedColumnKey,
  OptionTradeSide,
  OptionType,
} from 'types/optionsFeed';
import MultiOptionSelector from './MultiOptionSelector';
import MultiOptionAutocomplete from './MultiOptionAutocomplete';
import {
  OF_DEFAULT_FILTER_ID,
  OF_SCANNERS,
  TNS_OPT_SIDE_LABELS_MAP,
} from 'config/optionsFeed';
import { hiroSymbolsState, watchlistsState } from 'states';
import OptionsDropdownMultiSelector from './OptionsDropdownSelector';
import { useCallback, useMemo } from 'react';
import { addOrUpdateFilters, getFilterValue } from 'util/optionsFeed';
import { Equity, Scanner } from 'types';
import { tnsEquityScannersDataState } from 'states/optionsFeed';
import { getEquitiesForScanner } from 'util/equityhub';

interface FilterPanelProps {
  filters: FilterItem[];
  onChangeFilters: (newFilters: FilterItem[]) => void;
  noSym?: boolean;
  viewOnly?: boolean;
  sx?: SxProps<Theme>;
}

const MIN_DAYS = 0;
const MAX_DAYS = 1095; // 3 years

const MIN_PREM = 0;
const MAX_PREM = 2_000_000; // $2M

const minMaxFiltersConfig: {
  title: string;
  format: FilterValueFormat;
  minId: OF_DEFAULT_FILTER_ID;
  maxId: OF_DEFAULT_FILTER_ID;
  field: OptionsFeedColumnKey;
}[] = [
  {
    title: 'STRIKE',
    format: 'currency',
    minId: OF_DEFAULT_FILTER_ID.MinStrike,
    maxId: OF_DEFAULT_FILTER_ID.MaxStrike,
    field: OptionsFeedColumnKey.Strike,
  },
  {
    title: 'VOLUME',
    format: 'number',
    minId: OF_DEFAULT_FILTER_ID.MinVol,
    maxId: OF_DEFAULT_FILTER_ID.MaxVol,
    field: OptionsFeedColumnKey.Volume,
  },
  {
    title: 'O/I',
    format: 'number',
    minId: OF_DEFAULT_FILTER_ID.MinOI,
    maxId: OF_DEFAULT_FILTER_ID.MaxOI,
    field: OptionsFeedColumnKey.OI,
  },
  {
    title: 'SPOT',
    format: 'currency',
    minId: OF_DEFAULT_FILTER_ID.MinSpotPrice,
    maxId: OF_DEFAULT_FILTER_ID.MaxSpotPrice,
    field: OptionsFeedColumnKey.StockPrice,
  },
  {
    title: 'PRICE',
    format: 'currency',
    minId: OF_DEFAULT_FILTER_ID.MinOptPrice,
    maxId: OF_DEFAULT_FILTER_ID.MaxOptPrice,
    field: OptionsFeedColumnKey.Price,
  },
  {
    title: 'DELTA',
    format: 'number',
    minId: OF_DEFAULT_FILTER_ID.MinDelta,
    maxId: OF_DEFAULT_FILTER_ID.MaxDelta,
    field: OptionsFeedColumnKey.Delta,
  },
  {
    title: 'GAMMA',
    format: 'number',
    minId: OF_DEFAULT_FILTER_ID.MinGamma,
    maxId: OF_DEFAULT_FILTER_ID.MaxGamma,
    field: OptionsFeedColumnKey.Gamma,
  },
  {
    title: 'VEGA',
    format: 'number',
    minId: OF_DEFAULT_FILTER_ID.MinVega,
    maxId: OF_DEFAULT_FILTER_ID.MaxVega,
    field: OptionsFeedColumnKey.Vega,
  },
  {
    title: 'IV',
    format: 'percentage',
    minId: OF_DEFAULT_FILTER_ID.MinIV,
    maxId: OF_DEFAULT_FILTER_ID.MaxIV,
    field: OptionsFeedColumnKey.IVol,
  },
  {
    title: 'BID',
    format: 'currency',
    minId: OF_DEFAULT_FILTER_ID.MinBid,
    maxId: OF_DEFAULT_FILTER_ID.MaxBid,
    field: OptionsFeedColumnKey.Bid,
  },
  {
    title: 'ASK',
    format: 'currency',
    minId: OF_DEFAULT_FILTER_ID.MinAsk,
    maxId: OF_DEFAULT_FILTER_ID.MaxAsk,
    field: OptionsFeedColumnKey.Ask,
  },
];

const FilterPanel = ({
  filters,
  onChangeFilters,
  noSym,
  viewOnly,
  sx,
}: FilterPanelProps) => {
  const syms = useRecoilValue(hiroSymbolsState);
  const watchlists = useRecoilValue(watchlistsState);
  const eqScanners = useRecoilValue(tnsEquityScannersDataState);

  const onFilterItemChange = useCallback(
    (filterItem: FilterItem) => {
      const updatedFilters = addOrUpdateFilters(filters, [filterItem]);
      onChangeFilters(updatedFilters);
    },
    [filters, onChangeFilters],
  );

  const selectedWatchlists = useMemo(() => {
    const syms: string[] | undefined = filters.find(
      (f) => f.id === OF_DEFAULT_FILTER_ID.Symbols,
    )?.value as string[];

    if (syms && watchlists) {
      // a watchlist is considered "selected" if every symbol it contains is already added to the symbols filter
      return watchlists
        ?.filter((w) => w.symbols.every((s) => syms.includes(s)))
        .map((w) => `${w.id}`);
    }

    return [];
  }, [filters, watchlists]);

  const onWatchlistSelectChange = useCallback(
    (event: SelectChangeEvent<string | string[]>) => {
      const selectedWatchlistIds = event.target.value as string[];

      if (watchlists) {
        // Extract all watchlist syms
        const watchlistSyms: Set<string> = new Set(
          watchlists.flatMap((w) => w.symbols),
        );
        // Extract symbols from all selected watchlists
        const selectedSymbols = new Set(
          watchlists
            .filter((w) => selectedWatchlistIds.includes(`${w.id}`))
            .flatMap((w) => w.symbols),
        );

        // Check if the filter for symbols already exists
        const existingFilter = filters.find(
          (f) => f.id === OF_DEFAULT_FILTER_ID.Symbols,
        );

        // Safely handle the existing filter's value (ensure it's an array or use an empty array)
        const existingValue = (existingFilter?.value as string[]) || [];

        // Filter out symbols that are no longer in the selected watchlists
        const updatedSymbols = existingValue.filter(
          (symbol) => selectedSymbols.has(symbol) || !watchlistSyms.has(symbol),
        );

        // Add new symbols from the selected watchlists
        selectedSymbols.forEach((symbol) => updatedSymbols.push(symbol));

        // Create the updated filter array
        const updatedFilters = addOrUpdateFilters(filters, [
          {
            id: OF_DEFAULT_FILTER_ID.Symbols,
            field: OptionsFeedColumnKey.Sym,
            operator: FilterOperator.IsAnyOf,
            value: Array.from(new Set(updatedSymbols)), // Ensure uniqueness
          },
        ]);

        onChangeFilters(updatedFilters);
      }
    },
    [filters, watchlists, onChangeFilters],
  );

  const onSliderFilterChange = useCallback(
    (
      ids: OF_DEFAULT_FILTER_ID[],
      field: OptionsFeedColumnKey,
      newValue: number | number[],
    ) => {
      const filtersToUpdate = Array.isArray(newValue)
        ? [
            {
              id: ids[0],
              field,
              operator: FilterOperator.GreaterThanOrEqual,
              value: newValue[0] as number,
            },
            {
              id: ids[1],
              field,
              operator: FilterOperator.LessThanOrEqual,
              value: newValue[1] as number,
            },
          ]
        : [
            {
              id: ids[0],
              field,
              operator: FilterOperator.GreaterThanOrEqual,
              value: newValue as number,
            },
          ];

      const updatedFilters = addOrUpdateFilters(filters, filtersToUpdate);
      onChangeFilters(updatedFilters);
    },
    [filters, onChangeFilters],
  );

  const selectedScanners = useMemo(() => {
    const syms: string[] | undefined = filters.find(
      (f) => f.id === OF_DEFAULT_FILTER_ID.Symbols,
    )?.value as string[];

    if (syms && eqScanners) {
      // a scanner is considered "selected" if every symbol it contains is already added to the symbols filter
      return OF_SCANNERS.filter((sc) =>
        getEquitiesForScanner(sc.value, eqScanners).every((e: Equity) =>
          syms.includes(e.sym),
        ),
      ).map((sc) => sc.value);
    }

    return [];
  }, [filters, eqScanners]);

  const onScannerSelectChange = useCallback(
    (event: SelectChangeEvent<string | string[]>) => {
      const selectedScannerIds = event.target.value as Scanner[];

      if (selectedScannerIds) {
        // Get all scanner syms
        const scannerSyms: Set<string> = new Set(
          OF_SCANNERS.flatMap((sc) =>
            getEquitiesForScanner(sc.value, eqScanners),
          ).map((eq) => eq.sym),
        );
        // Get unique symbols for all scanners that are currently selected
        const selectedSymbols = new Set(
          selectedScannerIds
            .flatMap((scannerId) =>
              getEquitiesForScanner(scannerId, eqScanners),
            )
            .map((equity: Equity) => equity.sym),
        );

        // Check if the filter for symbols already exists
        const existingFilter = filters.find(
          (f) => f.id === OF_DEFAULT_FILTER_ID.Symbols,
        );

        // Safely handle the existing filter's value (ensure it's an array or use an empty array)
        const existingValue = (existingFilter?.value as string[]) || [];

        // Filter out symbols that are no longer in the selected scanners
        const updatedSymbols = existingValue.filter(
          (symbol) => selectedSymbols.has(symbol) || !scannerSyms.has(symbol),
        );

        // Add new symbols from the selected scanners
        selectedSymbols.forEach((symbol) => updatedSymbols.push(symbol));

        // Create the updated filter array
        const updatedFilters = addOrUpdateFilters(filters, [
          {
            id: OF_DEFAULT_FILTER_ID.Symbols,
            field: OptionsFeedColumnKey.Sym,
            operator: FilterOperator.IsAnyOf,
            value: Array.from(new Set(updatedSymbols)), // Ensure uniqueness
          },
        ]);

        onChangeFilters(updatedFilters);
      }
    },
    [filters, eqScanners, onChangeFilters],
  );

  // Helper function to handle filter changes for min values
  const handleMinChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: OptionsFeedColumnKey,
    minId: OF_DEFAULT_FILTER_ID,
  ) => {
    onChangeFilters(
      addOrUpdateFilters(filters, [
        {
          field,
          value: event.target.value,
          id: minId,
          operator: FilterOperator.GreaterThanOrEqual,
        },
      ]),
    );
  };

  // Helper function to handle filter changes for max values
  const handleMaxChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: OptionsFeedColumnKey,
    maxId: OF_DEFAULT_FILTER_ID,
  ) => {
    onChangeFilters(
      addOrUpdateFilters(filters, [
        {
          field,
          value: event.target.value,
          id: maxId,
          operator: FilterOperator.LessThanOrEqual,
        },
      ]),
    );
  };

  return (
    <Stack
      gap={5}
      sx={{
        ...sx,
      }}
    >
      {!noSym && (
        <>
          <MultiOptionAutocomplete
            options={[...syms]}
            value={
              (filters.find((f) => f.id === OF_DEFAULT_FILTER_ID.Symbols)
                ?.value as string[]) ?? []
            }
            onChange={(_event: React.SyntheticEvent, value: string[]) =>
              onFilterItemChange({
                id: OF_DEFAULT_FILTER_ID.Symbols,
                value,
                operator: FilterOperator.IsAnyOf,
                field: OptionsFeedColumnKey.Sym,
              })
            }
            viewOnly={viewOnly}
          />
          <Stack direction="row" gap={2} alignItems="center" width="100%">
            <OptionsDropdownMultiSelector
              label="Watchlist"
              value={selectedWatchlists}
              options={
                watchlists?.map((w) => ({
                  label: w.name,
                  value: `${w.id!}`,
                })) ?? []
              }
              isMultiple
              onChange={onWatchlistSelectChange}
              viewOnly={viewOnly}
            />
            <OptionsDropdownMultiSelector
              label="Scanner"
              value={selectedScanners}
              viewOnly={viewOnly}
              options={OF_SCANNERS}
              isMultiple
              onChange={onScannerSelectChange}
            />
          </Stack>
        </>
      )}
      <SliderFilter
        viewOnly={viewOnly}
        header="EXPIRATION"
        name="expiration"
        value={[
          getFilterValue<number>(
            filters,
            OF_DEFAULT_FILTER_ID.MinExp,
            MIN_DAYS,
          ),
          getFilterValue<number>(
            filters,
            OF_DEFAULT_FILTER_ID.MaxExp,
            MAX_DAYS,
          ),
        ]}
        min={MIN_DAYS}
        max={MAX_DAYS}
        valueFormat="date"
        onChange={(newValue: number | number[]) =>
          onSliderFilterChange(
            [OF_DEFAULT_FILTER_ID.MinExp, OF_DEFAULT_FILTER_ID.MaxExp],
            OptionsFeedColumnKey.Expiration,
            newValue,
          )
        }
      />
      <SliderFilter
        viewOnly={viewOnly}
        header="MIN PREMIUM"
        name="premium"
        value={getFilterValue<number>(
          filters,
          OF_DEFAULT_FILTER_ID.Premium,
          MIN_PREM,
        )}
        min={MIN_PREM}
        max={MAX_PREM}
        valueFormat="currency"
        onChange={(newValue: number | number[]) =>
          onSliderFilterChange(
            [OF_DEFAULT_FILTER_ID.Premium],
            OptionsFeedColumnKey.Premium,
            newValue,
          )
        }
      />
      <MultiOptionSelector
        viewOnly={viewOnly}
        title="SIDE"
        options={Object.values(OptionTradeSide).map((ots) => ({
          label: TNS_OPT_SIDE_LABELS_MAP.get(ots)!,
          value: ots,
        }))}
        selectedOptions={getFilterValue<string[]>(
          filters,
          OF_DEFAULT_FILTER_ID.Side,
          [],
        )}
        onSelect={(selectedOptions: string[]) =>
          onFilterItemChange({
            id: OF_DEFAULT_FILTER_ID.Side,
            value: selectedOptions,
            operator: FilterOperator.IsAnyOf,
            field: OptionsFeedColumnKey.Side,
          })
        }
      />
      <MultiOptionSelector
        viewOnly={viewOnly}
        title="AGRESSOR"
        options={[Aggressor.BUY, Aggressor.SELL].map((a) => ({
          label: a,
          value: a,
        }))}
        selectedOptions={getFilterValue<string[]>(
          filters,
          OF_DEFAULT_FILTER_ID.Aggressor,
          [],
        )}
        onSelect={(selectedOptions: string[]) =>
          onFilterItemChange({
            id: OptionsFeedColumnKey.Aggressor,
            value: selectedOptions,
            operator: FilterOperator.IsAnyOf,
            field: OptionsFeedColumnKey.Aggressor,
          })
        }
      />
      <MultiOptionSelector
        viewOnly={viewOnly}
        title="CALL/PUT"
        options={Object.values(OptionType).map((ot) => ({
          label: ot,
          value: ot,
        }))}
        selectedOptions={getFilterValue<string[]>(
          filters,
          OF_DEFAULT_FILTER_ID.CP,
          [],
        )}
        onSelect={(selectedOptions: string[]) =>
          onFilterItemChange({
            id: OF_DEFAULT_FILTER_ID.CP,
            value: selectedOptions,
            operator: FilterOperator.IsAnyOf,
            field: OptionsFeedColumnKey.CP,
          })
        }
      />
      {minMaxFiltersConfig.map(({ title, format, minId, maxId, field }) => (
        <MinMaxFilter
          key={`${title}-${minId}-${maxId}`}
          viewOnly={viewOnly}
          title={title}
          format={format}
          minValue={getFilterValue<string>(filters, minId, '')}
          maxValue={getFilterValue<string>(filters, maxId, '')}
          minPlaceholder={
            format === 'currency' ? '$0' : format === 'percentage' ? '0%' : '0'
          }
          maxPlaceholder={
            format === 'currency' ? '$∞' : format === 'percentage' ? '∞%' : '∞'
          }
          onChangeMin={(event) => handleMinChange(event, field, minId)}
          onChangeMax={(event) => handleMaxChange(event, field, maxId)}
        />
      ))}
      <MultiOptionSelector
        viewOnly={viewOnly}
        title="FLAG"
        options={Object.values(OptionSaleType).map((ost) => ({
          label: ost.toLowerCase(),
          value: ost,
        }))}
        selectedOptions={getFilterValue<string[]>(
          filters,
          OF_DEFAULT_FILTER_ID.Flag,
          [],
        )}
        onSelect={(selectedOptions: string[]) =>
          onFilterItemChange({
            id: OF_DEFAULT_FILTER_ID.Flag,
            value: selectedOptions,
            operator: FilterOperator.IsAnyOf,
            field: OptionsFeedColumnKey.SaleType,
          })
        }
      />
    </Stack>
  );
};

export default FilterPanel;
