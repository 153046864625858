import * as d3 from 'd3';
import { OIEntity } from '../../types';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { Stack, Typography } from '@mui/material';
import { SGTooltip } from '../../components/core';
import { useMemo } from 'react';
import { nonProdDebugLog, predicateSearch } from '../../util';

type TraceVolSliderProps = {
  statsData: any;
  gammaAtLastPriceUninverted: number | undefined;
  parquetKey: OIEntity;
  width: number;
  height: number;
  fontSize?: number;
  suppress?: boolean;
};

const MEAN_KEY = 'vol_10m_mean';
const BIN_MEAN_KEY = 'mean';

export const TraceVolGauge = ({
  statsData,
  gammaAtLastPriceUninverted,
  parquetKey,
  width,
  height,
  fontSize = 12,
  suppress = false,
}: TraceVolSliderProps) => {
  const stabilityPercentile = useMemo(() => {
    const gammaVol = statsData?.['gamma_vol'];
    const volData =
      gammaVol?.['90']?.[`${parquetKey}_gamma`] ??
      gammaVol?.['60']?.[`${parquetKey}_gamma`];
    if (gammaAtLastPriceUninverted == null || volData == null) {
      return null;
    }

    let volMax = -Infinity;
    let volMin = Infinity;

    const volDataSortedArr = Object.keys(volData)
      .sort((a, b) => (parseFloat(a) > parseFloat(b) ? 1 : -1))
      .map((key) => ({ ...volData[key], key }));

    const binMeans = volDataSortedArr.map((o) => o[BIN_MEAN_KEY]);
    const idxA = predicateSearch(
      binMeans,
      (s) => s <= gammaAtLastPriceUninverted,
    );
    const idxB = Math.min(idxA + 1, binMeans.length - 1);
    const interpolate = d3.interpolate(
      volDataSortedArr[idxA][MEAN_KEY],
      volDataSortedArr[idxB][MEAN_KEY],
    );

    // If idxA == idxB, the fraction doesn't matter, just make sure we don't divide by zero.
    const fraction =
      (gammaAtLastPriceUninverted - binMeans[idxA]) /
      (idxA === idxB ? 1 : binMeans[idxB] - binMeans[idxA]);
    const volMean = interpolate(fraction);

    for (const volObj of volDataSortedArr) {
      volMin = Math.min(volMin, volObj[MEAN_KEY]);
      volMax = Math.max(volMax, volObj[MEAN_KEY]);
    }

    const volPercentile = (volMean - volMin) / (volMax - volMin);
    nonProdDebugLog(
      'stats: using last directional gamma uninverted',
      gammaAtLastPriceUninverted,
    );
    nonProdDebugLog('stats: vol data arr sorted', volDataSortedArr);
    nonProdDebugLog(
      'stats: interpolated vol mean, min, max, stability percentile',
      volMean,
      volMin,
      volMax,
      1 - volPercentile,
    );
    // stability is inverted vol
    return 1 - volPercentile;
  }, [statsData, gammaAtLastPriceUninverted, parquetKey]);

  if (stabilityPercentile == null) {
    return null;
  }

  const opts = suppress ? { opacity: 0.5 } : {};
  return (
    <SGTooltip
      title={
        'The SpotGamma Stability Indicator is a proprietary measure of how stable (less prone to sudden moves) ' +
        'the market is likely to be in the next 60 minutes. ' +
        'The higher the value, the more stable, and vice versa.'
      }
    >
      <Stack
        direction="column"
        alignItems="center"
        height={height + 10}
        sx={opts}
      >
        <Gauge
          width={width}
          height={height}
          value={stabilityPercentile * 100}
          startAngle={-90}
          endAngle={90}
          valueMin={0}
          valueMax={100}
          text={({ value }) => (value != null ? `${Math.round(value)}%` : '')}
          sx={(theme) => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: fontSize + 2,
              transform: 'translate(0px, -6px)',
            },
            [`& .${gaugeClasses.referenceArc}`]: {
              fill: theme.palette.text.disabled,
            },
          })}
        />
        <Typography sx={{ fontSize }}>Stability</Typography>
      </Stack>
    </SGTooltip>
  );
};
