import * as arrow from 'apache-arrow';
import Plotly from 'plotly.js';
import {
  ET,
  getDateFormatted,
  nonProdDebugLog,
  ONE_HOUR_MS,
  ONE_MIN_MS,
  predicateSearch,
  roundToNearest,
  roundUpToNearest,
  stockMarketClose,
  stockMarketOpen,
} from './shared';
import dayjs from 'dayjs';
import {
  IntradayGammaLense,
  IntradayStrikeBarType,
  OIScaleRange,
  PRICE_BOUNDS,
  PriceCandle,
  PriceCandleWithDateTime,
  StrikeBarsDailyTracker,
  StrikeBarsDailyTrackerEntry,
  TraceContourData,
  TraceGreek,
  ZoomData,
} from '../types';
import {
  DEFAULT_MAX_AGE_MS,
  DEFAULT_MAX_AGE_PADDING_MS,
  HEATMAP_TRACE_NAME,
  HIRO_TRACE_NAME,
  IntradayFiltersAxisLabels,
  IntradayFiltersStatsInnerKeys,
  IntradayFiltersStatsKeys,
  PRICE_CANDLES_TRACE_NAME,
} from '../config/oi';
import { HeatmapColorSettings, StrikeBarColorSettings } from '../theme';
import { isFinite } from 'lodash';
import { readParquet } from 'parquet-wasm';

const HIRO_Y_AXIS_BASE_PADDING = 250_000_000;

// TODO: get actual numbers from brent/john. these were just guessed
const LENSE_BASE_COLORSCALE_RANGES = new Map([
  [IntradayGammaLense.GAMMA, 500_000_000], //500m
  [IntradayGammaLense.DELTA, 50_000_000_000], //50b
  [IntradayGammaLense.GAMMA_COLOR, 10_000_000], //10m
  [IntradayGammaLense.DELTA_CHARM_DIRECTIONAL, 10_000_000], //10m
  [IntradayGammaLense.DELTA_END_DIFF, 5_000_000_000], //5b
  [IntradayGammaLense.DELTA_DIRECTIONAL, 5_000_000_000], //5b
  [IntradayGammaLense.GAMMA_DIRECTIONAL, 100_000_000], //100m
]);

export type TraceStrikeBarsMapStore = {
  colors: string[];
  x: number[];
  y: number[];
};

export function getStrikeBarsData(
  table: arrow.Table,
  invert: boolean,
  netParquetKeys: string[],
  timestamp: dayjs.Dayjs | null,
  negGammaColor: string,
  posGammaColor: string,
  zeroDTE: boolean,
  strikeBarType: IntradayStrikeBarType,
  heatmapColorSettings: HeatmapColorSettings,
  startTs: number,
  endTs: number,
): {
  latestTimestamp?: number;
  allDataByTs?: Map<number, Map<string, TraceStrikeBarsMapStore>>;
} {
  if (strikeBarType === IntradayStrikeBarType.NONE) {
    return {};
  }

  const array = table.toArray();
  if (array.length === 0 || timestamp == null) {
    nonProdDebugLog(
      'TRACE: something gex related is null, returning early',
      array,
      timestamp,
    );
    return {};
  }

  const allDataByTs: Map<
    number,
    Map<string, TraceStrikeBarsMapStore>
  > = new Map();

  const start =
    startTs === 0
      ? 0
      : predicateSearch(array, (e) => e.timestamp < startTs) + 1;
  const end = predicateSearch(array, (e) => e.timestamp <= endTs);
  const data = array.slice(start, Math.max(0, end) + 1);

  for (const e of data) {
    const strike = e.strike_price;
    const values = getGexValues(
      e,
      netParquetKeys,
      invert,
      zeroDTE,
      strikeBarType,
      heatmapColorSettings,
    );
    for (const entry of values) {
      const mapToUse = allDataByTs.get(e.timestamp) ?? new Map();
      const curr = mapToUse.get(entry.name) ?? {
        colors: [],
        x: [],
        y: [],
      };
      curr.x.push(entry.value);
      curr.y.push(strike);
      curr.colors.push(
        entry.color ?? (entry.value >= 0 ? posGammaColor : negGammaColor),
      );
      allDataByTs.set(e.timestamp, mapToUse.set(entry.name, curr));
    }
  }

  const latestTimestamp = array[array.length - 1].timestamp;
  nonProdDebugLog(
    'TRACE: gex latest timestamp available',
    dayjs(latestTimestamp),
  );

  return { latestTimestamp, allDataByTs };
}

export const convertStrikeBarsDataToChart = (
  allDataByTs: Map<number, Map<string, TraceStrikeBarsMapStore>>,
  latestTimestamp: number,
  strikeBarType: IntradayStrikeBarType,
  min: number,
  max: number,
): Plotly.Data[] => {
  const currMap = allDataByTs.get(latestTimestamp);
  return currMap == null
    ? []
    : [...currMap?.keys()].map((traceName: string) => {
        const traceData = currMap.get(traceName)!;
        const minX =
          predicateSearch(
            traceData.y,
            (d) => d < min - maxStrikeBarY3Offset(strikeBarType),
          ) + 1;
        const maxX = predicateSearch(
          traceData.y,
          (d) => d <= max + maxStrikeBarY3Offset(strikeBarType),
        );
        nonProdDebugLog(
          'TRACE: using minX and maxX for strike bars',
          minX,
          maxX,
          min,
          max,
          traceData,
        );
        return {
          x: traceData.x.slice(minX, maxX + 1),
          y: traceData.y
            .slice(minX, maxX + 1)
            .map((v) => v + strikeBarTraceNameYOffset(traceName)),
          xaxis: 'x2',
          yaxis: 'y',
          orientation: 'h',
          type: 'bar',
          marker: {
            color: traceData.colors.slice(minX, maxX + 1),
          },
          name: traceName,
          hoverinfo: 'none',
          width: strikeBarType === IntradayStrikeBarType.GAMMA ? undefined : 1,
        } as Plotly.Data;
      });
};

export const convertStrikeBarsTrackerToChart = (
  tracker: StrikeBarsDailyTracker | null,
  colorSettings: StrikeBarColorSettings,
  min: number,
  max: number,
): Plotly.Data[] => {
  if (tracker == null) {
    return [];
  }

  const entries = tracker.entriesPerTrace;
  const traces = [...entries.keys()];

  const traceForKey = (key: keyof StrikeBarsDailyTrackerEntry) => {
    const useBar = ['dailyMax'].includes(key);
    const options = useBar
      ? { type: 'bar', width: 0.05 }
      : { type: 'scatter', mode: 'markers' };

    return traces.map((traceName: string) => {
      const traceData = entries.get(traceName)!;
      const strikes = [...traceData.keys()].filter(
        (strike) => strike >= min && strike <= max,
      );
      const bases: number[] = [];
      const x = strikes.flatMap((strike) => {
        const entry = traceData.get(strike)!;
        let val = entry[key];
        if (val == null) {
          return [];
        }

        if (useBar) {
          const offset = entry['dailyMin']!;
          bases.push(offset);
          val -= offset;
        }

        return [val];
      });

      return {
        x: x,
        y: strikes.map((v) => v + strikeBarTraceNameYOffset(traceName)),
        xaxis: 'x2',
        yaxis: 'y',
        marker: {
          color: colorSettings.tracker[key] ?? '#fff',
          // shrink the dots a bit based on zoom level (using max-min to determine that) so that the dots dont
          // make the bars hard to read. we use traces.length because some strike bar types
          // show more bars per strike than others, and for those types the dots will need to be smaller
          size: Math.min(8, 2000 / (max - min) / traces.length),
        },
        // @ts-ignore
        base: bases.length > 0 ? bases : undefined,
        hoverinfo: 'none',
        orientation: 'h',
        name: traceName,
        showlegend: false,
        showscale: false,
        ...options,
      } as Plotly.Data;
    });
  };

  const keys: (keyof StrikeBarsDailyTrackerEntry)[] = [
    'last',
    'thirtyMin',
    'hour',
    'dailyMax',
    // dailyMax shows both the min and max, since they need to be one bar
  ];
  return keys.flatMap((key) => traceForKey(key));
};

const getGexValues = (
  data: any,
  netParquetKeys: string[],
  invert: boolean,
  zeroDTE: boolean,
  strikeBarType: IntradayStrikeBarType,
  heatmapColorSettings: HeatmapColorSettings,
): { name: string; value: number; color?: string }[] => {
  switch (strikeBarType) {
    case IntradayStrikeBarType.GAMMA:
      return [
        {
          name: strikeBarTraceName(strikeBarType),
          value: getTableValue(
            data,
            netParquetKeys,
            greekForLense(IntradayGammaLense.GAMMA),
            invert,
            zeroDTE,
          ),
        },
      ];
    case IntradayStrikeBarType.OI:
    case IntradayStrikeBarType.OI_NET:
      const buy = getTableValue(
        data,
        netParquetKeys,
        invert ? 'sell_oi' : 'buy_oi',
        false,
        zeroDTE,
      );
      const sell = getTableValue(
        data,
        netParquetKeys,
        invert ? 'buy_oi' : 'sell_oi',
        false,
        zeroDTE,
      );
      const color = data.is_call
        ? heatmapColorSettings.callColor
        : heatmapColorSettings.putColor;
      if (strikeBarType === IntradayStrikeBarType.OI_NET) {
        return [
          {
            name: strikeBarTraceName(strikeBarType, data.is_call),
            value: buy - sell,
            color,
          },
        ];
      }

      return [
        {
          name: strikeBarTraceName(strikeBarType, data.is_call, true),
          value: buy,
          color,
        },
        {
          name: strikeBarTraceName(strikeBarType, data.is_call, false),
          value: -sell,
          color,
        }, // in non-net view, sell should be negative
      ];
    case IntradayStrikeBarType.NONE:
      return [];
  }
};

export const strikeBarTraceName = (
  strikeBarType: IntradayStrikeBarType,
  isCall?: boolean,
  long?: boolean,
) => {
  if (strikeBarType === IntradayStrikeBarType.GAMMA) {
    return 'GEX';
  }

  const type = isCall ? 'Call' : 'Put';
  if (strikeBarType === IntradayStrikeBarType.OI_NET) {
    return `Net ${type} Position`;
  }

  return `${long ? 'Long' : 'Short'} ${type}s`;
};

export const strikeBarTraceNameYOffset = (traceName: string) => {
  // different traces have different offsets here because of their different relative
  // y position to the y axis strike coordinate. i.e. 5500 Long Puts and 5500 Long Calls are
  // offset at different amounts relative to the 5500 y coordinate
  switch (traceName) {
    case strikeBarTraceName(IntradayStrikeBarType.OI, true, true):
    case strikeBarTraceName(IntradayStrikeBarType.OI_NET, true):
      return -0.5;
    case strikeBarTraceName(IntradayStrikeBarType.OI, true, false):
      return -1;
    case strikeBarTraceName(IntradayStrikeBarType.OI, false, false):
    case strikeBarTraceName(IntradayStrikeBarType.OI_NET, false):
      return 0.5;
    case strikeBarTraceName(IntradayStrikeBarType.OI, false, true):
      return 1;
    default:
      return 0;
  }
};

export const maxStrikeBarY3Offset = (type: IntradayStrikeBarType) => {
  return Math.abs(
    strikeBarTraceNameYOffset(strikeBarTraceName(type, false, false)),
  );
};

const getTableValue = (
  e: any,
  netParquetKeys: string[],
  suffix: string,
  invert: boolean,
  zeroDTE?: boolean,
) => {
  const value = netParquetKeys.reduce(
    (tot, k) => tot + e[tableKeyForLense(k, suffix, zeroDTE)],
    0,
  );
  return invert ? -value : value;
};

export function getContourData(
  table: arrow.Table,
  timestamp: number | undefined,
  netParquetKeys: string[],
  invert: boolean = false,
  priceBounds: number[] | null,
  offsetMs: number,
  filter: IntradayGammaLense,
  lastPrice: PriceCandleWithDateTime | undefined,
  chartWidth: number,
): TraceContourData | null {
  const array = table.toArray();
  if (array.length === 0) {
    return null;
  }

  let { x, y, z, rawChartTimes, data } = parseContourArray(
    array,
    timestamp,
    netParquetKeys,
    invert,
    priceBounds,
    offsetMs,
    filter,
  );

  nonProdDebugLog(
    `TRACE before filter ${filter}: contour data with timestamp ${timestamp}, lastPrice ${lastPrice}:`,
  );
  nonProdDebugLog(x, y, z);

  if (z.length > 0) {
    if (
      filter === IntradayGammaLense.DELTA_DIRECTIONAL ||
      filter === IntradayGammaLense.GAMMA_DIRECTIONAL ||
      filter === IntradayGammaLense.DELTA_END_DIFF
    ) {
      if (lastPrice != null) {
        const lastPriceGreekValue = getGreekValueAtLastPrice(
          y,
          z,
          rawChartTimes,
          lastPrice,
          filter,
        );
        z = applyDirectionalFilter(
          z,
          lastPriceGreekValue,
          greekForLense(filter) === 'delta',
        );
      }
    } else if (
      filter === IntradayGammaLense.GAMMA_COLOR ||
      filter === IntradayGammaLense.DELTA_CHARM_DIRECTIONAL
    ) {
      let idxDistanceBetweenStrikes: number = -1;
      for (let i = 1; i < data.length; i++) {
        if (data[i].time !== data[i - 1].time) {
          idxDistanceBetweenStrikes = i;
          break;
        }
      }
      let timeBetweenStampsMs: number[] = [];
      // For every time bucket, send down the difference between buckets
      for (
        let i = 0;
        i + idxDistanceBetweenStrikes < data.length;
        i += idxDistanceBetweenStrikes
      ) {
        timeBetweenStampsMs.push(
          data[i + idxDistanceBetweenStrikes].time - data[i].time,
        );
      }

      z = applyColorCharmFilter(
        z,
        rawChartTimes,
        idxDistanceBetweenStrikes,
        timeBetweenStampsMs,
        filter,
      );
    }
  }

  const type = 'contour';
  nonProdDebugLog(
    `after filter ${filter}: contour data with timestamp ${timestamp}, lastPrice ${lastPrice}:`,
  );
  nonProdDebugLog(x, y, z);

  // @ts-ignore
  return {
    chartData: {
      x,
      y,
      z,
      // @ts-ignore
      type,
      colorbar: shouldShowAxisLabels(chartWidth)
        ? {
            title: IntradayFiltersAxisLabels.get(filter)!,
            titleside: 'right',
          }
        : {},
      hoverinfo: 'none',
      name: HEATMAP_TRACE_NAME,
    },
    firstChartTimestamp: rawChartTimes[0],
  };
}

export const parseContourArray = (
  array: any[],
  timestamp: number | undefined,
  netParquetKeys: string[],
  invert: boolean = false,
  priceBounds: number[] | null,
  offsetMs: number,
  filter: IntradayGammaLense,
) => {
  // Filter down to the timestamp in question. Using binary search cuts filter time significantly
  const start = predicateSearch(array, (e) => e.timestamp < timestamp!) + 1;
  const end = predicateSearch(array, (e) => e.timestamp <= timestamp!);
  let data = array.slice(start, Math.max(0, end) + 1);
  if (priceBounds != null) {
    data = data.filter(
      (e) => e.spot >= priceBounds[0] && e.spot <= priceBounds[1],
    );
  }

  const rawChartTimes: number[] = [];
  const x: Date[] = [];
  const y: number[] = [];
  let z: number[] = [];

  for (const e of data) {
    x.push(new Date(e.time + offsetMs));
    y.push(Number(e.spot));
    const value = getTableValue(
      e,
      netParquetKeys,
      greekForLense(filter),
      invert,
    );
    z.push(value);
    rawChartTimes.push(e.time);
  }

  return { x, y, z, rawChartTimes, data };
};

export const getGreekValueAtLastPrice = (
  y: number[],
  z: number[],
  rawChartTimes: number[],
  lastPrice: PriceCandleWithDateTime,
  filter: IntradayGammaLense,
) => {
  const lastChartTime = rawChartTimes[rawChartTimes.length - 1];
  const marketClose = stockMarketClose(dayjs(lastChartTime)).valueOf();
  let lastTime = lastPrice.datetime.valueOf();
  if (filter === IntradayGammaLense.DELTA_END_DIFF) {
    const times = [...new Set(rawChartTimes)];
    const idx = predicateSearch(times, (t) => t < marketClose);
    lastTime = times[idx]; // last time before market close
  }

  return getGreekValueAtPrice(lastPrice?.close, rawChartTimes, y, z, lastTime);
};

export const getContourColorOptions = (
  z: any,
  negativeTrendColor: string,
  positiveTrendColor: string,
  zeroColor: string,
  filter: IntradayGammaLense,
  selectedScaleRange: OIScaleRange,
) => {
  const colorscale = [
    [0, negativeTrendColor],
    [0.5, zeroColor],
    [1, positiveTrendColor],
  ];

  const contours = {
    coloring: 'heatmap',
    ...predefinedContourColorRange(selectedScaleRange, filter, z),
  };

  return { contours, colorscale };
};

const predefinedContourColorRange = (
  selectedScaleRange: OIScaleRange,
  selectedLense: IntradayGammaLense,
  values: number[],
): { start: number; end: number } | {} => {
  if (selectedScaleRange === OIScaleRange.AUTO) {
    const max = values.reduce((a, b) => Math.max(Math.abs(a), Math.abs(b)));
    return { start: -max, end: max };
  }

  const rangeMinMax =
    LENSE_BASE_COLORSCALE_RANGES.get(selectedLense) ?? 50_000_000;

  const multiplier = multiplierForScaleRange(selectedScaleRange);
  return {
    start: rangeMinMax * multiplier * -1,
    end: rangeMinMax * multiplier,
  };
};

const multiplierForScaleRange = (selectedScaleRange: OIScaleRange) => {
  if (selectedScaleRange === OIScaleRange.LOW) {
    return 0.5;
  } else if (selectedScaleRange === OIScaleRange.HIGH) {
    return 2;
  }

  return 1;
};

const getGreekValueAtPrice = (
  lastPrice: number | undefined,
  chartTimes: number[],
  strikes: number[],
  values: number[],
  timestamp: number | undefined,
) => {
  if (lastPrice == null || timestamp == null) {
    return undefined;
  }

  for (let i = strikes.length - 1; i > 0; i--) {
    if (timestamp < chartTimes[i]) {
      // use < instead of == because sometimes the timestamp after market will
      // be well after the 4:30pm max we show on the chart
      continue;
    }

    if (lastPrice >= strikes[i]) {
      return values[i];
    } else if (strikes[i] >= lastPrice && strikes[i - 1] <= lastPrice) {
      // calculate gamma by finding the closest strikes and doing offset math if necessary to find out where the gamma
      // at the current price would lie in the range of the values of the 2 closest strikes
      const valueDiff = values[i] - values[i - 1];
      const strikeDiff = strikes[i] - strikes[i - 1];

      return (
        values[i - 1] + valueDiff * ((lastPrice - strikes[i - 1]) / strikeDiff)
      );
    }
  }

  nonProdDebugLog(`using greek value for last price ${lastPrice}:`, values[0]);

  return values[0];
};

const applyDirectionalFilter = (
  z: number[],
  lastPriceGreekValue: number | undefined,
  invert: boolean,
) => {
  if (lastPriceGreekValue == null) {
    // dont throw here as this may trigger once without lastPrice set as the react state change bubbles through
    return z;
  }

  return z.map((val) => (val - lastPriceGreekValue) * (invert ? -1 : 1));
};

const applyColorCharmFilter = (
  z: number[],
  chartTimes: number[],
  idxDistanceBetweenStrikes: number,
  timeBetweenStampsMs: number[],
  filter: IntradayGammaLense,
) => {
  if (idxDistanceBetweenStrikes < 0 || !(timeBetweenStampsMs?.length > 0)) {
    throw new Error(
      idxDistanceBetweenStrikes < 0
        ? 'Unable to calculate idx distance between strikes.'
        : 'Unable to calculate time between chartTimes.',
    );
  }

  const date = dayjs(chartTimes[chartTimes.length - 1]);
  const marketOpen = stockMarketOpen(date).valueOf();
  const marketClose = stockMarketClose(date).valueOf();

  return z.map((val, idx) => {
    if (
      idx - idxDistanceBetweenStrikes < 0 ||
      chartTimes[idx] === marketOpen ||
      chartTimes[idx] === marketClose
    ) {
      return 0;
    }
    const timeIdx = Math.floor(
      (idx - idxDistanceBetweenStrikes) / idxDistanceBetweenStrikes,
    );
    const timeBetweenStampsMins = timeBetweenStampsMs[timeIdx] / 60_000;
    const value =
      (val - z[idx - idxDistanceBetweenStrikes]) / timeBetweenStampsMins;
    // if charm pressure, we need to invert the value here since it shows the change in delta
    // to show change in buying pressure, it needs to be inverted
    return filter === IntradayGammaLense.DELTA_CHARM_DIRECTIONAL
      ? -value
      : value;
  });
};

// Convert price candles into Plotly trace
export function getCandles(
  candles: PriceCandleWithDateTime[],
  timestamp: number | undefined,
  offsetMs: number,
  colorSettings: HeatmapColorSettings,
): Plotly.Data {
  candles = timestamp
    ? candles.filter((c) => c.datetime.valueOf() <= timestamp)
    : candles;
  const x = candles.map((c) => new Date(c.datetime.valueOf() + offsetMs));
  const open = candles.map((c) => c.open);
  const high = candles.map((c) => c.high);
  const low = candles.map((c) => c.low);
  const close = candles.map((c) => c.close);
  return {
    // @ts-ignore
    x,
    open,
    high,
    low,
    close,
    xaxis: 'x',
    type: 'candlestick',
    hoverinfo: 'skip',
    name: PRICE_CANDLES_TRACE_NAME,
    // @ts-ignore
    increasing: {
      fillcolor: colorSettings.candleUpFillColor,
      line: { color: colorSettings.candleUpLineColor },
    },
    decreasing: {
      fillcolor: colorSettings.candleDownFillColor,
      line: { color: colorSettings.candleDownLineColor },
    },
    line: { width: 2 },
  } as any;
}

export function getHiro(
  candles: PriceCandle[], // hiro does not use the 'useDayjs' useStreaming param yet, so it does not have datetime
  timestamp: number | undefined,
  offsetMs: number,
  color: string,
  symbol: string,
): { chartData: Plotly.Data; min: number; max: number } | null {
  const x = [];
  const y = [];
  let min = Infinity;
  let max = -1;

  candles = timestamp
    ? candles.filter((c) => c.time * 1000 <= timestamp)
    : candles;

  if (candles.length === 0 || symbol.length === 0) {
    return null;
  }

  for (const candle of candles) {
    x.push(new Date(candle.time * 1000 + offsetMs));
    y.push(candle.close);
    min = Math.min(candle.close, min);
    max = Math.max(candle.close, max);
  }

  return {
    chartData: {
      x,
      y,
      yaxis: 'y2',
      xaxis: 'x',
      type: 'scatter',
      line: { width: 2, color },
      mode: 'lines',
      name: HIRO_TRACE_NAME,
      hoverinfo: 'skip',
    },
    min,
    max,
  };
}

export const greekForLense = (lense: IntradayGammaLense) => {
  switch (lense) {
    case IntradayGammaLense.GAMMA:
    case IntradayGammaLense.GAMMA_DIRECTIONAL:
    case IntradayGammaLense.GAMMA_COLOR:
      return TraceGreek.Gamma;
    default:
      return TraceGreek.Delta;
  }
};

export function convertTwelveCandles(
  candles: any[],
  tz: string,
): PriceCandleWithDateTime[] {
  return candles?.map((c) => {
    const dt = dayjs.tz(c.datetime, tz);
    return {
      ...c,
      datetime: dayjs.tz(c.datetime, tz),
      time: dt.unix(), // secs
      open: parseFloat(c.open),
      high: parseFloat(c.high),
      low: parseFloat(c.low),
      close: parseFloat(c.close),
    };
  });
}

export const tableKeyForLense = (
  key: string,
  suffix: string,
  zeroDTE?: boolean,
) => `${key}_${suffix}${zeroDTE ? '_0' : ''}`;

export const shouldShowAxisLabels = (chartWidth: number) => chartWidth >= 900;

export const getHiroRange = (hiroData: any, priceBounds: number) => {
  const priceBoundsMultiplier =
    PRICE_BOUNDS.length - PRICE_BOUNDS.indexOf(priceBounds);
  const minPadding = HIRO_Y_AXIS_BASE_PADDING * priceBoundsMultiplier;

  return [
    roundToNearest(hiroData.min - minPadding, HIRO_Y_AXIS_BASE_PADDING),
    roundToNearest(hiroData.max + minPadding, HIRO_Y_AXIS_BASE_PADDING),
  ];
};

const gexBaseRange = (strikeBarType: IntradayStrikeBarType) => {
  if (strikeBarType === IntradayStrikeBarType.GAMMA) {
    return 100_000_000; //100m
  }

  return 10_000;
};

export const getStrikeBarsXAxisRange = (
  tracker: StrikeBarsDailyTracker | null,
  min: number | undefined,
  max: number | undefined,
) => {
  if (tracker == null || min == null || max == null) {
    return undefined;
  }

  let maxVal = 0;
  for (const trace of [...tracker.entriesPerTrace.keys()]) {
    const entry = tracker.entriesPerTrace.get(trace)!;
    for (const strike of [...entry.keys()]) {
      if (strike < min || strike > max) {
        continue;
      }

      maxVal = Math.max(
        maxVal,
        Math.abs(entry.get(strike)!.dailyMax ?? 0),
        Math.abs(entry.get(strike)!.dailyMin ?? 0),
      );
    }
  }

  maxVal = roundUpToNearest(
    maxVal,
    tracker.type === IntradayStrikeBarType.GAMMA ? 10_000_000 : 1_000,
  );

  return [-maxVal, maxVal];
};

export const getStatsPercentile = (
  value: number | undefined,
  lenseOrStrikeType: IntradayGammaLense | IntradayStrikeBarType,
  statsData: any,
  parquetKeys: string[],
  inverted: boolean,
  isStrikeBar: boolean,
  zeroDte: boolean,
  traceName: string,
  lookbackDays: number,
) => {
  let key = IntradayFiltersStatsKeys.get(lenseOrStrikeType);
  if (
    value == null ||
    statsData == null ||
    key == null ||
    statsData[key] == null ||
    parquetKeys.length !== 1 ||
    lenseOrStrikeType === IntradayStrikeBarType.NONE
  ) {
    return null;
  }

  const parquetKey = parquetKeys[0];
  const uninvertedVal = inverted ? -value : value;

  let innerKey = IntradayFiltersStatsInnerKeys.get(lenseOrStrikeType) ?? key;
  if (lenseOrStrikeType === IntradayStrikeBarType.OI) {
    // if the trace name equals what the tracename would be if long puts or calls, then we want to use the 'buy' key
    const buy = [
      strikeBarTraceName(IntradayStrikeBarType.OI, true, true),
      strikeBarTraceName(IntradayStrikeBarType.OI, false, true),
    ].includes(traceName);
    innerKey = buy ? 'buy' : 'sell';
  }

  const valKey = `${innerKey}${isStrikeBar && zeroDte ? '_0' : ''}${
    lenseOrStrikeType === IntradayStrikeBarType.OI
      ? ''
      : uninvertedVal > 0
      ? '_pos'
      : '_neg'
  }`;
  const statsForKey = statsData[key][`${lookbackDays}`]?.[parquetKey]?.[valKey];
  const percentiles = statsForKey?.percentiles;
  if (percentiles == null) {
    return null;
  }
  const percentilesArr: any[] = [...Object.entries(percentiles)].map((v) => [
    parseInt(v[0]),
    v[1],
  ]);
  const absVal = Math.abs(value);
  const idx = predicateSearch(
    percentilesArr,
    (percentile: any[]) => percentile[1] < absVal,
  );
  const lower = idx < 0 ? [0, statsForKey.min] : percentilesArr[idx];
  const upper =
    idx < percentilesArr.length - 1
      ? percentilesArr[idx + 1]
      : [100, statsForKey.max];

  const valDiffPerOne = (upper[1] - lower[1]) / (upper[0] - lower[0]);
  const percent = lower[0] + (absVal - lower[1]) / valDiffPerOne;
  return percent.toFixed(2);
};

export const updateStrikeBarsTracker = (
  data: Map<number, Map<string, TraceStrikeBarsMapStore>> | undefined,
  currType: IntradayStrikeBarType,
  strikeBarsTracker: StrikeBarsDailyTracker | null,
  zeroDte: boolean,
) => {
  if (data == null) {
    return strikeBarsTracker;
  }

  const entriesPerTrace: Map<
    string,
    Map<number, StrikeBarsDailyTrackerEntry>
  > = strikeBarsTracker?.entriesPerTrace ?? new Map();

  // since these are map keys, no guarantee they are sorted in the way we want
  // so we want to ensure we sort them high -> low so that we can set the 10,30,60 min updates based on
  // the first timestamp.
  const timestampsSorted = [...data.keys()].sort((a, b) => b - a);
  const latestTimestamp = timestampsSorted[0];

  for (const ts of timestampsSorted) {
    const map = data.get(ts)!;
    // for GEX there is just one trace
    // for OI we have traces for Long/Short Call/Put
    // for net OI it's Net Call/Put
    // see getGexValues()
    const traces = [...map.keys()];
    for (const traceName of traces) {
      // for each trace, set the 10, 30, and 60 min value, and the daily min/max, using the prev tracker (if available)
      // then save that data on the new tracker map, corresponding to the strike that we're saving data for
      const entriesPerStrike = entriesPerTrace.get(traceName) ?? new Map();
      const setThirty = ts === latestTimestamp - 30 * ONE_MIN_MS;
      const setSixty = ts === latestTimestamp - ONE_HOUR_MS;
      const setLast = ts === latestTimestamp - 10 * ONE_MIN_MS;

      const valuesForTrace = map.get(traceName)!;
      const strikes = valuesForTrace.y;

      for (let j = 0; j < strikes.length; j++) {
        const strike = strikes[j];
        const val = valuesForTrace.x[j];
        const entry = entriesPerStrike.get(strike) ?? {};

        entry.dailyMax = Math.max(
          isFinite(entry.dailyMax) ? entry.dailyMax : -Infinity,
          val,
        );
        entry.dailyMin = Math.min(
          isFinite(entry.dailyMin) ? entry.dailyMin : Infinity,
          val,
        );

        if (setThirty) {
          entry.thirtyMin = val;
        }
        if (setSixty) {
          entry.hour = val;
        }
        if (setLast) {
          entry.last = val;
        }

        entriesPerStrike.set(strike, entry);
      }

      entriesPerTrace.set(traceName, entriesPerStrike);
    }
  }

  return {
    type: currType,
    latestTimestamp,
    entriesPerTrace,
    zeroDte,
  };
};

export const pollAtFromHeaders = (headers: any) =>
  dayjs().valueOf() + maxAgeMsFromHeaders(headers);

const maxAgeMsFromHeaders = (headers: any) => {
  if (headers == null) {
    return DEFAULT_MAX_AGE_MS + DEFAULT_MAX_AGE_PADDING_MS;
  }

  const maxAgeStr = headers.get
    ? headers.get('cache-control')
    : headers['cache-control'];
  const maxAgeNumStr = /max-age=(\d+)/.exec(maxAgeStr)?.[1];
  const newMaxAge = maxAgeNumStr == null ? NaN : parseFloat(maxAgeNumStr);
  return (
    (isNaN(newMaxAge) ? DEFAULT_MAX_AGE_MS : newMaxAge * 1_000) +
    DEFAULT_MAX_AGE_PADDING_MS
  );
};

export const responseToTable = async (resp: any) => {
  const buffer = await resp.arrayBuffer();
  const arrowTable = readParquet(new Uint8Array(buffer));
  return arrow.tableFromIPC(arrowTable.intoIPCStream());
};

export const getParquetUrl = (
  action: string,
  intradayDate: dayjs.Dayjs,
  intradaySym: string,
) => {
  const sym = encodeURIComponent(intradaySym);
  const params = new URLSearchParams({ sym });
  if (intradayDate != null) {
    params.append('date', getDateFormatted(intradayDate));
  }
  return `v1/oi/${action}?${params.toString()}`;
};

export const strikeBarParquetUrlForType = (
  strikeType: IntradayStrikeBarType,
  intradayDate: dayjs.Dayjs,
  intradaySym: string,
) =>
  strikeType === IntradayStrikeBarType.NONE
    ? null
    : getParquetUrl(
        [IntradayStrikeBarType.OI, IntradayStrikeBarType.OI_NET].includes(
          strikeType,
        )
          ? 'intradayStrikeOI'
          : 'intradayStrikeGEX',
        intradayDate,
        intradaySym,
      );

export const getTableParquetUrl = (
  greek: TraceGreek,
  intradayDate: dayjs.Dayjs,
  intradaySym: string,
) => {
  const action = greek === TraceGreek.Delta ? 'intradayDelta' : 'intradayGamma';
  return getParquetUrl(action, intradayDate, intradaySym);
};

export const getYRange = (
  zoomData: ZoomData | undefined,
  min: number | undefined,
  max: number | undefined,
  strikeBarType: IntradayStrikeBarType,
) => {
  // need some Y-padding so the bars do not get cutoff
  let padding = 0;
  if (strikeBarType === IntradayStrikeBarType.OI_NET) {
    padding = 0.5;
  } else if (strikeBarType === IntradayStrikeBarType.OI) {
    padding = 1;
  }

  let yRange: number[] | undefined =
    zoomData?.y ?? (min == null || max == null) ? undefined : [min, max];
  if ((yRange?.length ?? 0) === 0) {
    yRange = undefined;
  } else {
    yRange = [
      yRange![0] - maxStrikeBarY3Offset(strikeBarType) - padding,
      yRange![1] + maxStrikeBarY3Offset(strikeBarType) + padding,
    ];
  }

  return yRange;
};
