import { OF_DEFAULT_FILTER_ID } from 'config/optionsFeed';
import {
  OPTION_FEED_IDX,
  OptionFlag,
  OptionsFeedColumnKey,
  OptionTransactionType,
  OptionType,
  RawOptionFeedData,
  OptionTradeSide,
  OptionSaleType,
  FilterItem,
  Aggressor,
  Filter,
  FilterOperator,
  TransactionSentiment,
} from 'types/optionsFeed';

import { v4 as uuidv4 } from 'uuid';

const Mask = {
  OPTION_TYPE: 0x1,
  TNS_TYPE: 0x6,
  SIDE_TYPE: 0x18,
  IS_NEXT_EXP: 0x20,
  IS_RETAIL: 0x40,
  IS_BLOCK: 0x80,
  IS_SPREAD: 0x100,
};

const TYPE_STRS = [
  OptionTransactionType.NEW, // new
  OptionTransactionType.CORRECTION, // correction
  OptionTransactionType.CANCEL, // cancel
];

const typeToString = (tns_type: number): OptionTransactionType =>
  TYPE_STRS[tns_type];

const SIDE_STRS = [
  Aggressor.UNDETERMINED, // undetermined
  Aggressor.BUY, // buy
  Aggressor.SELL, // sell
];

const sideToString = (side_idx: number): Aggressor => SIDE_STRS[side_idx];

export const parseOptionFlags = (flags: number): OptionFlag => ({
  type: flags & Mask.OPTION_TYPE ? OptionType.PUT : OptionType.CALL,
  transactionType: typeToString((flags & Mask.TNS_TYPE) >> 1),
  sideType: sideToString((flags & Mask.SIDE_TYPE) >> 3),
  isNextExp: !!(flags & Mask.IS_NEXT_EXP) ? true : false,
  isRetail: !!(flags & Mask.IS_RETAIL) ? true : false,
  isBlock: !!(flags & Mask.IS_BLOCK) ? true : false,
  isSpread: !!(flags & Mask.IS_SPREAD) ? true : false,
});

// rawSignal format: ['AAPL', 1718733518879n, 6553.737343480935, 228.74070754478853, 12244.064045890529, 213.61, 7381904254035821727n, 1737147600000n, 200, 1, 273, 'f', 7, 6.95, 7.05, 0.2009438256563837]
export const getOptionFeedDataRow = (rawSignal: any[]): RawOptionFeedData => {
  const row: RawOptionFeedData = rawSignal.reduce((acc, value, index) => {
    switch (index) {
      case OPTION_FEED_IDX.SYM:
        acc[OptionsFeedColumnKey.Sym] = value.replace(/^.*\|/, '');
        break;
      case OPTION_FEED_IDX.TIME:
        acc[OptionsFeedColumnKey.Time] = BigInt(value);
        break;
      case OPTION_FEED_IDX.DELTA:
        acc[OptionsFeedColumnKey.Delta] = value;
        break;
      case OPTION_FEED_IDX.GAMMA:
        acc[OptionsFeedColumnKey.Gamma] = value;
        break;
      case OPTION_FEED_IDX.VEGA:
        acc[OptionsFeedColumnKey.Vega] = value;
        break;
      case OPTION_FEED_IDX.STOCK_PRICE:
        acc[OptionsFeedColumnKey.StockPrice] = value;
        break;
      case OPTION_FEED_IDX.TNS_INDEX:
        acc[OptionsFeedColumnKey.TnsIndex] = value;
        break;
      case OPTION_FEED_IDX.EXPIRATION:
        acc[OptionsFeedColumnKey.Expiration] = BigInt(value);
        break;
      case OPTION_FEED_IDX.STRIKE:
        acc[OptionsFeedColumnKey.Strike] = value;
        break;
      case OPTION_FEED_IDX.SIZE:
        acc[OptionsFeedColumnKey.Size] = value;
        break;
      case OPTION_FEED_IDX.FLAG:
        const flag = parseOptionFlags(value);
        acc[OptionsFeedColumnKey.Flags] = parseOptionFlags(value);
        acc[OptionsFeedColumnKey.CP] = flag.type;
        acc[OptionsFeedColumnKey.Aggressor] = flag.sideType;
        break;
      case OPTION_FEED_IDX.EXCHANGE_SALE_CONDITIONS:
        acc[OptionsFeedColumnKey.ExchangeSaleCondition] = value;
        break;
      case OPTION_FEED_IDX.PRICE:
        acc[OptionsFeedColumnKey.Price] = isNaN(value) ? null : value;
        break;
      case OPTION_FEED_IDX.BID:
        acc[OptionsFeedColumnKey.Bid] = isNaN(value) ? null : value;
        break;
      case OPTION_FEED_IDX.ASK:
        acc[OptionsFeedColumnKey.Ask] = isNaN(value) ? null : value;
        break;
      case OPTION_FEED_IDX.IVOL:
        acc[OptionsFeedColumnKey.IVol] = value;
        break;
      default:
        break;
    }
    return acc;
  }, {} as RawOptionFeedData);

  // dynamic field values
  row[OptionsFeedColumnKey.Premium] =
    row[OptionsFeedColumnKey.Size] * row[OptionsFeedColumnKey.Price] * 100;

  row[OptionsFeedColumnKey.Side] = getOptionTradeSide(row);

  row[OptionsFeedColumnKey.SaleType] = getOptionSaleTypeFlag(row);

  row.id = `${row[OptionsFeedColumnKey.Sym]}-${
    row[OptionsFeedColumnKey.Strike]
  }-${row[OptionsFeedColumnKey.Flags].type}-${
    row[OptionsFeedColumnKey.Expiration]
  }-${row[OptionsFeedColumnKey.TnsIndex]}-${uuidv4()}`;

  return row;
};

export const satisfiesDefaultConditions = (row: RawOptionFeedData) =>
  row[OptionsFeedColumnKey.Premium] > 1000;

export const getOptionTradeSide = (row: RawOptionFeedData) => {
  const optPrice = row[OptionsFeedColumnKey.Price];
  const bidPrice = row[OptionsFeedColumnKey.Bid];
  const askPrice = row[OptionsFeedColumnKey.Ask];

  if (optPrice < bidPrice) {
    return OptionTradeSide.BB;
  }

  if (optPrice === bidPrice) {
    return OptionTradeSide.B;
  }

  if (optPrice > bidPrice && optPrice < askPrice) {
    return OptionTradeSide.M;
  }

  if (optPrice === askPrice) {
    return OptionTradeSide.A;
  }

  return OptionTradeSide.AA;
};

export const getOptionSaleTypeFlag = (
  row: RawOptionFeedData,
): OptionSaleType | undefined => {
  const flags = row[OptionsFeedColumnKey.Flags];

  if (flags.isBlock) {
    return OptionSaleType.BLOCK;
  } else if (flags.isSpread) {
    return OptionSaleType.SPREAD;
  }

  switch (row[OptionsFeedColumnKey.ExchangeSaleCondition]) {
    case 'b':
    case 'S':
      return OptionSaleType.SWEEP;
    case 'c':
    case 'o':
    case 'p':
      return OptionSaleType.CROSS;
    default:
      return undefined;
  }
};

export const addOrUpdateFilters = (
  filters: FilterItem[],
  newFilters: FilterItem[],
): FilterItem[] => {
  const filterMap = new Map<string, FilterItem>(filters.map((f) => [f.id, f]));

  // Add or update new filters in the map
  newFilters.forEach((newFilter) => {
    const { id, value } = newFilter;

    // Check if the value is an empty array or empty string and remove the filter
    if ((Array.isArray(value) && value.length === 0) || value === '') {
      // If the new value is empty, remove it
      filterMap.delete(id);
    } else {
      // Otherwise, add or update the filter
      filterMap.set(id, newFilter);
    }
  });

  // Convert map back to array
  return Array.from(filterMap.values());
};

export const getFilter = (filterObj: any): Filter => ({
  id: filterObj.filter_id,
  value: filterObj.filter,
  name: filterObj.name,
  noSym: filterObj.no_sym,
});

export const satisfyFilters = (
  data: RawOptionFeedData,
  filters: FilterItem[],
): boolean => {
  if (filters.length === 0) {
    return true;
  }

  return filters.every((filter) => {
    const { field, operator, value } = filter;
    const dataValue = data[field as keyof RawOptionFeedData];

    // Handle Expiration and Time fields specifically
    if (
      field === OptionsFeedColumnKey.Expiration ||
      field === OptionsFeedColumnKey.Time
    ) {
      // Convert the number of days to a BigInt timestamp for comparison
      const filterValue = daysToBigInt(value as number);
      return NUMERIC_COMPARISONS.get(operator)?.(
        dataValue as bigint,
        filterValue,
      );
    }

    if (typeof dataValue !== 'number' && typeof dataValue !== 'string') {
      return false; // Early return if dataValue is neither a number nor a string
    }

    if (typeof dataValue === 'string' && dataValue === '') {
      return true;
    }

    if (typeof dataValue === 'number' || typeof dataValue === 'bigint') {
      return NUMERIC_COMPARISONS.get(operator)?.(
        dataValue,
        value as number | bigint,
      );
    }

    return handleEqualityAndArrayComparison(operator, dataValue, value);
  });
};

const NUMERIC_COMPARISONS = new Map<
  FilterOperator,
  (a: bigint | number, b: bigint | number) => boolean
>([
  [FilterOperator.LessThan, (a, b) => a < b],
  [FilterOperator.LessThanOrEqual, (a, b) => a <= b],
  [FilterOperator.GreaterThan, (a, b) => a > b],
  [FilterOperator.GreaterThanOrEqual, (a, b) => a >= b],
]);

const handleEqualityAndArrayComparison = (
  operator: FilterOperator,
  dataValue: string | number,
  filterValue: FilterItem['value'],
): boolean => {
  if (operator === FilterOperator.Equal) {
    return dataValue === filterValue;
  } else if (operator === FilterOperator.NotEqual) {
    return dataValue !== filterValue;
  } else if (
    operator === FilterOperator.IsAnyOf &&
    Array.isArray(filterValue)
  ) {
    // Check the type of the first element to decide whether to cast to string[] or number[]
    if (typeof dataValue === 'string') {
      return (filterValue as string[])
        .map((s) => s.toLowerCase())
        .includes(dataValue.toLowerCase()); // ignore casing when string matching
    } else if (typeof dataValue === 'number') {
      return (filterValue as number[]).includes(dataValue);
    }
  }

  return true;
};

const daysToBigInt = (days: number): bigint => {
  const now = BigInt(Date.now());
  const daysInt = BigInt(Math.round(days)); // Ensure days is an big int
  return now + daysInt * (1000n * 60n * 60n * 24n); // milliseconds in a day
};

export const getTransactionSentiment = (
  row: RawOptionFeedData,
): TransactionSentiment => {
  const isBullish =
    (row[OptionsFeedColumnKey.Aggressor] === Aggressor.BUY &&
      (row[OptionsFeedColumnKey.Side] === OptionTradeSide.AA ||
        row[OptionsFeedColumnKey.Side] === OptionTradeSide.A) &&
      row[OptionsFeedColumnKey.CP] === OptionType.CALL) ||
    (row[OptionsFeedColumnKey.Aggressor] === Aggressor.SELL &&
      (row[OptionsFeedColumnKey.Side] === OptionTradeSide.BB ||
        row[OptionsFeedColumnKey.Side] === OptionTradeSide.B) &&
      row[OptionsFeedColumnKey.CP] === OptionType.PUT);

  const isBearish =
    (row[OptionsFeedColumnKey.Aggressor] === Aggressor.BUY &&
      (row[OptionsFeedColumnKey.Side] === OptionTradeSide.AA ||
        row[OptionsFeedColumnKey.Side] === OptionTradeSide.A) &&
      row[OptionsFeedColumnKey.CP] === OptionType.PUT) ||
    (row[OptionsFeedColumnKey.Aggressor] === Aggressor.SELL &&
      (row[OptionsFeedColumnKey.Side] === OptionTradeSide.BB ||
        row[OptionsFeedColumnKey.Side] === OptionTradeSide.B) &&
      row[OptionsFeedColumnKey.CP] === OptionType.CALL);

  return isBullish ? 'bullish' : isBearish ? 'bearish' : 'neutral';
};

// Utility to get filter value or return default
export const getFilterValue = <T>(
  filters: FilterItem[],
  filterId: OF_DEFAULT_FILTER_ID,
  defaultValue: T,
): T => {
  return (filters.find((f) => f.id === filterId)?.value as T) ?? defaultValue;
};
