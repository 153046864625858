import { CircularProgress } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GridTableOverlay from 'components/shared/GridTableOverlay';
import EmptyGrid from 'icons/EmptyGrid';

interface WrapperProps {
  error?: boolean;
  loading?: boolean;
}

const GridTableOverlayWrapper = ({ error, loading }: WrapperProps) => {
  return (
    <GridTableOverlay
      icon={
        error ? (
          <ErrorOutlineIcon />
        ) : loading ? (
          <CircularProgress />
        ) : (
          <EmptyGrid sx={{ width: 36, height: 36 }} />
        )
      }
      text={
        error
          ? 'Something went wrong while fetching data...'
          : loading
          ? 'Waiting for data...'
          : 'Nothing found.'
      }
    />
  );
};

export default GridTableOverlayWrapper;
