import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  Switch,
  Typography,
} from '@mui/material';
import { HiroInlineControls } from './HiroInlineControls';
import Button from '@mui/material/Button';
import {
  DefaultChartSettings,
  MARK_KEYS,
  MARKS,
  VAL2MARK,
} from '../../../../config';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import {
  businessDaysSubtract,
  ET,
  getDefaultSlotProps,
  getQueryDate,
} from '../../../../util';
import {
  BottomCandlesType,
  HiroUpdatingType,
  ProductType,
  ValToMarkerKey,
  DashAccess,
  hasDashAccess,
} from '../../../../types';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  bottomCandlesTypeState,
  endQueryDateState,
  hiroETHState,
  hiroUpdatingTypeState,
  hiroViewAlertsState,
  lastManuallySetDateState,
  showChartTooltipState,
  startQueryDateState,
  sumWindowState,
  userDashAccessState,
} from '../../../../states';
import useUserDetails from '../../../../hooks/user/useUserDetails';
import { useTheme } from '@mui/material/styles';
import useAuth from '../../../../hooks/auth/useAuth';
import { SGTooltip } from '../../../core';

type ChartSettingsProps = {
  onClose?: () => void;
};

export const ChartSettings = ({ onClose }: ChartSettingsProps) => {
  const { changeHiroChartSetting } = useUserDetails();
  const dashAccess = useRecoilValue(userDashAccessState);
  const { productsWithAccess } = useAuth();
  const theme = useTheme();

  const showAlerts = useRecoilValue(hiroViewAlertsState);
  const sumWindow = useRecoilValue(sumWindowState);
  const defaultSlotProps = getDefaultSlotProps(theme);

  const [startDate, setStartDate] = useRecoilState(startQueryDateState);
  const [endDate, setEndDate] = useRecoilState(endQueryDateState);
  const [lastSetDate, setLastSetDate] = useRecoilState(
    lastManuallySetDateState,
  );
  const includeHiroETH = useRecoilValue(hiroETHState);
  const hasHiro = productsWithAccess.has(ProductType.HIRO);

  const maxQueryDate = useMemo(() => dayjs(getQueryDate(true).format()), []);

  const minQueryDate = useMemo(
    () => businessDaysSubtract(maxQueryDate, 4),
    [maxQueryDate],
  );
  const showChartTooltip = useRecoilValue(showChartTooltipState);
  const bottomCandlesType = useRecoilValue(bottomCandlesTypeState);
  const hiroUpdatingType = useRecoilValue(hiroUpdatingTypeState);

  const markSetDateToday = () => {
    const today = dayjs().tz(ET);
    if (!lastSetDate?.isSame(today, 'day')) {
      setLastSetDate(today);
    }
  };

  const saveSumWindow = (v: string) => {
    const newWin = VAL2MARK[v as ValToMarkerKey].seconds;
    if (newWin !== sumWindow) {
      changeHiroChartSetting({ sumWindow: newWin });
    }
  };

  const resetToDefaults = useCallback(() => {
    setStartDate(getQueryDate());
    setEndDate(getQueryDate());
    markSetDateToday();
    changeHiroChartSetting(DefaultChartSettings);
  }, []);

  const getSumWindowKey = () => {
    for (const key in VAL2MARK) {
      const seconds = VAL2MARK[key as ValToMarkerKey].seconds;
      if (seconds === sumWindow) {
        return parseInt(key);
      }
    }

    return 7; // default settings sum window key
  };

  return (
    <Box
      sx={{
        width: 'auto',
        height: 'auto',
        backgroundColor: theme.palette.background.paper,
        marginTop: '10px',
        flexDirection: 'column',
        display: 'flex',
        padding: '16px',
        boxShadow: theme.palette.shadows.default,
        borderRadius: theme.spacing(3),
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      }}
    >
      <Grid
        container
        direction={'column'}
        justifyContent="space-around"
        alignItems="flex-start"
      >
        <Grid item>
          <HiroInlineControls isInline={false} />
          <Button
            sx={{
              fontFamily: 'SF Pro Display',
              fontSize: 12,
              textTransform: 'capitalize',
              backgroundColor: 'transparent',
              top: '18px',
              position: 'absolute',
              right: '10px',
            }}
            onClick={resetToDefaults}
          >
            Reset to defaults
          </Button>

          <Box sx={{ width: 300, marginTop: '15px' }}>
            <Typography sx={{ fontSize: '14px', my: '8px' }}>
              Rolling Window
            </Typography>
            <Slider
              aria-label="Rolling Window"
              value={getSumWindowKey()}
              step={null}
              onChange={(e: Event, v: number | number[]) => {
                saveSumWindow(v.toString());
              }}
              marks={MARKS}
              min={parseInt(MARK_KEYS[0])}
              max={parseInt(MARK_KEYS[MARK_KEYS.length - 1])}
            />
          </Box>
        </Grid>
        <Box sx={{ marginBottom: 6 }}>
          <Typography sx={{ fontSize: '14px' }}>Date Range</Typography>
        </Box>

        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['day']}
              label="Start Date"
              value={startDate}
              disabled={!hasHiro}
              onChange={(newValue: Dayjs | null) => {
                if (newValue && newValue.isValid()) {
                  setStartDate(newValue);
                }
              }}
              minDate={minQueryDate}
              maxDate={maxQueryDate}
              format="YYYY-MM-DD"
              slotProps={{
                ...defaultSlotProps,
                textField: {
                  ...defaultSlotProps.textField,
                  style: {
                    width: '170px',
                    fontSize: '14px',
                    marginBottom: '12px',
                    marginRight: '5px',
                  },
                },
              }}
            />
            <DatePicker
              views={['day']}
              label="End Date"
              value={endDate}
              disabled={!hasHiro}
              onChange={(newValue: Dayjs | null) => {
                if (newValue && newValue.isValid()) {
                  setEndDate(newValue);
                }
              }}
              minDate={minQueryDate}
              maxDate={maxQueryDate}
              format="YYYY-MM-DD"
              slotProps={{
                ...defaultSlotProps,
                textField: {
                  ...defaultSlotProps.textField,
                  style: {
                    width: '170px',
                    fontSize: '14px',
                    marginBottom: '12px',
                    marginLeft: '5px',
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item sx={{ display: 'flex' }}>
          <Grid
            item
            sx={{ flexDirection: 'column', display: 'flex', gap: '8px' }}
          >
            <FormControlLabel
              value="bottom"
              control={
                <Switch
                  size="small"
                  color="primary"
                  checked={showAlerts}
                  onChange={(evt) => {
                    changeHiroChartSetting({
                      showAlerts: evt.target.checked,
                    });
                  }}
                  sx={{ fontSize: '14px' }}
                />
              }
              label="Show alerts"
              labelPlacement="end"
            />

            <FormControlLabel
              value="bottom"
              control={
                <Switch
                  size="small"
                  color="primary"
                  checked={includeHiroETH}
                  onChange={(evt) => {
                    changeHiroChartSetting({
                      showExtendedHours: evt.target.checked,
                    });
                  }}
                  sx={{ fontSize: '14px' }}
                />
              }
              label="Extended hours"
              labelPlacement="end"
            />
            {hasDashAccess(dashAccess, DashAccess.BETA) && (
              <SGTooltip title={'Revert to polling instead of streaming'}>
                <FormControlLabel
                  value="bottom"
                  control={
                    <Switch
                      size="small"
                      color="primary"
                      checked={hiroUpdatingType === HiroUpdatingType.POLLING}
                      onChange={(evt) => {
                        changeHiroChartSetting({
                          usePolling: evt.target.checked,
                        });
                      }}
                      sx={{ fontSize: '14px' }}
                    />
                  }
                  label="Use Polling"
                  labelPlacement="end"
                  sx={{ marginBottom: '12px' }}
                />
              </SGTooltip>
            )}
            {hiroUpdatingType === HiroUpdatingType.STREAMING && (
              <SGTooltip
                title={
                  'Show a tooltip displaying all the values for the hovered over candle. This is resource intensive and may slow down the HIRO chart.'
                }
              >
                <FormControlLabel
                  value="bottom"
                  control={
                    <Switch
                      size="small"
                      color="primary"
                      checked={showChartTooltip}
                      onChange={(evt) => {
                        changeHiroChartSetting({
                          showChartTooltip: evt.target.checked,
                        });
                      }}
                      sx={{ fontSize: '14px' }}
                    />
                  }
                  label="Show corner tooltip"
                  labelPlacement="end"
                  sx={{ marginBottom: '12px' }}
                />
              </SGTooltip>
            )}
          </Grid>

          {hiroUpdatingType === HiroUpdatingType.STREAMING && (
            <FormControl>
              <FormLabel
                id="bottom-candles-data-label"
                sx={{
                  color: `${theme.palette.text.primary} !important`,
                }}
              >
                Bottom Chart Display
              </FormLabel>
              <RadioGroup
                aria-labelledby="bottom-candles-data-label"
                value={bottomCandlesType}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  changeHiroChartSetting({
                    bottomCandlesType: event.target.value as BottomCandlesType,
                  });
                }}
              >
                <FormControlLabel
                  value={BottomCandlesType.ABSOLUTE_DELTA}
                  control={<Radio />}
                  label="Absolute Delta"
                />
                <FormControlLabel
                  value={BottomCandlesType.FILTERED_DELTA}
                  control={<Radio />}
                  label="Net HIRO Value"
                />
              </RadioGroup>
            </FormControl>
          )}
        </Grid>

        <Grid item sx={{ width: '100%' }}>
          <Button
            variant="contained"
            size="small"
            sx={{
              float: 'right',
              width: '45px',
              fontFamily: 'Satoshi Complete',
              fontSize: 12,
              textTransform: 'capitalize',
            }}
            onClick={onClose}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
