import { userIsLoggedInState, workerState } from '../../states';
import { useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import poll from 'util/poll';
import { userNotificationsState } from 'states/notifications';
import { getUserNotification } from 'util/notifications';
import { DEFAULT_NOTIFICATION_DAYS } from 'config/notifications';

// TODO: merge this global polling into one poll endpoint that would send down futures, unread alert counts and notifications
const useNotificationsPolling = () => {
  const worker = useRecoilValue(workerState);
  const loggedIn = useRecoilValue(userIsLoggedInState);
  const setNotifications = useSetRecoilState(userNotificationsState);

  const handleResponse = useCallback(async ({ json }: any) => {
    if (json == null || json?.error) {
      console.error(json?.error ?? 'Failed to fetch latest notifications');
    } else {
      setNotifications(json.map(getUserNotification));
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      return poll(worker, {
        url: `v1/me/notifications?days=${DEFAULT_NOTIFICATION_DAYS}`,
        interval: 30_000,
        onResponse: handleResponse,
      });
    }
  }, [worker, loggedIn, handleResponse]);
};

export default useNotificationsPolling;
