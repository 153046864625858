import { Stack, Divider, Typography, useTheme } from '@mui/material';

interface FilterSectionLabelProps {
  title: string;
}
const FilterSectionLabel = ({ title }: FilterSectionLabelProps) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ width: '100%', marginY: 2 }}
    >
      <Divider
        sx={{
          flexGrow: 1,
          borderColor: theme.palette.text.secondary,
          height: '1px',
        }}
      />

      <Typography
        variant="subtitle2"
        sx={{
          marginX: 2,
          color: theme.palette.text.secondary,
        }}
      >
        {title}
      </Typography>

      <Divider
        sx={{
          flexGrow: 1,
          borderColor: theme.palette.text.secondary,
          height: '1px',
        }}
      />
    </Stack>
  );
};

export default FilterSectionLabel;
