import { useEffect, useMemo, useState } from 'react';
import {
  IntradayGammaLense,
  ProcessingState,
  SubLevel,
  TraceContourData,
  TraceGreek,
} from '../../types';
import {
  fetchRawAPI,
  getAuthHeader,
  getContourColorOptions,
  getContourData,
  getTableParquetUrl,
  getTzOffsetMs,
  isLatestTradingDay,
  nonProdDebugLog,
  pollAtFromHeaders,
  responseToTable,
} from '../../util';
import { useRecoilValue } from 'recoil';
import {
  oiIntradayInvertedState,
  oiIntradayParquetKeys,
  oiNegativeGammaColorState,
  oiPositiveGammaColorState,
  oiScaleRangeState,
  screenWidthWithoutSidebarState,
  timezoneState,
  userSubLevelState,
  workerState,
} from '../../states';
import { dayjs } from '../../util/shared/date';
import { HeatmapColorSettings } from '../../theme';
import useWasmParquet from '../../hooks/useWasmParquet';
import useToast from '../../hooks/useToast';
import { readParquet } from 'parquet-wasm';
import * as arrow from 'apache-arrow/Arrow.node';
import poll from '../../util/poll';
import useLog from '../../hooks/useLog';
import { getUserTraceToken } from 'config/user';

type useContourDataProps = {
  selectedGreek: TraceGreek;
  intradayDate: dayjs.Dayjs;
  intradaySym: string;
  timestamp: dayjs.Dayjs | null;
  timestamps: dayjs.Dayjs[];
  heatmapColorSettings: HeatmapColorSettings;
  selectedLense: IntradayGammaLense;
  getTable: () => any;
  candlesDeps: any[];
  getLastCandle: () => any;
  setFirstChartTs: (ts: number | null) => void;
  setTimestamp: (val: dayjs.Dayjs | null) => void;
  setTable: (cb: (oldTable: any) => any, greek: TraceGreek) => void;
  width: number;
  boundsStr: string | null;
};

export const useContourData = ({
  selectedGreek,
  intradaySym,
  intradayDate,
  heatmapColorSettings,
  selectedLense,
  timestamp,
  timestamps,
  getTable,
  candlesDeps,
  getLastCandle,
  setFirstChartTs,
  setTimestamp,
  setTable,
  width,
  boundsStr,
}: useContourDataProps) => {
  const userLevel = useRecoilValue(userSubLevelState);
  const worker = useRecoilValue(workerState);
  const parquetKeys = useRecoilValue(oiIntradayParquetKeys);
  const invert = useRecoilValue(oiIntradayInvertedState);
  const tz = useRecoilValue(timezoneState);
  const screenWidth = useRecoilValue(screenWidthWithoutSidebarState);
  const negativeTrendColor = useRecoilValue(oiNegativeGammaColorState);
  const positiveTrendColor = useRecoilValue(oiPositiveGammaColorState);
  const selectedScaleRange = useRecoilValue(oiScaleRangeState);

  const [processingState, setProcessingState] = useState<ProcessingState>(
    ProcessingState.LOADING,
  );
  const [pollAtMap, setPollAtMap] = useState<Map<TraceGreek, number>>(
    new Map(),
  );

  const { getWasmPromise } = useWasmParquet();
  const { openToast } = useToast();
  const { logError } = useLog('useContourData');

  const setPollAt = (pollAt: number | undefined, greek: TraceGreek) => {
    // need to make sure you create a new map otherwise react will not recognize the state change
    if (pollAt == null) {
      const newMap = new Map(pollAtMap);
      newMap.delete(greek);
      return setPollAtMap(newMap);
    }

    setPollAtMap((map) => new Map(map.set(greek, pollAt)));
  };

  const handleLatestResponse =
    (greek: TraceGreek) =>
    ({ data, headers }: any) => {
      try {
        const arrowTable = readParquet(new Uint8Array(data));
        const tableAppend = arrow.tableFromIPC(arrowTable.intoIPCStream());
        const array = tableAppend.toArray();
        const lastTS = array[array.length - 1].timestamp;
        const polledLastTs = dayjs.utc(lastTS).tz(tz);
        nonProdDebugLog(
          `last timestamp received from polling oi ${greek}: `,
          polledLastTs,
        );
        // if the last timestamp we've polled and received is later than the current timestamp
        // and the current timestamp is the latest timestamp in the timestamps array
        // set the current timestamp to the last polled timestamp
        if (
          greek === selectedGreek &&
          polledLastTs?.isAfter(timestamp) &&
          timestamp?.isSameOrAfter(timestamps[timestamps.length - 1])
        ) {
          nonProdDebugLog(
            `${greek}: current timestamp below. setting timestamp to polledLastTs`,
            timestamp,
            polledLastTs,
          );
          setTimestamp(polledLastTs);
        }

        setTable((oldTable) => oldTable?.concat(tableAppend), greek);

        const newPollAt = pollAtFromHeaders(headers);
        nonProdDebugLog('TRACE: Setting poll for', greek, newPollAt);
        setPollAt(newPollAt, greek);

        nonProdDebugLog(
          `received polling data for OI ${greek}. polling at: ${dayjs(
            newPollAt,
          )}`,
        );
      } catch (err) {
        logError(err, 'handleLatestResponse', { greek, selectedGreek });
        if (greek === selectedGreek) {
          openToast({
            message: 'There was an error updating the heatmap data.',
            type: 'error',
          });
        }
      }
    };

  const pollForTable = (greek: TraceGreek, pollAt: number | undefined) => {
    const pollIn = pollAt == null ? -1 : pollAt - dayjs().valueOf();
    nonProdDebugLog('TRACE: greek: ', greek, 'pollIn: ', pollIn);
    if (pollIn < 0) {
      return;
    }

    nonProdDebugLog(
      `TRACE: setting up OI poller for ${greek} to poll at`,
      dayjs().add(pollIn, 'milliseconds'),
    );

    const parquetUrl = getTableParquetUrl(greek, intradayDate, intradaySym);

    const url = `${parquetUrl}&last=1&cb=${pollAt}`;
    nonProdDebugLog('TRACE: polling for ', url, ' in ', pollIn);

    return poll(
      worker,
      {
        url,
        interval: pollIn,
        onResponse: handleLatestResponse(greek),
        noPollOnInit: true,
        buffer: true,
        onlyPollOnce: true,
      },
      {
        ...getAuthHeader(getUserTraceToken(userLevel === SubLevel.ALPHA)),
      },
    );
  };

  useEffect(() => {
    const gammaCleanup = pollForTable(
      TraceGreek.Gamma,
      pollAtMap.get(TraceGreek.Gamma),
    );
    const deltaCleanup = pollForTable(
      TraceGreek.Delta,
      pollAtMap.get(TraceGreek.Delta),
    );

    return () => {
      gammaCleanup?.();
      deltaCleanup?.();
    };
  }, [pollAtMap, timestamp, timestamps]);

  const setProcessingStateForSelectedGreek = (
    newState: ProcessingState,
    greek: TraceGreek,
  ) => {
    if (greek !== selectedGreek) {
      return;
    }

    setProcessingState(newState);
  };

  const contourData = useMemo<TraceContourData | null>(() => {
    const table = getTable();
    if (table == null) {
      return null;
    }
    const bounds = boundsStr?.split(',').map((v) => parseFloat(v)) ?? null;
    nonProdDebugLog('using bounds', bounds);
    const data = getContourData(
      table,
      timestamp?.valueOf(),
      parquetKeys,
      invert,
      bounds,
      getTzOffsetMs(tz),
      selectedLense,
      getLastCandle(),
      width,
    );

    if (data == null) {
      return data;
    }

    setFirstChartTs(data.firstChartTimestamp ?? null);

    const colorContourOptions = getContourColorOptions(
      data.chartData?.z,
      negativeTrendColor,
      positiveTrendColor,
      heatmapColorSettings.chartZeroColor,
      selectedLense,
      selectedScaleRange,
    );
    data.chartData = { ...data.chartData, ...colorContourOptions };

    nonProdDebugLog('rendering contour data', data);
    return data;
  }, [
    parquetKeys,
    invert,
    selectedGreek,
    timestamp,
    boundsStr,
    positiveTrendColor,
    negativeTrendColor,
    selectedLense,
    selectedScaleRange,
    screenWidth,
    getTable,
    ...candlesDeps,
    heatmapColorSettings,
  ]);

  const fetchParquet = async (greek: TraceGreek) => {
    try {
      setProcessingStateForSelectedGreek(ProcessingState.LOADING, greek);

      const [resp, _wasm] = await Promise.all([
        fetchRawAPI(getTableParquetUrl(greek, intradayDate, intradaySym), {
          ...getAuthHeader(getUserTraceToken(userLevel === SubLevel.ALPHA)),
        }),
        getWasmPromise(),
      ]);

      // only tell the poller to poll if the date we're fetching is today
      if (isLatestTradingDay(intradayDate)) {
        setPollAt(pollAtFromHeaders(resp.headers), greek);
      } else {
        setPollAt(undefined, greek);
      }

      if (resp.status !== 200) {
        console.error('Received status: ' + resp.status);
        throw await resp.json();
      }

      const table = await responseToTable(resp);
      setTable((_oldTable) => table, greek);

      if (greek === selectedGreek) {
        const array = table.toArray();

        const tsMappedToDate = timestamp
          ?.dayOfYear(intradayDate.dayOfYear())
          ?.month(intradayDate.month())
          ?.year(intradayDate.year());

        if (
          tsMappedToDate == null ||
          !new Set(array.map((e) => e.timestamp.valueOf())).has(
            tsMappedToDate.valueOf(),
          )
        ) {
          const lastTS = array[array.length - 1].timestamp;
          const newTs = dayjs.utc(lastTS).tz(tz);
          setTimestamp(newTs);
        } else if (!tsMappedToDate.isSame(timestamp)) {
          setTimestamp(tsMappedToDate);
        }
      }

      setProcessingStateForSelectedGreek(ProcessingState.DONE, greek);
    } catch (err) {
      console.error(err);
      setProcessingStateForSelectedGreek(ProcessingState.FAILED_FETCH, greek);
    }
  };

  const fetchAllParquets = async () => {
    fetchParquet(TraceGreek.Gamma);
    fetchParquet(TraceGreek.Delta);
  };

  useEffect(() => {
    fetchAllParquets();
  }, [intradayDate, intradaySym]);

  return { contourData, processingState, fetchAllParquets };
};
