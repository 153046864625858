import { Box, IconButton, Slider, Stack } from '@mui/material';
import { SGTooltip } from '../../components/core';
import {
  ArrowBack,
  ArrowForward,
  FastForward,
  FastRewind,
  PlayArrow,
  Stop,
} from '@mui/icons-material';
import { useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isMobileState, oiIntradayFilterPrice } from '../../states';
import { ET } from '../../util';
import dayjs from 'dayjs';

type TraceTimeSliderProps = {
  timestamps: dayjs.Dayjs[];
  chartWidth: number;
  timestamp: dayjs.Dayjs | null;
  setTimestamp: (newVal: dayjs.Dayjs | null) => void;
};

const PLAY_INTERVAL_MS = 600;

export const TraceTimeSlider = ({
  timestamps,
  chartWidth,
  timestamp,
  setTimestamp,
}: TraceTimeSliderProps) => {
  const isMobile = useRecoilValue(isMobileState);
  const setFilterPrice = useSetRecoilState(oiIntradayFilterPrice);

  const [playingInterval, setPlayingInterval] = useState<
    NodeJS.Timer | undefined
  >();
  const playingIntervalRef = useRef<number>(0);

  let selectedTimestampIndex = timestamps.length - 1;
  for (let i = timestamps.length - 1; i >= 0; i--) {
    if (timestamp?.isSame(timestamps[i], 'second')) {
      selectedTimestampIndex = i;
      break;
    }
  }

  const horizGap = isMobile ? '5px' : '25px';
  const showLatestButton = selectedTimestampIndex !== timestamps.length - 1;
  const showBack = selectedTimestampIndex > 0;
  const showForward = selectedTimestampIndex < timestamps.length - 1;

  const handleTimeChange = (
    _evt: Event,
    idx: number | number[],
    _activeThumb: number,
  ) => {
    if (!Array.isArray(idx)) {
      setTimestamp(timestamps[idx]);
    }
  };

  const jumpToLatest = () => {
    setTimestamp(timestamps[timestamps.length - 1]);
  };

  const jumpToEarliest = () => {
    setTimestamp(timestamps[0]);
  };

  const playOrStop = () => {
    if (playingInterval != null) {
      clearInterval(playingInterval);
      setPlayingInterval(undefined);
    } else {
      // without filterPrice = true playing doesnt do anything
      setFilterPrice(true);
      playingIntervalRef.current = selectedTimestampIndex;
      const newInterval = setInterval(() => {
        const newIndex = playingIntervalRef.current + 1;
        setTimestamp(timestamps[newIndex]);
        playingIntervalRef.current = newIndex;
        if (newIndex === timestamps.length - 1) {
          clearInterval(newInterval);
          setPlayingInterval(undefined);
        }
      }, PLAY_INTERVAL_MS);
      setPlayingInterval(newInterval);
    }
  };

  // We want to ensure that certain important ticks show if they are available in the timestamps slider
  // like the market open tick, and the start and end range ticks
  // it's more complicated than you'd think to do that well, hence this function
  const getOiTicks = (timestamps: dayjs.Dayjs[], chartWidth: number) => {
    const timeMarks = [];
    const marketOpen = timestamps[0]
      .tz(ET)
      .set('hour', 9)
      .set('minute', 30)
      .set('second', 0);
    const indexOfMarketOpen = timestamps.findIndex((t) =>
      t.isSame(marketOpen, 'second'),
    );

    // the below are really just trial/error to figure out what works best
    const maxLabels = Math.floor(Math.max((chartWidth - 400) / 90, 2));
    const tickStep = Math.floor(timestamps.length / maxLabels);

    for (let i = 0; i < timestamps.length; i += tickStep) {
      // we always want to show the market open 9:30 timestamp
      // if we are about to show the timestamp right before 9:30
      // instead, show 9:30
      if (
        indexOfMarketOpen >= 0 &&
        i <= indexOfMarketOpen &&
        i + tickStep >= indexOfMarketOpen
      ) {
        i = indexOfMarketOpen;
      } else if (
        i > timestamps.length - tickStep - 1 &&
        (isMobile || (maxLabels > 3 && i + tickStep >= timestamps.length))
      ) {
        // for the last timestamp we are showing
        // if we are on mobile (we always want to show the last timestamp on mobile since we do not have the value label
        // tooltip on by default) or this timestamp is less than label distance to the end of the slider,
        // we wont be able to show the last timestamp's label in the block after this, since the labels will overlap
        // in that case, instead of showing whatever timestamp we are about to show,
        // just show the timestamps.length-1 timestamp here
        // this looks weird if there arent enough timestamps available, so only do this if we have at least 4 ticks
        // i.e. timestamps.length > TICK_STEP * 3
        i = timestamps.length - 1;
      }

      timeMarks.push({
        value: i,
        label: `${timestamps[i].format('HH:mm')}`,
      });
    }

    // if we have enough space at the end of what we last added in the for loop above to add the last timestamp label
    // then add it. this if block should never be true if the if block directly above was true
    if (
      timeMarks.length < maxLabels &&
      timeMarks[timeMarks.length - 1].value <= timestamps.length - 1 - tickStep
    ) {
      timeMarks.push({
        value: timestamps.length - 1,
        label: `${timestamps[timestamps.length - 1].format('HH:mm')}`,
      });
    }

    return timeMarks;
  };

  const timeMarks = getOiTicks(timestamps, chartWidth);

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      gap={horizGap}
      marginY={isMobile ? 0 : '5px'}
    >
      <Stack direction="row" width={1} gap={'5px'}>
        <Stack direction="row" width={1} gap="3px">
          <Box visibility={showBack ? 'visible' : 'hidden'}>
            <SGTooltip title={'Rewind to earliest timestamp'}>
              <IconButton
                onClick={jumpToEarliest}
                sx={{ position: 'relative', top: '-4px' }}
              >
                <FastRewind color="primary" />
              </IconButton>
            </SGTooltip>
          </Box>
          <Box visibility={showBack ? 'visible' : 'hidden'}>
            <SGTooltip title={'Go back one timestamp interval'}>
              <IconButton
                onClick={() =>
                  setTimestamp(timestamps[selectedTimestampIndex - 1])
                }
                sx={{
                  position: 'relative',
                  top: '-2px',
                  width: '18px',
                }}
              >
                <ArrowBack color="primary" fontSize="small" />
              </IconButton>
            </SGTooltip>
          </Box>
          <Slider
            aria-label="timestamp"
            marks={timeMarks}
            valueLabelDisplay={isMobile ? 'auto' : 'on'}
            valueLabelFormat={(val: number) =>
              timestamps[val].format('H:mm:ss')
            }
            min={0}
            max={timestamps.length - 1}
            onChange={handleTimeChange}
            value={selectedTimestampIndex}
            getAriaValueText={(val: number) =>
              timestamps[val].format('H:mm:ss')
            }
          />
          <Box visibility={showForward ? 'visible' : 'hidden'}>
            <SGTooltip title={'Go forward one timestamp interval'}>
              <IconButton
                onClick={() =>
                  setTimestamp(timestamps[selectedTimestampIndex + 1])
                }
                sx={{
                  position: 'relative',
                  top: '-2px',
                  width: '22px',
                }}
              >
                <ArrowForward color="primary" fontSize="small" />
              </IconButton>
            </SGTooltip>
          </Box>
        </Stack>
        <Box visibility={showLatestButton ? 'visible' : 'hidden'}>
          <SGTooltip title={'Jump to latest update'}>
            <IconButton
              onClick={jumpToLatest}
              sx={{ position: 'relative', top: '-4px' }}
            >
              <FastForward color="primary" />
            </IconButton>
          </SGTooltip>
        </Box>
        <Box visibility={showLatestButton ? 'visible' : 'hidden'}>
          <SGTooltip
            title={
              playingInterval == null ? "Play the day's moves" : 'Stop playing'
            }
          >
            <IconButton
              onClick={playOrStop}
              sx={{ position: 'relative', top: '-4px', width: '20px' }}
            >
              {playingInterval == null ? (
                <PlayArrow color="primary" />
              ) : (
                <Stop color="primary" />
              )}
            </IconButton>
          </SGTooltip>
        </Box>
      </Stack>
    </Stack>
  );
};
