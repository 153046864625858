import React from 'react';
import {
  alpha,
  Button,
  ButtonGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

interface MultiOptionSelectorProps {
  title: string;
  options: {
    value: string;
    label: string;
  }[];
  selectedOptions: string[];
  onSelect: (selectedOptions: string[]) => void;
  viewOnly?: boolean;
}

const MultiOptionSelector: React.FC<MultiOptionSelectorProps> = ({
  title,
  options,
  selectedOptions,
  onSelect,
  viewOnly = false,
}) => {
  const theme = useTheme();

  const handleOptionClick = (option: string) => {
    if (viewOnly) {
      return; // Prevent clicks in view-only mode
    }
    let updatedSelectedOptions: string[];
    if (selectedOptions.includes(option)) {
      updatedSelectedOptions = selectedOptions.filter(
        (item) => item !== option,
      );
    } else {
      updatedSelectedOptions = [...selectedOptions, option];
    }
    onSelect(updatedSelectedOptions);
  };

  return (
    <Stack direction="row" gap={2} alignItems="center" width="100%">
      <Typography
        sx={{
          textTransform: 'uppercase',
          color: theme.palette.text.secondary,
          fontSize: 11,
          minWidth: 60,
          maxWidth: 60,
        }}
      >
        {title}
      </Typography>

      <ButtonGroup
        variant="contained"
        sx={{
          borderRadius: 1,
          overflow: 'hidden',
          border: `1px solid ${theme.palette.primary.main}`,
          width: '100%',
          height: 28,
        }}
      >
        {options.map(({ label, value }) => (
          <Button
            key={value}
            onClick={() => handleOptionClick(value)}
            disabled={viewOnly} // Disable in view-only mode
            sx={{
              flex: 1,
              fontSize: 12,
              backgroundColor: selectedOptions.includes(value)
                ? theme.palette.button.default
                : 'transparent',
              color: selectedOptions.includes(value)
                ? viewOnly
                  ? theme.palette.text.disabled // Disabled color
                  : theme.palette.sgGreen
                : viewOnly
                ? theme.palette.text.disabled // Disabled color for non-selected
                : theme.palette.sgGreen,
              '&:hover': {
                backgroundColor: !viewOnly
                  ? theme.palette.button.hover
                  : 'transparent', // No hover effect in view-only mode
              },
              '&.Mui-disabled': {
                backgroundColor: selectedOptions.includes(value)
                  ? alpha(theme.palette.button.default, 0.5)
                  : 'transparent',
              },
              textTransform: 'capitalize',
              display: 'flex',
              justifyContent: 'center',
              padding: '0 12px',
              alignItems: 'center',
              gap: '2px',
            }}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </Stack>
  );
};

export default MultiOptionSelector;
