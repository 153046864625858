import { useRecoilValue } from 'recoil';
import { isMobileState } from 'states';
import {
  tnsActiveWatchlistsIdsState,
  tnsColumnOrderState,
  tnsColumnSizesState,
  tnsColumnsVisibilityState,
  tnsNewFilterState,
  tnsFilterPanelContainerActiveTab,
  tnsFilterPanelContainerOpenState,
  tnsActiveCustomFilterState,
  tnsSavedFiltersState,
} from 'states/optionsFeed';
import OptionsFeed from './OptionsFeed';
import { Box } from '@mui/material';
import { OptionsFeedColumnKey } from 'types/optionsFeed';

const OptionsFeedContainer = () => {
  const isMobile = useRecoilValue(isMobileState);

  return (
    <Box
      sx={{
        height: isMobile ? '85vh' : '100%',
        paddingBottom: isMobile ? '60px' : '20px',
        width: '100%',
      }}
    >
      <OptionsFeed
        filterActiveTabState={tnsFilterPanelContainerActiveTab}
        newFilterItemsState={tnsNewFilterState}
        savedFiltersState={tnsSavedFiltersState}
        activeCustomFilterState={tnsActiveCustomFilterState}
        disabledColumnFilters={[OptionsFeedColumnKey.Time]}
        filterPanelProps={{
          openState: tnsFilterPanelContainerOpenState,
        }}
        columnVisibilityState={tnsColumnsVisibilityState}
        columnOrderState={tnsColumnOrderState}
        columnSizingState={tnsColumnSizesState}
        activeWatchlistIdsState={tnsActiveWatchlistsIdsState}
      />
    </Box>
  );
};

export default OptionsFeedContainer;
