import { SxProps, Theme } from '@mui/material';
import { Scanner } from 'types';
import {
  FilterItem,
  FilterOperator,
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  OptionsFeedColumnVisibility,
  OptionTradeSide,
  OptionType,
} from 'types/optionsFeed';

export const DEFAULT_TNS_FIELDS = Object.values(OptionsFeedColumnKey);

export const OPTIONS_FEED_MAX_ROWS = 10_000;

export const OPTIONS_FEED_WATCHLIST_FILTER_KEY = 'watchlist-syms-filter';

export const DEFAULT_TNS_COL_VISIBILITY = Object.values(
  OptionsFeedColumnKey,
).reduce((acc, key) => {
  acc[key] = true;
  return acc;
}, {} as OptionsFeedColumnVisibility);

export const TNS_UNMODIFIABLE_FIELDS = [
  OptionsFeedColumnKey.Time,
  OptionsFeedColumnKey.Sym,
];

export enum OF_DEFAULT_FILTER_ID {
  Symbols = 'symbols',
  MinExp = 'min_expiration',
  MaxExp = 'max_exp',
  Premium = 'premium',
  Aggressor = 'aggressor',
  Side = 'side',
  CP = 'call/put',
  MinStrike = 'min_strike',
  MaxStrike = 'max_strike',
  MinVol = 'min_vol',
  MaxVol = 'max_vol',
  MinOptPrice = 'min_opt_price',
  MaxOptPrice = 'max_opt_price',
  MinSpotPrice = 'min_spot_price',
  MaxSpotPrice = 'max_spot_price',
  MinBid = 'min_bid',
  MaxBid = 'max_bid',
  MinAsk = 'min_ask',
  MaxAsk = 'max_ask',
  MinOI = 'min_oi',
  MaxOI = 'max_oi',
  MinDelta = 'min_delta',
  MaxDelta = 'max_delta',
  MinGamma = 'min_gamma',
  MaxGamma = 'max_gamma',
  MinVega = 'min_vega',
  MaxVega = 'max_vega',
  MinIV = 'min_iv',
  MaxIV = 'max_iv',
  Flag = 'flag',
}

const getColumnSize = (key: OptionsFeedColumnKey) => {
  switch (key) {
    case OptionsFeedColumnKey.Time:
      return 110;
    case OptionsFeedColumnKey.Sym:
      return 110;
    case OptionsFeedColumnKey.Side:
      return 90;
    case OptionsFeedColumnKey.Strike:
      return 60;
    case OptionsFeedColumnKey.Aggressor:
      return 70;
    case OptionsFeedColumnKey.Expiration:
      return 110;
    case OptionsFeedColumnKey.StockPrice:
      return 125;
    case OptionsFeedColumnKey.Bid:
    case OptionsFeedColumnKey.Ask:
    case OptionsFeedColumnKey.Size:
      return 90;
    case OptionsFeedColumnKey.Price:
      return 120;
    case OptionsFeedColumnKey.Premium:
      return 110;
    case OptionsFeedColumnKey.SaleType:
      return 100;
    case OptionsFeedColumnKey.IVol:
      return 80;
    default:
      return 65;
  }
};

export const TNS_SIDE_LABELS_MAP = new Map([
  [OptionTradeSide.BB, 'Below Bid'],
  [OptionTradeSide.B, 'Bid'],
  [OptionTradeSide.M, 'Mid'],
  [OptionTradeSide.A, 'Ask'],
  [OptionTradeSide.AA, 'Above Ask'],
]);

export const TNS_OPT_SIDE_LABELS_MAP = new Map([
  [OptionTradeSide.AA, 'AA'],
  [OptionTradeSide.A, 'A'],
  [OptionTradeSide.M, 'M'],
  [OptionTradeSide.B, 'B'],
  [OptionTradeSide.BB, 'BB'],
]);

export const DEFAULT_TNS_COL_SIZES = Object.values(OptionsFeedColumnKey).reduce(
  (acc, key) => {
    acc[key] = getColumnSize(key);
    return acc;
  },
  {} as OptionsFeedColumnSizes,
);

export const DEFAULT_FILTERS: FilterItem[] = [
  {
    field: OptionsFeedColumnKey.Strike,
    id: 'minStrike',
    operator: FilterOperator.GreaterThanOrEqual,
    value: '',
  },
  {
    field: OptionsFeedColumnKey.Strike,
    id: 'maxStrike',
    operator: FilterOperator.LessThanOrEqual,
    value: '',
  },
  {
    field: OptionsFeedColumnKey.Vega,
    id: 'minVega',
    operator: FilterOperator.GreaterThanOrEqual,
    value: '',
  },
  {
    field: OptionsFeedColumnKey.Vega,
    id: 'maxVega',
    operator: FilterOperator.LessThanOrEqual,
    value: '',
  },
  {
    field: OptionsFeedColumnKey.Delta,
    id: 'minDelta',
    operator: FilterOperator.GreaterThanOrEqual,
    value: '',
  },
  {
    field: OptionsFeedColumnKey.Delta,
    id: 'maxDelta',
    operator: FilterOperator.LessThanOrEqual,
    value: '',
  },
  {
    field: OptionsFeedColumnKey.Gamma,
    id: 'minGamma',
    operator: FilterOperator.GreaterThanOrEqual,
    value: '',
  },
  {
    field: OptionsFeedColumnKey.Gamma,
    id: 'maxGamma',
    operator: FilterOperator.LessThanOrEqual,
    value: '',
  },
  {
    field: OptionsFeedColumnKey.Price,
    id: 'minPrice',
    operator: FilterOperator.GreaterThanOrEqual,
    value: '',
  },
  {
    field: OptionsFeedColumnKey.Price,
    id: 'maxPrice',
    operator: FilterOperator.LessThanOrEqual,
    value: '',
  },
  {
    field: OptionsFeedColumnKey.Side,
    id: 'side',
    operator: FilterOperator.IsAnyOf,
    value: [],
  },
  {
    field: OptionsFeedColumnKey.Flags,
    id: 'maxPrice',
    operator: FilterOperator.LessThanOrEqual,
    value: '',
  },
];

export enum OptionsFeedFilterTab {
  NewFilter = 'New Filter',
  SavedFilters = 'Saved Filters',
}

export const DEFAULT_PANEL_STYLES: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  padding: '16px',
  gap: '16px',
  borderRadius: '8px',
  minWidth: 330,
  maxWidth: 330,
  height: '100%',
};

export const DEFAULT_TNS_SYM = '*';
export const DEFAULT_TNS_SYMBOLS = [DEFAULT_TNS_SYM];

export const OF_SCANNERS = [
  {
    label: 'VRP',
    value: Scanner.VOL_RISK_PREMIUM,
  },
  {
    label: 'Squeeze',
    value: Scanner.SQUEEZE,
  },
];
