import { useEffect, useMemo, useRef, useState } from 'react';
import useCleanup from '../../hooks/useCleanup';
import { Box, Typography, useTheme } from '@mui/material';
import { InfoButton, Loader, Tabs, ZoomOutButton } from 'components/shared';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  freeSymbolState,
  isMobileState,
  onSymSearchChangeState,
  pricesState,
  productAccessState,
  searchHandlerState,
  searchSuggestionsData,
  stocksState_SUBSCRIBE_TO_POLLING_RENDER,
} from 'states';
import {
  termStructureChartDataState,
  termStructureChartZoomConfigState,
  volSkewChartDataState,
  volSkewChartZoomConfigState,
} from 'states/iVol';
import {
  HiroOverview,
  IVOL_LABEL_MAPPING,
  IVOL_TABS,
  IVolTab,
  ProductType,
  SearchHandlerFunction,
  SuggestionSearchData,
} from 'types';
import usePrices from 'hooks/equityhub/usePrices';
import { useSearchParams } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { TermStructureChartSettings } from 'components/implied_vol/TermStructure/TermStructureControls';
import { VolSkewTypeSwitch } from 'components/implied_vol/VolSkew/VolSkewTypeSwitch';
import { VolSkewChartSettings } from 'components/implied_vol/VolSkew/VolSkewControls';
import { FixedStrikeTable } from 'components/implied_vol/FixedStrikeMatrix';
import { FixedStrikeTableSettings } from 'components/implied_vol/FixedStrikeMatrix/FixedStrikeTableControls';
import { updateSearch } from '../../util';
import { COMBINED_SIGNALS, DEFAULT_BRUSH_ZOOM_CONFIG } from 'config';
import VolSkewChart from 'components/implied_vol/VolSkew/VolSkewChart';
import TermStructureChart from 'components/implied_vol/TermStructure/TermStructureChart';
import { DownloadChartButton } from 'components/shared/DownloadChartButton';
import useSetSym from 'hooks/hiro/useSetSym';

export const ImpliedVol = () => {
  useCleanup();
  const tsRef = useRef<HTMLDivElement | null>(null);
  const vsRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const stocks = useRecoilValue(stocksState_SUBSCRIBE_TO_POLLING_RENDER);
  const products = useRecoilValue(productAccessState);
  const hasIVolAccess = useMemo(
    () => products.includes(ProductType.IMPLIED_VOL),
    [products],
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { getSym, setSym } = useSetSym();
  const searchParamSym = getSym(ProductType.IMPLIED_VOL);
  const freeSym = useRecoilValue(freeSymbolState);
  const querySymbol = !hasIVolAccess ? freeSym ?? '' : searchParamSym;

  const query = searchParams.get('tab') ?? '';
  const tab = IVOL_TABS.has(query)
    ? query
    : (IVolTab.FixedStrikeMatrix as string);
  const isMobile = useRecoilValue(isMobileState);
  const [termStructureChartZoomConfig, setTermStructureChartZoomConfig] =
    useRecoilState(termStructureChartZoomConfigState);
  const termStructureChartData = useRecoilValue(termStructureChartDataState);
  const [volSkewChartZoomConfig, setVolSkewChartZoomConfig] = useRecoilState(
    volSkewChartZoomConfigState,
  );

  const volSkewChartData = useRecoilValue(volSkewChartDataState);
  const setPrices = useSetRecoilState(pricesState);
  const setOnSymSearchChange = useSetRecoilState(onSymSearchChangeState);
  const theme = useTheme();
  const { getPrices } = usePrices();

  const setSuggestionsData = useSetRecoilState(searchSuggestionsData);

  const setSuggestionsHandler = useSetRecoilState(searchHandlerState);
  useEffect(() => {
    // search suggestions data handler
    const suggestionsHandler: SearchHandlerFunction = (val: string): void => {
      setSym(val, ProductType.IMPLIED_VOL);
    };

    setSuggestionsHandler(() => suggestionsHandler);
  }, [setSearchParams, setSuggestionsHandler, setSym]);

  useEffect(() => {
    const result: SuggestionSearchData[] = Array.from(stocks.values()).map(
      (data: HiroOverview) => ({
        symbol: data.instrument,
        name: data.companyName,
      }),
    );

    const suggestions = result.filter(
      (value: { symbol: string; name: string }) =>
        !COMBINED_SIGNALS.has(value.symbol) && value.symbol !== 'Mag7',
    );
    setSuggestionsData(suggestions);
  }, [setSuggestionsData, stocks]);

  useEffect(() => {
    setOnSymSearchChange((_prevValue: any) => {
      return (sym: string) => {
        setSym(sym, ProductType.IMPLIED_VOL);
      };
    });

    return () => {
      setOnSymSearchChange(undefined);
    };
  }, [setOnSymSearchChange, setSym]);

  useEffect(() => {
    const fetchPrices = async () => {
      setLoading(true);
      const payload = await getPrices([querySymbol]);
      setPrices(payload);
      setLoading(false);
    };
    fetchPrices();
  }, [getPrices, setPrices, querySymbol]);

  const fixedOptions = [
    ...(!hasIVolAccess
      ? [
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 14,
              display: isMobile ? 'none' : 'inline',
            }}
          >
            Access limited to <strong>{querySymbol}</strong>
          </Typography>,
        ]
      : []),
    ...(tab === (IVolTab.FixedStrikeMatrix as string)
      ? [
          <FixedStrikeTableSettings
            key="fixed-strike-table-settings"
            selectedSym={querySymbol}
          />,
        ]
      : []),
    ...(tab === (IVolTab.TermStructure as string)
      ? [
          <ZoomOutButton
            key="zoom"
            zoomConfig={termStructureChartZoomConfig}
            setZoomConfig={setTermStructureChartZoomConfig}
            initialData={termStructureChartData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: termStructureChartData.length - 1,
            }}
          />,
          <TermStructureChartSettings
            key="term-structure-settings"
            selectedSym={querySymbol}
          />,
          <DownloadChartButton
            key="ts-download"
            chartRef={tsRef}
            selectedSym={querySymbol}
            chartName="Term-Structure"
          />,
        ]
      : []),
    ...(tab === (IVolTab.VolSkew as string)
      ? [
          <ZoomOutButton
            key="zoom"
            zoomConfig={volSkewChartZoomConfig}
            setZoomConfig={setVolSkewChartZoomConfig}
            initialData={volSkewChartData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: volSkewChartData.length - 1,
            }}
          />,
          <VolSkewTypeSwitch />,
          <VolSkewChartSettings
            key="volatility-skew-settings"
            selectedSym={querySymbol}
          />,
          <DownloadChartButton
            key="vs-download"
            chartRef={vsRef}
            selectedSym={querySymbol}
            chartName="Volatility-Skew"
          />,
        ]
      : []),
    <InfoButton key={`ivol-info-${tab}`} articleKey={tab} />,
  ];

  return (
    <Box
      sx={{
        transition: '0.5s',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
        ...(isMobile ? { minHeight: '450px' } : {}),
        paddingBottom: '50px',
      }}
    >
      <Loader isLoading={Array.from(stocks.values()).length === 0 || loading}>
        <TabContext value={tab}>
          <Tabs
            options={IVOL_LABEL_MAPPING}
            onChange={(_evt, tab: string) => {
              setSearchParams(updateSearch({ tab }));
            }}
            controlProps={{ options: [], fixedOptions }}
            fontSize={isMobile ? '12px' : '14px'}
          />
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              overflowX: 'auto',
              ...(isMobile ? { maxHeight: 'calc(100% - 55px)' } : {}),
            }}
          >
            <TabPanel
              key={IVolTab.FixedStrikeMatrix}
              value={IVolTab.FixedStrikeMatrix as string}
            >
              <Box
                sx={{
                  transition: '0.5s',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  height: '100%',
                }}
              >
                <FixedStrikeTable selectedSym={querySymbol} />
              </Box>
            </TabPanel>
            <TabPanel
              key={IVolTab.TermStructure}
              value={IVolTab.TermStructure as string}
            >
              <Box
                sx={{
                  transition: '0.5s',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  height: '100%',
                }}
              >
                <TermStructureChart selectedSym={querySymbol} ref={tsRef} />
              </Box>
            </TabPanel>
            <TabPanel key={IVolTab.VolSkew} value={IVolTab.VolSkew as string}>
              <Box
                sx={{
                  transition: '0.5s',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  height: '100%',
                }}
              >
                <VolSkewChart selectedSym={querySymbol} ref={vsRef} />
              </Box>
            </TabPanel>
          </Box>
        </TabContext>
      </Loader>
    </Box>
  );
};
