import { bin, extent } from 'd3-array';

/** Can't run unit tests on this file due to d3 is using typescript in a JS file which confuses babel
 * https://github.com/facebook/jest/issues/12036 */
export const getHistogramData = (data: number[]) => {
  // Get the min and max values of the data
  const [minValue, maxValue] = extent(data);

  if (!minValue || !maxValue) {
    return null;
  }

  // Create the histogram bins
  const bins = bin()(data);

  return bins;
};

export const getAvg = (values: number[]): number => {
  return values.reduce((a, b) => a + b, 0) / values.length;
};

// Transform a linear value to a logarithmic scale
export const transformValueToLog = (
  value: number,
  min: number,
  max: number,
): number => {
  const logMin = Math.log(min + 1);
  const logMax = Math.log(max + 1);
  const logValue = Math.log(value + 1);
  return ((logValue - logMin) / (logMax - logMin)) * (max - min) + min;
};

// Reverse transform from logarithmic back to linear scale
export const transformLogToValue = (
  logValue: number,
  min: number,
  max: number,
): number => {
  const logMin = Math.log(min + 1);
  const logMax = Math.log(max + 1);
  return (
    Math.exp(((logValue - min) / (max - min)) * (logMax - logMin) + logMin) - 1
  );
};
