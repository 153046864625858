import { TabContext, TabPanel } from '@mui/lab';
import { Box, Button, useTheme } from '@mui/material';
import OptionsFeed from 'components/optionsFeed/OptionsFeed';
import {
  FullscreenBottomDrawerButton,
  Tabs,
  WatchlistMultiSelect,
} from 'components/shared';
import { AppcuesInfoButton } from 'components/shared/AppcuesInfoButton';
import { HIRO_TAB_LABEL_MAPPING, HIRO_TABS, HiroTab } from 'config';
import { useSetSym } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  bottomDrawerExpandedState,
  hiroActiveWatchlistsIdsState,
  hiroTnsActiveTabState,
  hiroTnsColumnOrderState,
  hiroTnsColumnSizesState,
  hiroTnsColumnsVisibilityState,
  hirotnsNewFilterState,
  hiroTnsFilterPanelOpenState,
  isMobileState,
  sidebarExpandedState,
  hirotnsActiveCustomFilterState,
  hirotnsSavedFiltersState,
} from 'states';
import { STOCK_SCREENER_FLOW_CONTENT_ID, updateSearch } from 'util/shared';
import { StockScreener } from './HiroTable';
import { Filter, OptionsFeedColumnKey } from 'types/optionsFeed';
import {
  GridFilterAltIcon,
  GridToolbarColumnsButton,
} from '@spotgamma/x-data-grid-premium';
import { useEffect, useState } from 'react';
import useTnsFilters from 'hooks/optionsFeed/useTnsFilters';
import useToast from 'hooks/useToast';

const HiroBottomQuadrantTabs = () => {
  const theme = useTheme();
  const { openToast } = useToast();
  const isMobile = useRecoilValue(isMobileState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeWatchlistIds, setActiveWatchlistIds] = useRecoilState(
    hiroActiveWatchlistsIdsState,
  );
  const [filterPanelOpen, setFilterPanelOpen] = useRecoilState(
    hiroTnsFilterPanelOpenState,
  );
  const bottomDrawerExpanded = useRecoilValue(bottomDrawerExpandedState);
  const hiroChartExpanded = useRecoilValue(sidebarExpandedState);
  const setSavedFilters = useSetRecoilState(hirotnsSavedFiltersState);
  const { fetchSavedFilters } = useTnsFilters();

  const [panelBtnEl, setPanelBtnEl] = useState<HTMLButtonElement | null>(null);

  const query = searchParams.get('tab') ?? '';
  const tab = HIRO_TABS.has(query) ? query : (HiroTab.optionsFeed as string);

  const { sym } = useSetSym();

  useEffect(() => {
    async function fetchFilters() {
      try {
        const myFilters: Filter[] = await fetchSavedFilters(true); // fetch only noSym:true filters on hiro
        setSavedFilters(myFilters);
      } catch (err: any) {
        console.error(err);
        openToast({
          message: err.message,
          type: 'error',
          duration: 10000,
        });
      }
    }
    fetchFilters();
  }, []);

  const fixedOptions = [
    <Box id="hiro-tns-filter-panel-btn" />,
    <Box id="hiro-tns-columns-panel-btn" />,
    <FullscreenBottomDrawerButton />,
    <WatchlistMultiSelect
      activeWatchlistIds={activeWatchlistIds}
      setActiveWatchlistIds={setActiveWatchlistIds}
    />,
    ...(tab === HiroTab.StockScreener
      ? [<AppcuesInfoButton articleKey={STOCK_SCREENER_FLOW_CONTENT_ID} />]
      : []),
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
        width: 1.0,
      }}
    >
      <TabContext value={tab}>
        <Tabs
          options={HIRO_TAB_LABEL_MAPPING}
          onChange={(_evt, tab: string) => {
            setSearchParams(updateSearch({ tab }));
          }}
          controlProps={{ options: [], fixedOptions }}
          fontSize={isMobile ? '12px' : '14px'}
        />
        <TabPanel
          key={HiroTab.optionsFeed}
          value={HiroTab.optionsFeed as string}
          sx={{ overflowY: 'hidden' }}
        >
          <Box
            sx={{
              transition: '0.5s',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              overflowX: 'auto',
              height: '100%',
              maxHeight: isMobile ? '500px' : '100%',
            }}
          >
            <OptionsFeed
              activeCustomFilterState={hirotnsActiveCustomFilterState}
              filterActiveTabState={hiroTnsActiveTabState}
              disabledColumnFilters={[
                OptionsFeedColumnKey.Sym,
                OptionsFeedColumnKey.Time,
              ]}
              filterPanelProps={{
                openState: hiroTnsFilterPanelOpenState,
                noSym: true,
                panelView: bottomDrawerExpanded ? 'default' : 'modal',
                currentSym: sym,
              }}
              newFilterItemsState={hirotnsNewFilterState}
              savedFiltersState={hirotnsSavedFiltersState}
              columnVisibilityState={hiroTnsColumnsVisibilityState}
              columnOrderState={hiroTnsColumnOrderState}
              columnSizingState={hiroTnsColumnSizesState}
              activeWatchlistIdsState={hiroActiveWatchlistsIdsState}
              customGridSlotProps={{
                panel: {
                  anchorEl: panelBtnEl,
                  placement: isMobile
                    ? 'bottom-end'
                    : !hiroChartExpanded && !bottomDrawerExpanded
                    ? 'left-end'
                    : bottomDrawerExpanded
                    ? 'bottom-end'
                    : 'top-end',
                },
                toolbar: {
                  filterPanelOpenState: hiroTnsFilterPanelOpenState,
                  disableToolbar: true,
                  customPositionedControllers: [
                    {
                      elementId: 'hiro-tns-filter-panel-btn',
                      component: (
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<GridFilterAltIcon />}
                          sx={{
                            textTransform: 'capitalize',
                            fontSize: {
                              xs: 12,
                              md: 14,
                            },
                            backgroundColor: filterPanelOpen
                              ? theme.palette.button.default
                              : 'inherit',
                            ':hover': {
                              backgroundColor: filterPanelOpen
                                ? theme.palette.button.hover
                                : 'inherit',
                            },
                          }}
                          onClick={() => setFilterPanelOpen((prev) => !prev)}
                        >
                          Filters
                        </Button>
                      ),
                    },
                    {
                      elementId: 'hiro-tns-columns-panel-btn',
                      component: (
                        <GridToolbarColumnsButton
                          ref={setPanelBtnEl}
                          slotProps={{
                            button: {
                              sx: {
                                textTransform: 'capitalize',
                                fontSize: {
                                  xs: 12,
                                  md: 14,
                                },
                              },
                            },
                          }}
                        />
                      ),
                    },
                  ],
                },
              }}
            />
          </Box>
        </TabPanel>
        <TabPanel
          key={HiroTab.StockScreener}
          value={HiroTab.StockScreener as string}
          sx={{ overflow: 'hidden' }}
        >
          <Box
            sx={{
              transition: '0.5s',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <StockScreener />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default HiroBottomQuadrantTabs;
