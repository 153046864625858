import { useEffect, useMemo, useState } from 'react';
import {
  getAuthHeader,
  getDateFormatted,
  getParquetUrl,
  nonProdDebugLog,
} from '../../util';
import { dayjs } from '../../util/shared/date';
import useToast from '../../hooks/useToast';
import { useLog } from '../../hooks';
import { getUserTraceToken } from 'config/user';
import { SubLevel } from 'types';
import { useRecoilValue } from 'recoil';
import { userSubLevelState } from 'states';

type useStatsProps = {
  intradayDate: dayjs.Dayjs;
  intradaySym: string;
};

export const useStats = ({ intradayDate, intradaySym }: useStatsProps) => {
  // use a map for stats so that we dont fetch stats for the same date more than once
  const [statsDataMap, setStatsDataMap] = useState<Map<string, any>>(new Map());
  const userLevel = useRecoilValue(userSubLevelState);

  const { openToast } = useToast();
  const { fetchAPIWithLog } = useLog('useStats');

  const statsParquetUrl = useMemo(
    () => getParquetUrl('intradayStats', intradayDate, intradaySym),
    [intradayDate, intradaySym],
  );

  const fetchStats = async (retries = 0) => {
    const dateKey = getDateFormatted(intradayDate);
    if (statsDataMap.has(dateKey)) {
      return;
    }

    try {
      const resp = await fetchAPIWithLog(statsParquetUrl, {
        ...getAuthHeader(getUserTraceToken(userLevel === SubLevel.ALPHA)),
      });
      const json = typeof resp === 'string' ? JSON.parse(resp) : resp;
      nonProdDebugLog('fetched stats data', json);
      setStatsDataMap((map) => map.set(dateKey, json));
    } catch (err) {
      console.error(err);
      if (retries < 1) {
        setTimeout(() => fetchStats(retries + 1), 5_000);
      } else {
        openToast({
          type: 'error',
          message: `There was an error loading stats data.`,
        });
      }
      return;
    }
  };

  useEffect(() => {
    fetchStats();
  }, [statsParquetUrl, statsDataMap]);

  return { statsDataMap };
};
