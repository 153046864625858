import { Box, Link } from '@mui/material';
import useIntegrations from '../../hooks/integrations/useIntegrations';

export const ThinkOrSwimIntegration = () => {
  const { getIntegrationsUrl } = useIntegrations();
  const tosScriptUrl = getIntegrationsUrl('ThinkOrSwim.txt');

  if (tosScriptUrl == null) {
    return <p>Error - please refresh this page.</p>;
  }

  return (
    <Box>
      <p>
        <em>
          <strong>
            This requires daily updating as a limitation of thinkorswim:
          </strong>
        </em>{' '}
        This single file loads levels for ALL products. Download{' '}
        <Link href={tosScriptUrl}>here</Link>.
      </p>
      <p style={{ paddingTop: '1em' }}>
        SpotGamma Daily trading levels can be added to{' '}
        <Link
          href="https://www.tdameritrade.com/tools-and-platforms/thinkorswim.html"
          target="_blank"
          rel="noreferrer noopener"
        >
          thinkorswim
        </Link>{' '}
        charts.
      </p>
      <h2 style={{ paddingTop: '1em' }}>
        How to add trading levels to thinkorswim:
      </h2>

      <p>
        <br />
        <b>Written Instructions (see video for demonstration):</b> <br />
        <br />
        1) Download the file on the integrations page. On ToS, this requires a
        daily reinstallation of this based on how their data architecture works.{' '}
        <br />
        2) In thinkorswim, you go to study (looks like a test tube). <br />
        3) Select 'Create'. <br />
        4) Name the study whatever you like at the top. <br />
        5) And then where there is that large open field, copy the code in.{' '}
        <br />
        6) Hit ok. Then you should have the study available. <br />
        7) Lastly, go to Chart Settings {`>`} Time Axis, and set the Expansion
        Area [bars to the right] to be at least 20. This way you will see the
        lines as labeled. <br />
        <br />
      </p>

      <video
        style={{ maxWidth: '100%' }}
        controls
        src="https://spotgamma.com/wp-content/uploads/2022/07/Example-Vid.mov"
      ></video>
    </Box>
  );
};
