import { GridColumnVisibilityModel } from '@spotgamma/x-data-grid-premium';
import {
  DEFAULT_TNS_COL_SIZES,
  DEFAULT_TNS_COL_VISIBILITY,
  DEFAULT_TNS_FIELDS,
  OptionsFeedFilterTab,
} from 'config/optionsFeed';

import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { Equity, Scanner } from 'types';
import {
  Filter,
  FilterItem,
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  RawOptionFeedData,
} from 'types/optionsFeed';
const { persistAtom } = recoilPersist();

export const tnsActiveWatchlistsIdsState = atom<number[]>({
  key: 'tns-activeWatchlistIds',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const tnsActiveScannersState = atom<Scanner[] | undefined>({
  key: 'tns-activeScannersState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const tnsEquityScannersDataState = atom<Equity[]>({
  key: 'tns-equityScannersDataState',
  default: [],
});

export const tnsNewFilterState = atom<FilterItem[]>({
  key: 'tns-newFilterState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const tnsSavedFiltersState = atom<Filter[]>({
  key: 'tns-savedFiltersState',
  default: [],
});

export const tnsActiveCustomFilterState = atom<Filter | undefined>({
  key: 'tns-activeCustomFilterState',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const tnsColumnsVisibilityState = atom<GridColumnVisibilityModel>({
  key: 'tns-tnsColumnsVisibilityState',
  default: DEFAULT_TNS_COL_VISIBILITY,
  effects_UNSTABLE: [persistAtom],
});

export const tnsColumnOrderState = atom<OptionsFeedColumnKey[]>({
  key: 'tns-tnsColumnOrderState',
  default: DEFAULT_TNS_FIELDS,
  effects_UNSTABLE: [persistAtom],
});

export const tnsColumnSizesState = atom<OptionsFeedColumnSizes>({
  key: 'tns-tnsColumnSizesState',
  default: DEFAULT_TNS_COL_SIZES,
  effects_UNSTABLE: [persistAtom],
});

export const tnsHistDataState = atom<RawOptionFeedData[]>({
  key: 'tns-tnsHistDataState',
  default: [],
});

export const tnsFilterPanelContainerOpenState = atom<boolean>({
  key: 'tns-tnsFilterPanelContainerOpenState',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const tnsFilterPanelContainerActiveTab = atom<OptionsFeedFilterTab>({
  key: 'tns-tnsFilterPanelContainerActiveTab',
  default: OptionsFeedFilterTab.NewFilter,
  effects_UNSTABLE: [persistAtom],
});
