import { useEffect, useMemo, useRef, useState } from 'react';
import {
  IntradayStrikeBarType,
  StrikeBarsDailyTracker,
  StrikeBarsData,
  SubLevel,
} from '../../types';
import {
  convertStrikeBarsDataToChart,
  convertStrikeBarsTrackerToChart,
  fetchRawAPI,
  getStrikeBarsData,
  isLatestTradingDay,
  nonProdDebugLog,
  ONE_HOUR_MS,
  updateStrikeBarsTracker,
  responseToTable,
  pollAtFromHeaders,
  strikeBarParquetUrlForType,
  getStrikeBarsXAxisRange,
  getAuthHeader,
} from '../../util';
import { useRecoilValue } from 'recoil';
import {
  oiIntradayInvertedState,
  oiIntradayParquetKeys,
  oiNegativeGammaColorState,
  oiPositiveGammaColorState,
  oiScaleRangeState,
  oiShowGexZeroDteState,
  oiStrikeBarsTrackerEnabledState,
  oiStrikeBarTypeState,
  userSubLevelState,
  workerState,
} from '../../states';
import dayjs from 'dayjs';
import { HeatmapColorSettings } from '../../theme';
import { readParquet } from 'parquet-wasm';
import * as arrow from 'apache-arrow';
import poll from '../../util/poll';
import useToast from '../../hooks/useToast';
import useWasmParquet from '../../hooks/useWasmParquet';
import useLog from '../../hooks/useLog';
import { useTheme } from '@mui/material/styles';
import { getUserTraceToken } from 'config/user';

type useStrikeBarProps = {
  contourData: any;
  timestamp: dayjs.Dayjs | null;
  timestamps: dayjs.Dayjs[];
  heatmapColorSettings: HeatmapColorSettings;
  intradayDate: dayjs.Dayjs;
  intradaySym: string;
};

export const useStrikeBars = ({
  contourData,
  timestamp,
  heatmapColorSettings,
  timestamps,
  intradayDate,
  intradaySym,
}: useStrikeBarProps) => {
  const theme = useTheme();
  const userLevel = useRecoilValue(userSubLevelState);
  const strikeBarType = useRecoilValue(oiStrikeBarTypeState);
  const negativeTrendColor = useRecoilValue(oiNegativeGammaColorState);
  const positiveTrendColor = useRecoilValue(oiPositiveGammaColorState);
  const showGexZeroDte = useRecoilValue(oiShowGexZeroDteState);
  const invert = useRecoilValue(oiIntradayInvertedState);
  const parquetKeys = useRecoilValue(oiIntradayParquetKeys);
  const worker = useRecoilValue(workerState);
  const trackerEnabled = useRecoilValue(oiStrikeBarsTrackerEnabledState);

  const [gexPollAt, setGexPollAt] = useState<number | undefined>(undefined);
  const [strikeBarsData, setStrikeBarsData] = useState<
    StrikeBarsData | undefined
  >();
  const [gexTable, setGexTable] = useState<arrow.Table | null>(null);
  const [gexLoading, setGexLoading] = useState(false);

  const strikeBarsTracker = useRef<StrikeBarsDailyTracker | null>(null);

  const { openToast } = useToast();
  const { getWasmPromise } = useWasmParquet();
  const { logError } = useLog('useStrikeBars');

  const [min, max] = useMemo(() => {
    // ensure the gex y axis matches the contour data y axis by getting the min/max of the contour data strikes
    // and not displaying any gex data with strikes outside that range
    const contourStrikes = contourData?.chartData?.y ?? [];
    const min = contourStrikes[0];
    const max = contourStrikes[contourStrikes.length - 1];
    return [min, max];
  }, [contourData]);

  useEffect(() => {
    if (gexTable == null || timestamp == null || min == null || max == null) {
      return setStrikeBarsData(undefined);
    }

    const endTs = timestamp.valueOf();
    // if we dont have the strikeBarTracker data for the current strike bar type
    // or if the tracker data we have is for a timestamp that is after the currently selected one
    // regenerate all gex values for all timestamps. otherwise we just need the previous hour
    const useLastTracker =
      strikeBarsTracker.current?.zeroDte === showGexZeroDte &&
      strikeBarsTracker.current?.type === strikeBarType &&
      (strikeBarsTracker.current?.latestTimestamp ?? 0) <= endTs;
    const startTs = useLastTracker
      ? Math.min(
          strikeBarsTracker.current!.latestTimestamp,
          endTs - ONE_HOUR_MS,
        )
      : 0;
    const { allDataByTs, latestTimestamp } = getStrikeBarsData(
      gexTable,
      invert,
      parquetKeys,
      timestamp,
      negativeTrendColor,
      positiveTrendColor,
      showGexZeroDte,
      strikeBarType,
      heatmapColorSettings,
      startTs,
      endTs,
    );

    if (allDataByTs == null || latestTimestamp == null) {
      return setStrikeBarsData(undefined);
    }

    const chartData = convertStrikeBarsDataToChart(
      allDataByTs,
      endTs,
      strikeBarType,
      min,
      max,
    );
    strikeBarsTracker.current = updateStrikeBarsTracker(
      allDataByTs,
      strikeBarType,
      useLastTracker ? strikeBarsTracker.current : null,
      showGexZeroDte,
    );
    const trackerData = trackerEnabled
      ? convertStrikeBarsTrackerToChart(
          strikeBarsTracker.current,
          theme.palette.trace.strikeBarSettings,
          min,
          max,
        )
      : [];

    nonProdDebugLog('strike bars data', {
      latestTimestamp,
      chartData,
      trackerData,
      tracker: strikeBarsTracker.current,
    });

    setStrikeBarsData({ latestTimestamp, chartData, trackerData });
  }, [
    gexTable,
    parquetKeys,
    contourData,
    timestamp,
    negativeTrendColor,
    positiveTrendColor,
    invert,
    showGexZeroDte,
    strikeBarType,
    theme.palette.trace.strikeBarSettings,
    trackerEnabled,
    min,
    max,
  ]);

  const strikeBarParquetUrl = useMemo(
    () => strikeBarParquetUrlForType(strikeBarType, intradayDate, intradaySym),
    [strikeBarType, intradayDate, intradaySym],
  );

  const pollForStrikeBars = (pollIn = 0, showLoading = false) => {
    if (pollIn < 0 || strikeBarType === IntradayStrikeBarType.NONE) {
      return;
    }

    if (showLoading) {
      setGexLoading(true);
    }

    nonProdDebugLog(
      'setting up GEX poller to poll at',
      dayjs().add(pollIn, 'milliseconds'),
    );

    return poll(
      worker,
      {
        url: `${strikeBarParquetUrl}&last=1&cb=${dayjs().valueOf()}`,
        interval: pollIn,
        onResponse: handleLatestResponseGex,
        noPollOnInit: true,
        buffer: true,
        onlyPollOnce: true,
      },
      {
        ...getAuthHeader(getUserTraceToken(userLevel === SubLevel.ALPHA)),
      },
    );
  };

  // do not rely on any states here. setters are fine though
  const handleLatestResponseGex = async ({ data, headers }: any) => {
    setGexLoading(false);
    try {
      const arrowTable = readParquet(new Uint8Array(data));
      const tableAppend = arrow.tableFromIPC(arrowTable.intoIPCStream());
      setGexTable((oldTable) => oldTable?.concat(tableAppend) ?? null);

      const newPollAt = pollAtFromHeaders(headers);
      setGexPollAt(newPollAt);

      nonProdDebugLog(
        `received polling data for gex. now polling at: ${dayjs(newPollAt)}`,
      );
    } catch (err) {
      logError(err, 'handleLatestResponseGex');
      openToast({
        message: 'There was an error updating the gamma exposure data.',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (timestamps.length === 0 || strikeBarsData == null) {
      return;
    }

    // if gex data is not null but we have no values, it means the gex timestamp does not match
    // the selected timestamp. if this is the case, show 'loading'
    const lastHeatTs = timestamps[timestamps.length - 1].valueOf();
    if (
      strikeBarsData.chartData.length === 0 &&
      strikeBarsData.latestTimestamp < lastHeatTs
    ) {
      // then fetch the latest gex data by telling it to poll in the next few seconds
      const pollIn =
        gexPollAt == null ? Infinity : gexPollAt - dayjs().valueOf();
      const refetchInMs = 5_000;
      if (pollIn > refetchInMs || pollIn < 0) {
        nonProdDebugLog(
          `gex is missing the latest timestmap. refetching in ${refetchInMs} ms`,
        );
        setGexLoading(true);
        return pollForStrikeBars(refetchInMs);
      }
    } else {
      setGexLoading(false);
    }
  }, [timestamps, strikeBarsData, gexPollAt]);

  useEffect(() => {
    const pollIn = gexPollAt == null ? -1 : gexPollAt - dayjs().valueOf();
    return pollForStrikeBars(pollIn);
  }, [gexPollAt, strikeBarParquetUrl]);

  const fetchStrikeBars = async (retries = 0) => {
    if (strikeBarParquetUrl == null) {
      return;
    }

    // add the latest heatmap timestamp to force this to not use cache once a new heatmap timestamp comes in
    const urlWithCb = `${strikeBarParquetUrl}&latestHeatmapTs=${
      timestamps[timestamps.length - 1]?.valueOf() ?? ''
    }`;
    nonProdDebugLog('fetching strike bar data...');
    try {
      setGexLoading(true);
      const [_wasm, gexResp] = await Promise.all([
        getWasmPromise(),
        fetchRawAPI(urlWithCb, {
          ...getAuthHeader(getUserTraceToken(userLevel === SubLevel.ALPHA)),
        }),
      ]);

      // only tell the poller to poll if the date we're fetching is today
      if (isLatestTradingDay(intradayDate)) {
        setGexPollAt(pollAtFromHeaders(gexResp.headers));
      } else {
        setGexPollAt(undefined);
      }

      if (gexResp.status >= 300) {
        if (retries < 1) {
          // keep it as loading, and try again in a few secs
          console.error('Received GEX resp status: ' + gexResp.status);
          setTimeout(() => fetchStrikeBars(retries + 1), 5_000);
        } else {
          openToast({
            type: 'error',
            message: `There was an error loading the strike bar chart data.`,
          });
        }
        return;
      }

      const gexRespTable = await responseToTable(gexResp);
      setGexTable(gexRespTable);
    } catch (err) {
      console.error(err);
    } finally {
      setGexLoading(false);
    }
  };

  useEffect(() => {
    fetchStrikeBars();
  }, [strikeBarParquetUrl]);

  const strikeBarsRange = getStrikeBarsXAxisRange(
    strikeBarsTracker.current,
    min,
    max,
  );

  return {
    strikeBarsData,
    strikeBarsTracker: strikeBarsTracker.current,
    strikeBarsRange,
    gexLoading,
    setGexLoading,
    min,
    max,
  };
};
