import {
  HEATMAP_TRACE_NAME,
  HIRO_TRACE_NAME,
  IntradayFiltersAxisLabels,
  PRICE_CANDLES_TRACE_NAME,
} from '../../config';
import {
  formatAsCompactNumber,
  getDateFormatted,
  getStatsPercentile,
  predicateSearch,
  roundToNearest,
  strikeBarTraceName,
  strikeBarTraceNameYOffset,
} from '../../util';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
  IntradayGammaLense,
  IntradayStrikeBarType,
  PriceCandle,
  StrikeBarsDailyTracker,
  StrikeBarsDailyTrackerEntry,
  StrikeBarsData,
} from '../../types';
import { dayjs } from '../../util/shared/date';
import { ReactNode, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  oiIntradayInvertedState,
  oiIntradayParquetKeys,
  oiPriceCandleDurationState,
  oiShowGexZeroDteState,
  oiStatsLookbackDaysState,
  oiStrikeBarsTrackerEnabledState,
  oiStrikeBarTypeState,
  timezoneState,
} from '../../states';
import { HeatmapColorSettings, TraceColorSettings } from '../../theme';

type useTooltipProps = {
  intradayDate: dayjs.Dayjs;
  intradaySym: string;
  selectedLense: IntradayGammaLense;
  statsDataMap: Map<string, any>;
  strikeBarsData: StrikeBarsData | undefined;
  strikeBarsTracker: StrikeBarsDailyTracker | null;
  heatmapColorSettings: HeatmapColorSettings;
  hiroSym: string;
  getCandlesArr: () => any;
  getHiroFiltered: () => any;
};

export const useTooltip = ({
  selectedLense,
  intradayDate,
  intradaySym,
  statsDataMap,
  strikeBarsData,
  strikeBarsTracker,
  heatmapColorSettings,
  hiroSym,
  getHiroFiltered,
  getCandlesArr,
}: useTooltipProps) => {
  const theme = useTheme();

  const parquetKeys = useRecoilValue(oiIntradayParquetKeys);
  const invert = useRecoilValue(oiIntradayInvertedState);
  const tz = useRecoilValue(timezoneState);
  const strikeBarType = useRecoilValue(oiStrikeBarTypeState);
  const candleDuration = useRecoilValue(oiPriceCandleDurationState);
  const showGexZeroDte = useRecoilValue(oiShowGexZeroDteState);
  const statsLookbackDays = useRecoilValue(oiStatsLookbackDaysState);
  const trackerEnabled = useRecoilValue(oiStrikeBarsTrackerEnabledState);

  const [hoverInfo, setHoverInfo] = useState<ReactNode>();

  const strikeBarTooltipValue = (val: number | undefined) => {
    if (val == null || isNaN(val)) {
      return 'N/A';
    }

    return `${
      strikeBarType === IntradayStrikeBarType.GAMMA ? '$' : ''
    }${formatAsCompactNumber(val, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })}`;
  };

  const onHover = (e: any) => {
    if ((e.points?.length ?? 0) === 0) {
      return;
    }

    const hoveredPoint = e.points[0];
    const isStrikeBar = ![
      HEATMAP_TRACE_NAME,
      PRICE_CANDLES_TRACE_NAME,
      HIRO_TRACE_NAME,
    ].includes(hoveredPoint.fullData.name);
    const statsData = statsDataMap.get(getDateFormatted(intradayDate));
    const percentile = getStatsPercentile(
      isStrikeBar ? hoveredPoint.x : hoveredPoint.z,
      isStrikeBar ? strikeBarType : selectedLense,
      statsData,
      parquetKeys,
      invert,
      isStrikeBar,
      showGexZeroDte,
      hoveredPoint.fullData.name,
      statsLookbackDays,
    );

    const divider = (
      <Box
        sx={{
          height: '1px',
          width: '100%',
          marginY: '5px',
          background: theme.palette.text.primary,
        }}
      />
    );

    let tooltipBody;

    if (isStrikeBar) {
      const strikeWithOffset = hoveredPoint.y;
      const strike =
        strikeWithOffset -
        strikeBarTraceNameYOffset(hoveredPoint.fullData.name);
      const traceData = strikeBarsData!.chartData.find(
        (d: any) => d.name === hoveredPoint.fullData.name,
      );
      const yIndex = traceData.y.indexOf(strikeWithOffset);
      const strikeBarsVal = traceData.x[yIndex];

      let trackerVal;
      if (strikeBarsTracker != null) {
        trackerVal = strikeBarsTracker?.entriesPerTrace
          .get(strikeBarTraceName(strikeBarType))
          ?.get(strike);
      }

      const trackerTooltipEntry = (
        trackerEntry: StrikeBarsDailyTrackerEntry,
        key: keyof StrikeBarsDailyTrackerEntry,
        title: string,
      ) => {
        const val = trackerEntry[key];
        if (val == null) {
          return null;
        }

        const useLine = ['dailyMax', 'dailyMin'].includes(key);
        const legend = trackerEnabled && (
          <span
            style={{
              ...(useLine
                ? { height: '2px', position: 'relative', bottom: '4px' }
                : { borderRadius: '10px', height: '10px' }),
              width: '10px',
              background: theme.palette.trace.strikeBarSettings.tracker[key],
              display: 'inline-block',
              marginRight: '3px',
            }}
          ></span>
        );

        return (
          <p>
            {legend} {title}: {strikeBarTooltipValue(val)}
          </p>
        );
      };

      tooltipBody = (
        <>
          <Typography>
            Strike: <b>{strike}</b>
          </Typography>
          <Typography>
            {showGexZeroDte ? '0DTE' : ''} {hoveredPoint.fullData.name}:{' '}
            <b>{strikeBarTooltipValue(strikeBarsVal)}</b>
            {percentile != null && (
              <p>
                (<b>{percentile}%</b> {statsLookbackDays}d Percentile)
              </p>
            )}
            {divider}
            {trackerVal != null && (
              <>
                {trackerTooltipEntry(trackerVal, 'last', '10 mins ago')}
                {trackerTooltipEntry(trackerVal, 'thirtyMin', '30 mins ago')}
                {trackerTooltipEntry(trackerVal, 'hour', '60 mins ago')}
                {trackerTooltipEntry(trackerVal, 'dailyMin', 'Daily Minimum')}
                {trackerTooltipEntry(trackerVal, 'dailyMax', 'Daily Maximum')}
              </>
            )}
          </Typography>
        </>
      );
    } else {
      const xTs = roundToNearest(e.xvals[0], candleDuration * 1_000);
      const xDate = dayjs
        .utc(xTs)
        .subtract(dayjs().tz(tz).utcOffset(), 'minutes'); // we get the time to utc, so convert to the tz we want
      const hiroAndPriceCandles = [getCandlesArr(), getHiroFiltered()].map(
        (arr) => {
          const firstIdx = predicateSearch(
            arr,
            (candle: PriceCandle) => candle.time < xDate.valueOf() / 1_000,
          );
          const possibleCandle = arr[firstIdx + 1];
          return possibleCandle?.time === xDate.valueOf() / 1_000
            ? possibleCandle
            : undefined;
        },
      );

      tooltipBody = (
        <>
          <Typography>
            Time: <b>{xDate.tz(tz).format('HH:mm')}</b>
          </Typography>
          {divider}
          <Typography>
            Price: <b>{hoveredPoint.y}</b>
          </Typography>
          {hoveredPoint.z != null && (
            <Typography>
              {IntradayFiltersAxisLabels.get(selectedLense)}:{' '}
              <b>{formatAsCompactNumber(hoveredPoint.z)}</b>
            </Typography>
          )}
          {percentile != null && (
            <Typography>
              (<b>{percentile}%</b> {statsLookbackDays}d Percentile)
            </Typography>
          )}
          {hiroAndPriceCandles.filter((v) => v != null).length > 0 && divider}
          {hiroAndPriceCandles[0] != null && (
            <Typography>
              {intradaySym} Price at time: <br />
              H: <b>{Math.round(hiroAndPriceCandles[0].high)}</b>
              &nbsp;&nbsp;&nbsp;L:{' '}
              <b>{Math.round(hiroAndPriceCandles[0].low)}</b>
              &nbsp;&nbsp;&nbsp;C:
              <b>{Math.round(hiroAndPriceCandles[0].close)}</b>
            </Typography>
          )}
          {hiroAndPriceCandles[1] != null && (
            <Typography>
              {hiroSym} HIRO at time:{' '}
              <b>{formatAsCompactNumber(hiroAndPriceCandles[1].close)}</b>
            </Typography>
          )}
        </>
      );
    }

    const tooltip = (
      <Box
        sx={{
          position: 'absolute',
          left: e.event.pageX + (isStrikeBar ? 0 : 30),
          top: e.event.pageY + (isStrikeBar ? 10 : 30),
          background: theme.palette.background.paper,
          padding: '15px',
          zIndex: 9999,
          pointerEvents: 'none',
        }}
      >
        <Stack direction="column">{tooltipBody}</Stack>
      </Box>
    );

    setHoverInfo(tooltip);
  };

  return { onHover, hoverInfo, setHoverInfo };
};
