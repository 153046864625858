import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import {
  IntradayGammaLense,
  IntradayStrikeBarType,
  OIEntity,
  OIScaleRange,
  OiTab,
  PRICE_BOUNDS,
} from '../types';
import dayjs from 'dayjs';
import { InitOutput } from 'parquet-wasm';
import { userSettingsState } from './auth';
import {
  HEATMAP_DEFAULT_NEG_COLOR,
  HEATMAP_DEFAULT_POS_COLOR,
  HEATMAP_DEFAULT_SPX_HIRO_SYM,
  IntradayShowChartType,
} from '../config/oi';
import { isMobileState } from './shared';

const { persistAtom } = recoilPersist();

export const oiSymsState = atom<Set<string>>({
  key: 'oi-syms',
  default: new Set(),
});

export const oiIntradayParquetKeys = atom<OIEntity[]>({
  key: 'oi-intraday-gamma-keys',
  default: [OIEntity.CUST],
});

export const oiIntradayPriceBoundsState = selector<number>({
  key: 'oi-price-bounds',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.zoom ?? PRICE_BOUNDS[6];
  },
});

export const oiIntradayInvertedState = atom<boolean>({
  key: 'oi-intraday-inverted',
  default: true,
});

export const oiIntradayFilterPrice = atom<boolean>({
  key: 'oi-intraday-filter-price',
  default: true,
});

export const oiTabState = atom<OiTab>({
  key: 'oi-tab',
  default: OiTab.DAILY_OI,
  effects_UNSTABLE: [persistAtom],
});

export const parquetWasmInitPromise = atom<Promise<InitOutput> | null>({
  key: 'parquet-wasm-init-promise',
  default: null,
});

export const oiPositiveGammaColorState = selector<string>({
  key: 'oi-intraday-positive-gamma-color',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.posColor ?? HEATMAP_DEFAULT_POS_COLOR;
  },
});

export const oiNegativeGammaColorState = selector<string>({
  key: 'oi-intraday-negative-gamma-color',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.negColor ?? HEATMAP_DEFAULT_NEG_COLOR;
  },
});

export const oiPriceCandleDurationState = selector<number>({
  key: 'oi-price-candle-duration',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.candleDuration ?? 5 * 60;
  },
});

export const oiSelectedLenseState = selector<IntradayGammaLense>({
  key: 'oi-selected-filters-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.selectedLense ?? IntradayGammaLense.GAMMA;
  },
});

export const oiShowKeyLevelsState = selector<boolean>({
  key: 'oi-show-levels-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return !settings?.oi?.hideLevels;
  },
});

export const oiShowColorScaleState = selector<boolean>({
  key: 'oi-show-color-scale-state',
  get: ({ get }) => {
    const fullscreen = get(oiFullscreenState);
    if (fullscreen) {
      return false;
    }
    const settings = get(userSettingsState);
    return !settings?.oi?.hideColorScale;
  },
});

export const oiScaleRangeState = selector<OIScaleRange>({
  key: 'oi-color-scale-min-max-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings?.oi?.scaleRange ?? OIScaleRange.AUTO;
  },
});

export const oiShowGexZeroDteState = selector<boolean>({
  key: 'oi-show-gex-zero-dte',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return !!settings?.oi?.showGexZeroDte;
  },
});

export const oiHiroSymsState = selector<Map<string, string>>({
  key: 'oi-hiro-syms-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    if (settings?.oi?.hiroSyms != null) {
      return new Map(Object.entries(settings?.oi?.hiroSyms));
    }
    return new Map([['SPX', HEATMAP_DEFAULT_SPX_HIRO_SYM]]);
  },
});

export const oiStrikeBarTypeState = selector<IntradayStrikeBarType>({
  key: 'oi-strike-bar-type-state',
  get: ({ get }) => {
    const fullscreen = get(oiFullscreenState);
    if (fullscreen) {
      const isMobile = get(isMobileState);
      if (!isMobile) {
        return IntradayStrikeBarType.NONE;
      }
    }
    const settings = get(userSettingsState);
    return settings?.oi?.strikeBarType ?? IntradayStrikeBarType.GAMMA;
  },
});

export const oiShowContourLinesState = selector<boolean>({
  key: 'oi-show-contour-lines-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return !settings?.oi?.hideContourLines;
  },
});

export const oiMobileFullscreenDefaultState = atom<boolean>({
  key: 'oi-mobile-fullscreen-state',
  default: true,
});

export const oiFullscreenState = selector<boolean>({
  key: 'oi-fullscreen-state',
  get: ({ get }) => {
    const isMobile = get(isMobileState);
    if (isMobile) {
      const mobileFullScreen = get(oiMobileFullscreenDefaultState);
      if (mobileFullScreen) {
        return true;
      }
    }
    const settings = get(userSettingsState);
    return !!settings?.oi?.fullscreen;
  },
});

export const oiStatsLookbackDaysState = selector<number>({
  key: 'oi-stats-lookback-days-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings.oi?.statsLookbackDays ?? 30;
  },
});

export const oiUseWhiteMode = selector<boolean>({
  key: 'oi-use-white-mode-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return !!settings.oi?.useWhiteMode;
  },
});

export const oiStrikeBarsTrackerEnabledState = selector<boolean>({
  key: 'oi-strike-bars-tracker-enabled-state',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return !settings.oi?.strikeBarsTrackerDisabled;
  },
});
