import {
  alpha,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Filter, FilterItem } from 'types/optionsFeed';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isMobileState } from 'states';
import FilterPanel from './FilterPanel';
import { HideSupportStyle } from 'components/shared/HideSupportStyle';

interface FiltersListProps {
  filters: Filter[];
  selectedFilterId?: Filter['id'];
  setSelectedFilter: (filter: Filter | undefined) => void;
  onEditSave?: (filter: Filter) => Promise<void>;
  onDelete?: (id: Filter['id']) => Promise<void>;
  editLoading?: boolean;
  deleteLoading?: boolean;
}

const FiltersList = ({
  filters,
  selectedFilterId,
  setSelectedFilter,
  onEditSave,
  onDelete,
  editLoading,
  deleteLoading,
}: FiltersListProps) => {
  return filters.length === 0 ? (
    <Typography my={2}>No saved filters</Typography>
  ) : (
    <List>
      {filters.map((filter: Filter) => {
        return (
          <FilterListItem
            key={filter.id}
            filter={filter}
            isSelected={selectedFilterId === filter.id}
            onSelect={setSelectedFilter}
            onEditSave={onEditSave}
            onDelete={onDelete}
            editLoading={editLoading}
            deleteLoading={deleteLoading}
          />
        );
      })}
    </List>
  );
};

interface FilterListItemProps {
  filter: Filter;
  isSelected: boolean;
  onSelect: (filter: Filter) => void;
  onEditSave?: (filter: Filter) => Promise<void>;
  onDelete?: (id: Filter['id']) => Promise<void>;
  editLoading?: boolean;
  deleteLoading?: boolean;
}

const FilterListItem = ({
  filter,
  isSelected,
  onSelect,
  onEditSave,
  onDelete,
  editLoading,
  deleteLoading,
}: FilterListItemProps) => {
  const isMobile = useRecoilValue(isMobileState);
  const theme = useTheme();
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [name, setName] = useState<string>(filter.name ?? '');
  const [filters, setFilters] = useState<FilterItem[]>(filter.value ?? []);

  const onOpenEditor = () => setOpenEditDialog(true);
  const onCloseEditor = () => setOpenEditDialog(false);

  const onOpenDelete = () => setOpenDeleteDialog(true);
  const onCloseDelete = () => setOpenDeleteDialog(false);

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onDeleteConfirm = async () => {
    await onDelete?.(filter.id);
  };

  const onEditConfirm = async () => {
    await onEditSave?.({
      ...filter,
      name,
      value: filters,
    });
    onCloseEditor();
  };

  return (
    <>
      <ListItemButton
        key={filter.id}
        sx={{
          borderRadius: 2,
          backgroundColor: isSelected
            ? alpha(theme.palette.sgGreen, 0.35)
            : 'inherit',
          transition: 'background-color 0.2s ease',
          ':hover': {
            backgroundColor: alpha(theme.palette.sgGreen, 0.1),
          },
        }}
        onClick={() => onSelect(filter)}
      >
        <ListItemText
          primary={filter.name}
          primaryTypographyProps={{ color: 'text.secondary', fontSize: 14 }}
        />
        <ListItemSecondaryAction>
          <Stack direction="row" gap={1} alignItems="center">
            {!filter.viewOnly ? (
              <>
                <IconButton
                  sx={{
                    color: 'text.secondary',
                  }}
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent triggering ListItem onClick
                    onOpenEditor();
                  }}
                >
                  <EditRoundedIcon sx={{ width: 16, height: 16 }} />
                </IconButton>
                <IconButton
                  sx={{
                    color: 'text.secondary',
                  }}
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent triggering ListItem onClick
                    onOpenDelete();
                  }}
                >
                  <DeleteRoundedIcon sx={{ width: 16, height: 16 }} />
                </IconButton>
              </>
            ) : (
              <IconButton
                sx={{ color: 'text.secondary' }}
                onClick={(event) => {
                  event.stopPropagation(); // Prevent triggering ListItem onClick
                  onOpenEditor();
                }}
              >
                <VisibilityRoundedIcon sx={{ width: 16, height: 16 }} />
              </IconButton>
            )}
          </Stack>
        </ListItemSecondaryAction>
      </ListItemButton>

      {/* Edit or view dialog */}
      <Dialog
        maxWidth="md"
        open={openEditDialog}
        fullScreen={isMobile}
        onClose={onCloseEditor}
      >
        <DialogContent>
          <Stack gap={4} maxWidth={450}>
            <Stack gap={0.5}>
              <Typography
                sx={{
                  fontSize: filter.viewOnly ? { xs: 18, md: 20 } : 16,
                  color: filter.viewOnly
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                }}
              >
                {!filter.viewOnly ? 'FILTER NAME' : filter.name}
              </Typography>
              {!filter.viewOnly && (
                <TextField
                  value={name}
                  onChange={onChangeName}
                  placeholder="My Custom Filter"
                  variant="outlined"
                  InputProps={{
                    sx: {
                      height: 28,
                      fontSize: 12,
                    },
                  }}
                  sx={{
                    width: '100%',
                    '& fieldset': {
                      borderColor: alpha(theme.palette.sgGreen, 0.85),
                    },
                    '&:hover fieldset': {
                      borderColor: theme.palette.sgGreen,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme.palette.sgGreen,
                    },
                  }}
                />
              )}
            </Stack>

            <FilterPanel
              filters={filters}
              onChangeFilters={(newFilters: FilterItem[]) =>
                setFilters(newFilters)
              }
              viewOnly={filter.viewOnly}
              noSym={filter.noSym}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ paddingX: '24px' }}>
          {!filter.viewOnly ? (
            <>
              <Button
                size="small"
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  width: '50%',
                  borderWidth: 2,
                  fontSize: 14,
                  ':hover': {
                    borderWidth: 2,
                  },
                }}
                onClick={onCloseEditor}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                sx={{
                  textTransform: 'none',
                  fontSize: 14,
                  width: '50%',
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.button.default,
                  boxShadow: 'none',
                  ':hover': {
                    backgroundColor: theme.palette.button.hover,
                  },
                }}
                onClick={onEditConfirm}
              >
                {editLoading ? <CircularProgress /> : 'Save Changes'}
              </Button>
            </>
          ) : (
            <Button
              size="small"
              variant="outlined"
              sx={{
                textTransform: 'none',
                width: '100%',
                borderWidth: 2,
                fontSize: 14,
                ':hover': {
                  borderWidth: 2,
                },
              }}
              onClick={onCloseEditor}
            >
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Delete dialog */}
      <Dialog
        sx={{ '& .MuiDialog-paper': { maxHeight: 435 } }}
        maxWidth="md"
        open={openDeleteDialog}
        fullScreen={isMobile}
        onClose={onCloseDelete}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the "{filter.name}" filter?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ paddingX: '24px' }}>
          <Button
            autoFocus
            size="small"
            sx={{
              textTransform: 'none',
              borderWidth: 2,
              fontSize: 14,
              minWidth: 100,
              ':hover': {
                borderWidth: 2,
              },
            }}
            onClick={onCloseDelete}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{
              textTransform: 'none',
              minWidth: 100,
              fontSize: 14,
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.button.default,
              boxShadow: 'none',
              ':hover': {
                backgroundColor: theme.palette.button.hover,
              },
            }}
            onClick={onDeleteConfirm}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {isMobile && (openEditDialog || openDeleteDialog) && <HideSupportStyle />}
    </>
  );
};

export default FiltersList;
