import { AccessProtectedPage } from './AccessProtectedPage';
import { ProductType } from 'types';
import { useCallback } from 'react';
import { IntradayGamma } from './components/IntradayGamma';
import { isValidTraceTimeframe } from 'config/user';

export const IntradayGammaPage = () => {
  return (
    <AccessProtectedPage
      productType={ProductType.TRACE}
      overrideCheck={isValidTraceTimeframe()} // don't bother with access checks if we're in the valid open access timeframe
      renderContent={useCallback(
        () => (
          <IntradayGamma productType={ProductType.TRACE} />
        ),
        [],
      )}
    />
  );
};
