import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, ButtonGroup, Typography, useTheme } from '@mui/material';
import {
  isMobileState,
  oiFullscreenState,
  oiIntradayParquetKeys,
  oiIntradayPriceBoundsState,
  oiSelectedLenseState,
  oiShowColorScaleState,
  oiShowContourLinesState,
  oiShowGexZeroDteState,
  oiShowKeyLevelsState,
  oiStrikeBarTypeState,
  oiUseWhiteMode,
  screenHeightState,
  screenWidthWithoutSidebarState,
  timezoneState,
  todaysOpenArrState,
  userDashAccessState,
  userSubLevelState,
} from 'states';
import { useRecoilValue } from 'recoil';
import {
  getAuthHeader,
  getDateFormatted,
  getQueryDateFormatted,
  nonProdDebugLog,
  predicateSearch,
} from 'util/shared';
import * as arrow from 'apache-arrow';
import { Plot } from './Plot';
import {
  IntradayGammaLense,
  IntradayStrikeBarType,
  PriceLineKey,
  ProcessingState,
  ProductType,
  SubLevel,
  TraceGreek,
  ZoomData,
} from '../../types';
import { Loader } from '../../components';
import { Center } from '../../components/shared/Center';
import {
  getGreekValueAtLastPrice,
  getHiroRange,
  greekForLense,
  parseContourArray,
  shouldShowAxisLabels,
  strikeBarParquetUrlForType,
  getYRange,
} from '../../util/oi';
import { IntradayGammaControls } from './IntradayGammaControls';
import { useSetSym } from '../../hooks';
import {
  dayjs,
  ET,
  getPriceLines,
  getSgData,
  sigHighLow,
  getTzOffsetMs,
} from '../../util';
import {
  HEATMAP_FIRST_AVAILABLE_DATE,
  IntradayFiltersReadable,
  IntradayShowChartType,
} from '../../config/oi';
import useUserDetails from '../../hooks/user/useUserDetails';
import { TraceTimeSlider } from './TraceTimeSlider';
import Plotly, { Image } from 'plotly.js';
import { ColorMode, HeatmapColorSettings, themeForMode } from '../../theme';
import { useStrikeBars } from '../../components/trace/useStrikeBars';
import { useTraceStreaming } from '../../components/trace/useTraceStreaming';
import { useContourData } from '../../components/trace/useContourData';
import { useTooltip } from '../../components/trace/useTooltip';
import { useStats } from '../../components/trace/useStats';
import { getUserTraceToken } from 'config/user';

const END_CHART_X_PADDING = 50;

type IntradayGammaProps = {
  productType: ProductType;
};

const INTRADAY_BAR_TITLES = new Map([
  [IntradayStrikeBarType.GAMMA, 'GEX by Strike'],
  [IntradayStrikeBarType.OI, 'OI by Strike'],
  [IntradayStrikeBarType.OI_NET, 'Net OI by Strike'],
]);

const VALID_SYMS = new Set(['SPX', 'VIX']);

export const IntradayGamma = ({ productType }: IntradayGammaProps) => {
  const theme = useTheme();
  const { getParam, searchParams, setParams } = useSetSym();
  const { saveSgSettings } = useUserDetails();
  const userLevel = useRecoilValue(userSubLevelState);

  const parquetKeys = useRecoilValue(oiIntradayParquetKeys);
  const priceBounds = useRecoilValue(oiIntradayPriceBoundsState);
  const tz = useRecoilValue(timezoneState);
  const screenWidth = useRecoilValue(screenWidthWithoutSidebarState);
  const screenHeight = useRecoilValue(screenHeightState);
  const isMobile = useRecoilValue(isMobileState);
  const strikeBarType = useRecoilValue(oiStrikeBarTypeState);
  const fullscreen = useRecoilValue(oiFullscreenState);

  const todaysOpenArr = useRecoilValue(todaysOpenArrState);
  const showKeyLevels = useRecoilValue(oiShowKeyLevelsState);
  const showGexZeroDte = useRecoilValue(oiShowGexZeroDteState);
  const showColorScale = useRecoilValue(oiShowColorScaleState);
  const showContourLines = useRecoilValue(oiShowContourLinesState);
  const selectedLenseFromSettings = useRecoilValue(oiSelectedLenseState);
  const useWhiteMode = useRecoilValue(oiUseWhiteMode);

  const [sgData, setSgData] = useState<any>(null);

  const [gammaTable, setGammaTable] = useState<arrow.Table | undefined>();
  const [deltaTable, setDeltaTable] = useState<arrow.Table | undefined>();
  const [zoomData, setZoomData] = useState<ZoomData | undefined>();

  const [showChartType, setShowChartType] = useState(
    isMobile ? IntradayShowChartType.Heatmap : IntradayShowChartType.Both,
  );

  const heatmapColorSettings = useMemo<HeatmapColorSettings>(() => {
    // 'useWhiteMode' only has an effect in dark mode
    if (useWhiteMode) {
      return themeForMode(ColorMode.LIGHT).palette.trace.heatmapSettings;
    }

    return theme.palette.trace.heatmapSettings;
  }, [useWhiteMode]);

  const selectedLense = useMemo(() => {
    const lenseParam = getParam('lense');
    const lense: any = lenseParam
      ? parseInt(lenseParam)
      : selectedLenseFromSettings;
    return IntradayGammaLense[lense]
      ? (lense as IntradayGammaLense)
      : selectedLenseFromSettings;
  }, [searchParams, selectedLenseFromSettings]);

  const selectedGreek = useMemo(
    () => greekForLense(selectedLense),
    [selectedLense],
  );

  const setTable = (
    stateSetter: (
      prevState: arrow.Table | undefined,
    ) => arrow.Table | undefined,
    greek: TraceGreek,
  ) => {
    const newTable = stateSetter(
      greek === TraceGreek.Delta ? deltaTable : gammaTable,
    );
    if (greek === TraceGreek.Delta) {
      setDeltaTable(newTable);
    } else {
      setGammaTable(newTable);
    }
  };

  const getTable = useCallback(
    () => (selectedGreek === TraceGreek.Delta ? deltaTable : gammaTable),
    [selectedGreek, deltaTable, gammaTable],
  );

  const intradaySym = useMemo(() => {
    let sym = getParam('sym')?.toUpperCase() ?? '';
    return VALID_SYMS.has(sym) ? sym : 'SPX';
  }, [searchParams]);

  // make this a str since if it was a dayjs directly it would always trigger
  // a re-render on any search param change, since we use shallow equality to determine if the
  // memo value is 'new'. create a second memo that depends on this string that is a dayjs object instead
  const intradayDateStr = useMemo(() => {
    const param = getParam('date');
    const newDate = dayjs(param);
    if (
      param == null ||
      !newDate?.isValid() ||
      newDate.isBefore(HEATMAP_FIRST_AVAILABLE_DATE, 'date')
    ) {
      return getQueryDateFormatted(true);
    }

    return param;
  }, [searchParams]);

  const intradayDate = useMemo(() => dayjs(intradayDateStr), [intradayDateStr]);

  // set the params initially so that the url is shareable from the get-go
  useEffect(() => {
    setParams({
      lense: selectedLense,
      date: getDateFormatted(intradayDate),
      sym: intradaySym,
    });
  }, []);

  const timestamps = useMemo(() => {
    const table = getTable();
    if (table == null) {
      return [];
    }
    const ts = new Set(table.toArray().map((e) => e.timestamp));
    return [...ts].sort().map((t) => dayjs.utc(t).tz(tz));
  }, [selectedGreek, getTable, tz]);

  const timestampParam = useMemo(() => getParam('ts'), [searchParams]);
  const timestamp = useMemo(() => {
    if (timestampParam != null) {
      const ts = dayjs(timestampParam);
      if (ts.isValid() && timestamps.find((time) => time.isSame(ts))) {
        return ts;
      }
    }

    return timestamps.length > 0 ? timestamps[timestamps.length - 1] : null;
  }, [timestamps, searchParams]);

  const setTimestamp = (newVal: dayjs.Dayjs | null) => {
    setParams({ ts: newVal?.format() });
  };

  const meanSpot = useMemo(() => {
    const table = getTable();
    // Grab the last timestamp in our payload and get the mean spot price
    const array = table?.toArray();
    if (array == null) {
      return null;
    }
    const lastTS = array[array.length - 1].timestamp;
    const start = predicateSearch(array, (e) => e.timestamp < lastTS!) + 1;
    let end = predicateSearch(array, (e) => e.timestamp <= lastTS!);
    end = Math.max(0, end);
    return start > end ? null : Number(array[start].spot + array[end].spot) / 2;
  }, [selectedGreek, getTable]);

  // if we have space, increase the width, but keep the aspect ratio fixed and some x padding if possible
  const width = screenWidth * (isMobile ? 1 : 0.95);
  // TODO: make height calculation dynamic and not hardcoded
  let height = isMobile
    ? screenHeight - 350
    : screenHeight * 0.95 -
      215 - // (top bar + title)
      (productType === ProductType.INTERNAL_OPEN_INTEREST ? 100 : 0); // extra row of settings
  height -= fullscreen ? 0 : 60; // support button

  const {
    getHiroFiltered,
    getCandlesArr,
    hiroChartData,
    candlesDeps,
    getLastCandle,
    getCandlesData,
    pricesLoading,
    hiroSym,
    setFirstChartTs,
    boundsStr,
  } = useTraceStreaming({
    intradayDate,
    intradaySym,
    timestamps,
    timestamp,
    heatmapColorSettings,
    meanSpot,
    zoomData,
  });

  const { contourData, processingState, fetchAllParquets } = useContourData({
    intradayDate,
    intradaySym,
    heatmapColorSettings,
    getTable,
    selectedGreek,
    selectedLense,
    timestamp,
    candlesDeps,
    getLastCandle,
    setFirstChartTs,
    setTimestamp,
    setTable,
    width,
    timestamps,
    boundsStr,
  });

  const {
    strikeBarsData,
    strikeBarsTracker,
    strikeBarsRange,
    gexLoading,
    setGexLoading,
    min,
    max,
  } = useStrikeBars({
    contourData,
    timestamp,
    timestamps,
    intradaySym,
    intradayDate,
    heatmapColorSettings,
  });

  const { statsDataMap } = useStats({ intradaySym, intradayDate });

  const { onHover, hoverInfo, setHoverInfo } = useTooltip({
    selectedLense,
    intradaySym,
    intradayDate,
    heatmapColorSettings,
    strikeBarsTracker,
    strikeBarsData,
    statsDataMap,
    getHiroFiltered,
    getCandlesArr,
    hiroSym,
  });

  const fetchSgData = async () => {
    // not worth showing a loading spinner, but invalidate prior sg data first so we dont show incorrect levels
    setSgData(null);
    const newData = await getSgData(intradayDate, intradaySym, {
      ...getAuthHeader(getUserTraceToken(userLevel === SubLevel.ALPHA)),
    });
    setSgData(newData);
  };

  useEffect(() => {
    if (showKeyLevels) {
      fetchSgData();
    }
  }, [intradayDate, showKeyLevels]);

  const gammaAtLastPriceUninverted = useMemo<number | undefined>(() => {
    const lastCandle = getLastCandle();
    if (gammaTable == null || lastCandle == null) {
      return undefined;
    }

    const { y, z, rawChartTimes } = parseContourArray(
      gammaTable.toArray(),
      timestamp?.valueOf(),
      parquetKeys,
      false, // we want uninverted
      null, // we always want to look at all the data
      getTzOffsetMs(tz),
      IntradayGammaLense.GAMMA,
    );

    return getGreekValueAtLastPrice(
      y,
      z,
      rawChartTimes,
      lastCandle,
      IntradayGammaLense.GAMMA,
    );
  }, [parquetKeys, timestamp, gammaTable, ...candlesDeps]);

  const levelsChartData = useMemo<{
    chartData: any[];
    annotations: any[];
  }>(() => {
    const contourX = contourData?.chartData?.x ?? [];
    if (
      sgData == null ||
      contourData == null ||
      contourX.length === 0 ||
      !showKeyLevels ||
      showChartType === IntradayShowChartType.StrikeBars
    ) {
      return { chartData: [], annotations: [] };
    }

    // get the min and max x coordinates for the chart since the lines we draw will need them so they appear
    // as horizontal lines
    const x1 = contourX[0];
    const x2 = contourX[contourX.length - 1];
    // always show the labels at the 2pm ET x point tz equivalent
    const xMid = dayjs(x1)
      .tz(ET)
      .hour(14)
      .minute(0)
      .second(0)
      .millisecond(0)
      .add(getTzOffsetMs(tz), 'milliseconds');

    // don't show levels that are not within the strike ranges
    const contourStrikes = contourData.chartData.y;
    const minY = contourStrikes[0];
    const maxY = contourStrikes[contourStrikes.length - 1];

    const priceLines = getPriceLines(
      sgData,
      theme,
      sigHighLow(sgData, todaysOpenArr, getDateFormatted(intradayDate)),
    );
    const chartData: any[] = [];
    const annotations: any[] = [];

    Object.keys(priceLines).forEach((priceLineName) => {
      const levelData = priceLines[priceLineName as PriceLineKey];
      const val = levelData?.value ?? 0;
      if (val < minY || val > maxY) {
        return;
      }

      chartData.push({
        x: [x1, x2],
        y: [val, val],
        xaxis: 'x',
        yaxis: 'y',
        mode: 'lines',
        line: {
          color: levelData.color,
          width: 3,
        },
        name: priceLineName,
        hoverinfo: 'name+y',
      });

      // find the leftmost annotation with an overlapping y val
      let neighbor: any = null;
      for (const annotation of annotations) {
        if (Math.abs(annotation.y - val) <= 10) {
          if (neighbor == null || neighbor.x > annotation.x) {
            neighbor = annotation;
          }
        }
      }

      // subtract one hour from the left overlapping annotation label to ensure no overlap
      const xCoord =
        neighbor != null ? neighbor.x - 60 * 60 * 1000 : xMid.valueOf();

      annotations.push({
        x: xCoord,
        y: val,
        text: priceLineName,
        font: {
          size: 11,
          color: '#000000',
        },
        showarrow: false,
        bgcolor: levelData.color,
        borderpad: 4,
      });
    });
    nonProdDebugLog('using annotations', annotations);
    return { chartData, annotations };
  }, [
    contourData,
    sgData,
    todaysOpenArr,
    theme,
    showKeyLevels,
    intradayDate,
    showChartType,
  ]);

  const loading =
    pricesLoading ||
    (processingState !== ProcessingState.DONE &&
      processingState !== ProcessingState.FAILED_FETCH);

  const gexTitle = () => {
    if (!shouldShowAxisLabels(width)) {
      return undefined;
    }

    const title = INTRADAY_BAR_TITLES.get(strikeBarType)!;
    if (
      showGexZeroDte &&
      (strikeBarsData?.chartData?.length ?? 0) > 0 &&
      strikeBarsData!.chartData[0].x.find((v: number) => v !== 0) == null
    ) {
      return `${title} <br /> (market closed, 0DTE n/a)`;
    }

    return title;
  };

  const onZoom = useCallback(
    (eventData: any) => {
      const xZoomedOut = eventData['xaxis.autorange'];
      const yZoomedOut = eventData['yaxis.autorange'];

      const xBounds = [
        eventData['xaxis.range[0]'],
        eventData['xaxis.range[1]'],
      ].filter((d) => d != null);
      const yBounds = [eventData['yaxis.range[0]'], eventData['yaxis.range[1]']]
        .filter((d) => d != null)
        .map((d) => Math.floor(d));

      const y2Bounds = [
        eventData['yaxis2.range[0]'],
        eventData['yaxis2.range[1]'],
      ]
        .filter((d) => d != null)
        .map((d) => Math.floor(d));

      const newZoomData: ZoomData = {};
      // for some reason, plotly sometimes does not pass in autorange for the y2 axis.
      // so we dont always know when y2 is zoomed out. instead, use the autorange for y
      if (!yZoomedOut) {
        newZoomData.y = yBounds.length > 0 ? yBounds : zoomData?.y;
        newZoomData.y2 = y2Bounds.length > 0 ? y2Bounds : zoomData?.y2;
      }
      if (!xZoomedOut) {
        newZoomData.x = xBounds.length > 0 ? xBounds : zoomData?.x;
      }
      const newZoomDataState =
        Object.keys(newZoomData).length > 0 ? newZoomData : undefined;
      nonProdDebugLog('new zoom data', newZoomDataState);
      setZoomData(newZoomDataState);
    },
    [zoomData],
  );

  const xAxisStartDomain = isMobile
    ? 0.15
    : strikeBarType === IntradayStrikeBarType.NONE
    ? 0.05
    : 0.27;
  // ensure there is fixed padding at the end regardless of chart width
  const xAxisEndDomain = isMobile ? 1 : 1 - END_CHART_X_PADDING / width;
  const xAxis2Domain =
    showChartType === IntradayShowChartType.StrikeBars
      ? [xAxisStartDomain, xAxisEndDomain]
      : [0, 0.19];

  const header = !isMobile && (
    <Typography fontSize={18}>
      SpotGamma's {intradayDate.format('M/D/YY')} {intradaySym}{' '}
      {IntradayFiltersReadable.get(selectedLense)} Exposure
    </Typography>
  );

  const getPlotlyData = () => {
    const heatmapData = [
      getCandlesData(),
      contourData?.chartData
        ? {
            ...contourData.chartData,
            showscale: showColorScale,
            line: {
              smoothing: 0.9,
              width: showContourLines ? undefined : 0,
              color: heatmapColorSettings.contourLineColor,
            },
          }
        : null,
      ...levelsChartData.chartData,
      hiroChartData?.chartData,
    ].filter((d) => d != null);

    let gexChartData = [];
    let trackerData: Plotly.Data[] = [];

    if (strikeBarType !== IntradayStrikeBarType.NONE) {
      gexChartData =
        (strikeBarsData?.chartData?.length ?? 0) > 0 && !gexLoading
          ? strikeBarsData!.chartData
          : // needed for it to show loading even without data
            [{ xaxis: 'x2', type: 'bar' }];

      if (gexChartData && gexChartData[0].x && gexChartData[0].y) {
        trackerData = strikeBarsData?.trackerData ?? [];
      }
    }

    const strikeBarsDisplayData = [...trackerData, ...gexChartData].filter(
      (d) => d != null,
    );

    if (showChartType === IntradayShowChartType.Both) {
      return heatmapData.concat(strikeBarsDisplayData);
    } else if (showChartType === IntradayShowChartType.Heatmap) {
      return heatmapData;
    } else {
      return strikeBarsDisplayData;
    }
  };

  // if we have zoomData below, the array is just 2 items, the min and max
  const watermarkXArr = zoomData?.x
    ? zoomData.x.map((str) => dayjs(str))
    : contourData?.chartData?.x;
  const watermarkYArr = zoomData?.y ?? contourData?.chartData?.y;
  const watermarkInverseSize = isMobile ? 5 : 10; // lower value means bigger
  const watermark: Partial<Image> =
    showChartType === IntradayShowChartType.StrikeBars ||
    (watermarkXArr?.length ?? 0) === 0 ||
    (watermarkYArr?.length ?? 0) === 0
      ? {}
      : {
          x: watermarkXArr[0].valueOf(),
          y: watermarkYArr[0],
          // same as above, these two formulas below are the product of repeated ui testing
          sizex:
            (watermarkXArr[watermarkXArr.length - 1].valueOf() -
              watermarkXArr[0].valueOf()) /
            watermarkInverseSize,
          sizey:
            (watermarkYArr[watermarkYArr.length - 1] - watermarkYArr[0]) /
            watermarkInverseSize,
          source: 'images/spotgamma-logo-full.png',
          xanchor: 'left',
          xref: 'x',
          yanchor: 'bottom',
          yref: 'y',
          layer: 'above',
          opacity: 0.5,
        };

  const body = (
    <Loader isLoading={loading}>
      <Center>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box width={1} flexGrow={1} textAlign="center">
            {header}
          </Box>
          <Box>
            {contourData == null ? (
              <Box sx={{ textAlign: 'center', marginTop: '25px' }}>
                <Typography
                  sx={{ cursor: 'pointer' }}
                  onClick={fetchAllParquets}
                >
                  There was an error. Please click here to retry.
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: `${theme.palette.background.default} !important`,
                  marginTop: isMobile ? 0 : '5px',
                }}
              >
                <Box>
                  <IntradayGammaControls
                    timestamp={timestamp}
                    intradaySym={intradaySym}
                    selectedLense={selectedLense}
                    timestamps={timestamps}
                    showAllSettings={
                      productType === ProductType.INTERNAL_OPEN_INTEREST
                    }
                    intradayDate={intradayDate}
                    zoomData={zoomData}
                    resetZoom={() => setZoomData(undefined)}
                    setStrikeBarType={(newType) => {
                      if (newType !== IntradayStrikeBarType.NONE) {
                        if (
                          strikeBarParquetUrlForType(
                            newType,
                            intradayDate,
                            intradaySym,
                          ) !==
                          strikeBarParquetUrlForType(
                            strikeBarType,
                            intradayDate,
                            intradaySym,
                          )
                        ) {
                          // there is this very annoying lag where we set the label title first, then set the loading setting
                          // this is because of the slight lag caused by everything bubbling up into the useMemo/useEffects
                          // instead, setGexLoading here if we anticipate needing to fetch
                          setGexLoading(true);
                        }
                      }
                      saveSgSettings({ oi: { strikeBarType: newType } });
                    }}
                    showChartType={showChartType}
                    setShowChartType={setShowChartType}
                    statsData={statsDataMap.get(getDateFormatted(intradayDate))}
                    gammaAtLastPriceUninverted={gammaAtLastPriceUninverted}
                  />
                </Box>
                {isMobile && (
                  <Box
                    margin="auto"
                    width={1}
                    textAlign="center"
                    marginBottom="10px"
                  >
                    <ButtonGroup>
                      <Button
                        sx={{ fontSize: '13px', textTransform: 'none' }}
                        variant={
                          showChartType === IntradayShowChartType.Heatmap
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() =>
                          setShowChartType(IntradayShowChartType.Heatmap)
                        }
                      >
                        Heatmap
                      </Button>
                      <Button
                        sx={{ fontSize: '13px', textTransform: 'none' }}
                        variant={
                          showChartType === IntradayShowChartType.StrikeBars
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() =>
                          setShowChartType(IntradayShowChartType.StrikeBars)
                        }
                      >
                        Strike Bars
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}

                <Box margin="auto">
                  <Plot
                    onRelayout={onZoom}
                    data={getPlotlyData()}
                    onHover={onHover}
                    onUnhover={() => {
                      setHoverInfo(null);
                    }}
                    config={{ showAxisDragHandles: false }}
                    layout={{
                      width,
                      height,
                      images: [watermark],
                      margin: {
                        l: 20,
                        r: isMobile ? 40 : 20,
                        b: isMobile ? 30 : 50,
                        t: 0,
                        pad: 4,
                      },
                      paper_bgcolor: theme.palette.background.default,
                      plot_bgcolor: theme.palette.background.default,
                      font: {
                        color: theme.palette.text.primary,
                      },
                      xaxis:
                        showChartType === IntradayShowChartType.StrikeBars
                          ? {}
                          : {
                              rangeslider: {
                                visible: false,
                              },
                              domain: [xAxisStartDomain, xAxisEndDomain],
                              fixedrange: false,
                              ...(zoomData?.x ? { range: zoomData.x } : {}),
                            },
                      xaxis2:
                        strikeBarType === IntradayStrikeBarType.NONE ||
                        showChartType === IntradayShowChartType.Heatmap
                          ? {}
                          : {
                              domain: xAxis2Domain,
                              range: strikeBarsRange,
                              ...(gexLoading
                                ? {
                                    showline: false,
                                    title: 'Updating. May take a minute...',
                                    showticklabels: false,
                                  }
                                : {
                                    title: { text: gexTitle() },
                                  }),
                            },
                      yaxis: shouldShowAxisLabels(width)
                        ? {
                            fixedrange: false,
                            title: {
                              text: 'Strike / Price ($)',
                              standoff: width > 1050 ? 15 : 5,
                            },
                            // you must specify a y range otherwise there will be unwanted padding with the
                            // tracker scatter trace on OI/NET_OI
                            range: getYRange(zoomData, min, max, strikeBarType),
                          }
                        : { fixedrange: false },
                      yaxis2:
                        hiroChartData == null
                          ? { fixedrange: false }
                          : {
                              fixedrange: false,
                              overlaying: 'y',
                              side: 'right',
                              position:
                                xAxisEndDomain + (isMobile ? -0.01 : 0.005),
                              range:
                                zoomData?.y2 ??
                                getHiroRange(hiroChartData, priceBounds),
                              ...(shouldShowAxisLabels(width)
                                ? {
                                    title: {
                                      text: 'HIRO',
                                    },
                                  }
                                : {}),
                            },
                      showlegend: false,
                      annotations: levelsChartData.annotations,
                      barmode: 'overlay',
                      // @ts-ignore
                      scattermode: 'overlay',
                      modebar: {
                        // remove all plotly buttons except the download as image one
                        // they simply dont work well with our chart
                        remove: [
                          'autoScale2d',
                          'hoverCompareCartesian',
                          'hovercompare',
                          'lasso2d',
                          'orbitRotation',
                          'pan2d',
                          'pan3d',
                          'resetCameraDefault3d',
                          'resetCameraLastSave3d',
                          'resetGeo',
                          'resetScale2d',
                          'resetViewMapbox',
                          'resetViews',
                          'select2d',
                          'sendDataToCloud',
                          'tableRotation',
                          'toggleHover',
                          'toggleSpikelines',
                          'togglehover',
                          'togglespikelines',
                          'zoom2d',
                          'zoom3d',
                          'zoomIn2d',
                          'zoomInGeo',
                          'zoomInMapbox',
                          'zoomOut2d',
                          'zoomOutGeo',
                          'zoomOutMapbox',
                          // remove toImage if mobile. if not, add dup key because ts complains
                          isMobile ? 'toImage' : 'zoom2d',
                        ],
                      },
                    }}
                  />
                </Box>
                {hoverInfo}
                <Box>
                  <TraceTimeSlider
                    timestamps={timestamps}
                    chartWidth={width}
                    timestamp={timestamp}
                    setTimestamp={setTimestamp}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Center>
    </Loader>
  );
  return (
    <Box sx={loading ? { width: 1, height: isMobile ? 1 : `${height}px` } : {}}>
      {body}
    </Box>
  );
};
