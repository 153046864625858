import { Slider, Typography, Stack, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import {
  formatAsCompactNumber,
  transformLogToValue,
  transformValueToLog,
} from 'util/shared';

type ValueFormat = 'currency' | 'date' | 'number';

interface SliderFilterProps {
  header: string;
  name: string;
  min: number;
  max: number;
  value: number | number[];
  onChange: (newValue: number | number[]) => void;
  valueFormat: ValueFormat;
  viewOnly?: boolean;
}

const getFormattedValueLabel = (
  value: number,
  valueFormat: ValueFormat = 'number',
) => {
  if (valueFormat === 'currency') {
    return `$${formatAsCompactNumber(value)}`;
  } else if (valueFormat === 'date') {
    const roundedVal = Math.round(value);
    return roundedVal === 0
      ? 'Today'
      : `${roundedVal} day${roundedVal > 1 ? 's' : ''}`;
  } else {
    return formatAsCompactNumber(value);
  }
};

const SliderFilter = ({
  header,
  name,
  min,
  max,
  value,
  valueFormat = 'number',
  onChange,
  viewOnly = false,
}: SliderFilterProps) => {
  const theme = useTheme();

  const getSliderInfo = useCallback(() => {
    if (!Array.isArray(value)) {
      return value
        ? getFormattedValueLabel(value, valueFormat)
        : `All ${name}s`;
    }

    const selectedMin = value[0];
    const selectedMax = value[1];

    if (selectedMin === min && selectedMax === max) {
      return `All ${name}s`;
    } else if (selectedMin > min && selectedMax === max) {
      return `More than ${getFormattedValueLabel(selectedMin, valueFormat)}`;
    } else if (selectedMin === min && selectedMax < max) {
      return `Less than ${getFormattedValueLabel(selectedMax, valueFormat)}`;
    }

    return `${getFormattedValueLabel(
      selectedMin,
      valueFormat,
    )} to ${getFormattedValueLabel(selectedMax, valueFormat)}`;
  }, [name, value, min, max, valueFormat]);

  const handleChange = useCallback(
    (_event: Event, newValue: number | number[]) => {
      if (viewOnly) {
        return; // Prevent any changes in viewOnly mode
      }

      if (valueFormat === 'date') {
        if (Array.isArray(newValue)) {
          onChange([
            transformLogToValue(newValue[0], min, max),
            transformLogToValue(newValue[1], min, max),
          ]);
        } else {
          onChange(transformLogToValue(newValue, min, max));
        }
      } else {
        onChange(newValue);
      }
    },
    [valueFormat, min, max, onChange, viewOnly],
  );

  const sliderValue = useMemo(() => {
    return valueFormat === 'date'
      ? Array.isArray(value)
        ? [
            transformValueToLog(value[0], min, max),
            transformValueToLog(value[1], min, max),
          ]
        : transformValueToLog(value as number, min, max)
      : value;
  }, [value, valueFormat, min, max]);

  return (
    <Stack gap={1}>
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            color: theme.palette.text.secondary,
          }}
        >
          {header}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.primary,
          }}
        >
          {getSliderInfo()}
        </Typography>
      </Stack>
      <Slider
        value={sliderValue}
        onChange={handleChange}
        min={min}
        max={max}
        disabled={viewOnly} // Disable the slider in view-only mode
        sx={{
          color: viewOnly
            ? theme.palette.action.disabled // Discolored when disabled
            : theme.palette.sgGreen,
          height: 8,
          '& .MuiSlider-thumb': {
            cursor: viewOnly ? 'default' : 'ew-resize', // Disable cursor change in viewOnly mode
            height: viewOnly ? 0 : 20, // Hide thumb in view-only mode
            width: viewOnly ? 0 : 8, // Hide thumb in view-only mode
            borderRadius: '4px',
            '&:focus, &:hover, &.Mui-active': {
              boxShadow: 'inherit',
            },
          },
          '& .MuiSlider-track': {
            border: 'none',
            backgroundColor: viewOnly
              ? theme.palette.action.disabled // Discolored track
              : theme.palette.sgGreen,
          },
          '& .MuiSlider-rail': {
            opacity: viewOnly ? 0.3 : 0.5, // Lighter rail in view-only mode
          },
        }}
      />
    </Stack>
  );
};

export default SliderFilter;
