import { PreferencesTab } from 'types';
import { dayjs, ET, getCachedToken, isBloomberg } from '../util';
import { Dayjs } from 'dayjs';

export const EDITABLE_ACCOUNT_DETAILS = isBloomberg()
  ? []
  : [
      {
        field: 'first_name',
        label: 'First Name',
        type: 'string',
      },
      {
        field: 'last_name',
        label: 'Last Name',
        type: 'string',
      },
      {
        field: 'email',
        label: 'Email',
        type: 'email',
      },
    ];

export const PREFERENCES_LABEL_MAPPING = new Map(
  isBloomberg()
    ? [
        [PreferencesTab.Account, 'Account'],
        [PreferencesTab.Colors, 'Colors'],
        [PreferencesTab.Alerts, 'Alerts'],
      ]
    : [
        [PreferencesTab.Account, 'Account'],
        [PreferencesTab.Colors, 'Colors'],
        [PreferencesTab.Payments, 'Payments'],
        [PreferencesTab.Watchlists, 'Watchlists'],
        [PreferencesTab.Alerts, 'Alerts'],
      ],
);

// putting everything in one place so it can be easily removed later

type TokenTimeframe = {
  minDate: Dayjs;
  maxDate: Dayjs;
  token: string;
};

export const TRACE_TOKEN_OVERRIDES: TokenTimeframe[] = [
  {
    minDate: dayjs.tz('2024-09-17 09:00', ET),
    maxDate: dayjs.tz('2024-09-25 00:00', ET),
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJTcG90R2FtbWEiLCJ1c2VyX2lkIjoiOThlM2IzYzMtODExMy00ZGIzLTg3MGItMDA2ZTJiZWEwNzI2IiwiaXNfaW5zdGl0dXRpb25hbCI6bnVsbCwid3BJRCI6MTY2MTEsImVtYWlsIjoiZW5nK2FscGhhQHNwb3RnYW1tYS5jb20iLCJtZW1iZXJzaGlwcyI6WyJBbHBoYSJdLCJyb2xlcyI6WyJzdWJzY3JpYmVyIl0sInVzZXJUeXBlIjoxLCJ3cFRva2VuIjp7ImFjY2Vzc190b2tlbiI6InIxZ2l1NWUwbm96cXVjdGR0YnZoZWducG93M3VscGJjb3BoYmtkeG8iLCJleHBpcmVzX2luIjoyNTkyMDAsInRva2VuX3R5cGUiOiJCZWFyZXIiLCJzY29wZSI6ImJhc2ljIiwicmVmcmVzaF90b2tlbiI6InZmc2l4MjM4bGhnZjdibHhodXJydGY1eTY2a2hwbmJmeWt1dDBjZDEifSwiaWF0IjoxNzI2NTE4MzU0LCJleHAiOjE3MjcyMzY5MTR9.ZYOs_t61N4ULc-a4emu76TKXVIJKmZdlCDCyVQgiyQA',
  },
  {
    minDate: dayjs.tz('2024-09-25 00:00', ET),
    maxDate: dayjs.tz('2024-09-28 00:00', ET),
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJTcG90R2FtbWEiLCJ1c2VyX2lkIjoiOThlM2IzYzMtODExMy00ZGIzLTg3MGItMDA2ZTJiZWEwNzI2IiwiaXNfaW5zdGl0dXRpb25hbCI6bnVsbCwid3BJRCI6MTY2MTEsImVtYWlsIjoiZW5nK2FscGhhQHNwb3RnYW1tYS5jb20iLCJtZW1iZXJzaGlwcyI6WyJBbHBoYSJdLCJyb2xlcyI6WyJzdWJzY3JpYmVyIl0sInVzZXJUeXBlIjoxLCJ3cFRva2VuIjp7ImFjY2Vzc190b2tlbiI6InIxZ2l1NWUwbm96cXVjdGR0YnZoZWducG93M3VscGJjb3BoYmtkeG8iLCJleHBpcmVzX2luIjoyNTkyMDAsInRva2VuX3R5cGUiOiJCZWFyZXIiLCJzY29wZSI6ImJhc2ljIiwicmVmcmVzaF90b2tlbiI6InZmc2l4MjM4bGhnZjdibHhodXJydGY1eTY2a2hwbmJmeWt1dDBjZDEifSwiaWF0IjoxNzI2NTE4MzU0LCJleHAiOjE3Mjc0OTYwNTR9.qnNeNx94qFt9i4xMy4-wZ8OPiBOIZB5Xrp_v2AJkWxM',
  },
];

const isInTraceOverrideTimeframe = (
  time: Dayjs,
  traceOverride: TokenTimeframe,
) => {
  return time.isBetween(traceOverride.minDate, traceOverride.maxDate);
};

export const isValidTraceTimeframe = () => {
  const nowET = dayjs().tz(ET);

  return TRACE_TOKEN_OVERRIDES.some((traceOverride) =>
    isInTraceOverrideTimeframe(nowET, traceOverride),
  );
};

export const getUserTraceToken = (hasAlphaAccess = false) => {
  if (hasAlphaAccess) {
    return getCachedToken(); // No need for the special token since user already has alpha access
  }

  const nowET = dayjs().tz(ET);

  const validOverride = TRACE_TOKEN_OVERRIDES.find((traceOverride) =>
    isInTraceOverrideTimeframe(nowET, traceOverride),
  );

  if (validOverride) {
    return validOverride.token;
  }

  return getCachedToken(); // Falls outside the valid timeframe so return the regular user cached token if any
};
