import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  SxProps,
  Theme,
  useTheme,
  SelectChangeEvent,
  alpha,
  Typography,
} from '@mui/material';

interface Option {
  label: string;
  value: string;
}

interface OptionsDropdownSelectorProps {
  label: string; // Label for the input field
  sx?: SxProps<Theme>; // Optional styles for the FormControl
  value: string | string[]; // Selected value(s)
  options: Option[]; // List of options
  isMultiple?: boolean;
  onChange: (event: SelectChangeEvent<string | string[]>) => void; // Event handler for value(s) change
  viewOnly?: boolean; // View-only mode
}

const OptionsDropdownSelector = ({
  label,
  sx,
  value,
  isMultiple,
  options,
  onChange,
  viewOnly = false, // default is false for normal interaction
}: OptionsDropdownSelectorProps) => {
  const theme = useTheme();

  // Create a display for selected values in view-only mode
  const renderSelectedValues = () => {
    if (isMultiple) {
      return (value as string[])
        .map((v) => options.find((o) => o.value === v)?.label)
        .filter(Boolean)
        .join(', ');
    }
    return options.find((o) => o.value === value)?.label || '';
  };

  return (
    <FormControl
      sx={{
        maxWidth: 160,
        width: '100%',
        ...sx, // Allow overriding styles
      }}
      size="small"
      variant="outlined"
    >
      {viewOnly ? (
        // In view-only mode, display the label above the field
        <Typography
          sx={{
            fontSize: 12,
            color: theme.palette.text.secondary,
            marginBottom: '8px',
          }}
        >
          {label}
        </Typography>
      ) : (
        // Show InputLabel in editable mode
        <InputLabel
          id={`${label}-select-label`}
          sx={{
            fontSize: 12, // Reduce font size
            color: theme.palette.primary.main,
            transition: '0.2s ease', // Smooth transition for the label
            '&:hover': {
              color: theme.palette.primary.dark,
            },
          }}
        >
          {label}
        </InputLabel>
      )}

      {viewOnly ? (
        // View-only mode: Display selected values as text
        <Typography
          sx={{
            height: 32,
            padding: '0 10px',
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: alpha(theme.palette.primary.main, 0.05),
            borderRadius: 2,
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {renderSelectedValues() || 'No selection'}
        </Typography>
      ) : (
        // Editable mode: Show dropdown select
        <Select
          labelId={`${label}-select-label`}
          id={`${label}-select`}
          multiple={isMultiple}
          value={value}
          onChange={onChange}
          input={
            <OutlinedInput
              label={label}
              sx={{
                height: 32,
                padding: '0 10px',
                fontSize: 12,
                svg: {
                  color: theme.palette.primary.main,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.primary.main,
                },
              }}
            />
          }
          renderValue={(selected) =>
            isMultiple
              ? (selected as string[])
                  .map((v) => options.find((o) => o.value === v)?.label)
                  .filter(Boolean)
                  .join(', ')
              : selected
          }
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '200px',
              },
            },
          }}
        >
          {options.length > 0 ? (
            options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  padding: '8px',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.2), // Subtle hover effect
                  },
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.action.selected, // Highlight the selected item
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: theme.palette.action.selected, // Maintain selected color on hover
                  },
                }}
              >
                {isMultiple && (
                  <Checkbox
                    checked={(value as string[]).indexOf(option.value) > -1}
                    style={{
                      padding: '0 8px 0 0',
                    }}
                  />
                )}

                <ListItemText
                  primary={option.label}
                  primaryTypographyProps={{
                    fontSize: '12px',
                  }}
                />
              </MenuItem>
            ))
          ) : (
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                fontSize: 14,
                pl: '4px',
              }}
            >
              No items
            </Typography>
          )}
        </Select>
      )}
    </FormControl>
  );
};

export default OptionsDropdownSelector;
