import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { Equity, HistoricalEquity, ProductType } from '../../../types';
import usePrices from '../../../hooks/equityhub/usePrices';
import {
  FullscreenBottomDrawerButton,
  InfoButton,
  UpsellModal,
} from '../../shared';
import {
  ehActiveScannerState,
  ehActiveWatchlistsIdsState,
  ehScannerColumnOrderState,
  ehScannerColumnsVisibilityState,
  ehScannerFilterModelState,
  ehScannerPinnedColumnsState,
  ehUseDefaultColumnsState,
  equityQuantilesState,
  pricesState,
  selectedEquitySymbolState,
  synthesizedEquitiesState,
  unpricedEquitiesState,
} from '../../../states/equityhub';
import * as d3 from 'd3';
import { productAccessState, watchlistsState } from '../../../states';
import { Scanners } from './Scanners';
import {
  getColumnsForScanner,
  getEquitiesForScanner,
  hasKeyEquityFields,
  minVolumeThresholdFilter,
} from '../../../util';
import {
  EquityTableType,
  useEquityGridColumns,
} from './useEquityScannerGridColumns';
import {
  GridCallbackDetails,
  GridRowParams,
  MuiEvent,
} from '@spotgamma/x-data-grid-premium';
import {
  defaultColumnScannerVisibility,
  EQUITYHUB_UPSELL,
  scannerFields,
} from 'config';
import { EquityScannerGrid } from 'components/stock_scanner/EquityScannerGrid';
import { WatchlistMultiSelect } from 'components/shared/WatchlistMultiSelect';
import useActiveScanner from 'hooks/scanners/useActiveScanner';
import { useSetSym } from '../../../hooks';
import { Scanner } from '../../../types';
import { scannerSortModelState } from 'states/scanners';

export const EquityhubStockScanner = () => {
  const unpricedEquities = useRecoilValue(unpricedEquitiesState);
  const equities = useRecoilValue(synthesizedEquitiesState);
  const watchlists = useRecoilValue(watchlistsState);
  const setPrices = useSetRecoilState(pricesState);
  const setEquityQuantiles = useSetRecoilState(equityQuantilesState);
  const [activeWatchlistIds, setActiveWatchlistIds] = useRecoilState(
    ehActiveWatchlistsIdsState,
  );
  const [dpiRange, setDPIRange] = useState<number[] | undefined>(undefined);
  const products = useRecoilValue(productAccessState);
  const hasEquityhubAccess = useMemo(
    () => products.includes(ProductType.EQUITYHUB),
    [products],
  );
  const setSelectedSymbol = useSetRecoilState(selectedEquitySymbolState);
  const [upsellOpen, setUpsellOpen] = useState<boolean>(false);

  const useDefaultColumns = useRecoilValue(ehUseDefaultColumnsState);
  const setColumnVisibilityModel = useSetRecoilState(
    ehScannerColumnsVisibilityState,
  );
  const { setSym } = useSetSym();

  const { activeScanner } = useActiveScanner(ehActiveScannerState);

  const { getPrices } = usePrices();
  const theme = useTheme();

  const { columns: gridCols, columnGroups: gridColGroups } =
    useEquityGridColumns(
      theme,
      dpiRange,
      EquityTableType.StockScreener,
      !hasEquityhubAccess
        ? [...equities.values()]
            .filter((e) => !hasKeyEquityFields(e))
            .map((e) => e.sym)
        : undefined,
    );

  useEffect(() => {
    const data = [...equities.values()];
    const mFactorArray = data
      .map((c: Equity | HistoricalEquity) => c.activity_factor)
      .sort(d3.ascending);
    const vFactorArray = data
      .map((c: Equity | HistoricalEquity) => c.position_factor)
      .sort(d3.ascending);
    const minVFactor = d3.quantile(mFactorArray, 0.1);
    const maxVFactor = d3.quantile(mFactorArray, 0.9);
    const minMFactor = d3.quantile(vFactorArray, 0.1);
    const maxMFactor = d3.quantile(vFactorArray, 0.9);
    const dpiArray = data.map((d: Equity | HistoricalEquity) => d.dpi);

    setDPIRange([Math.min(...dpiArray), Math.max(...dpiArray)]);
    setEquityQuantiles({
      positionMin: minVFactor!,
      positionMax: maxVFactor!,
      activityMin: minMFactor!,
      activityMax: maxMFactor!,
    });
  }, [equities, setEquityQuantiles]);

  useEffect(() => {
    if (useDefaultColumns) {
      setColumnVisibilityModel(
        activeScanner
          ? getColumnsForScanner(activeScanner)
          : defaultColumnScannerVisibility,
      );
    }
  }, [useDefaultColumns, activeScanner]);

  const scannerData = useMemo(() => {
    // filters data by search/scanner
    let resultData = [...equities.values()];

    if (activeWatchlistIds.length > 0) {
      const activeWatchlists =
        watchlists?.filter((w) =>
          activeWatchlistIds.includes(w.id as number),
        ) ?? [];

      const syms: Set<string> = new Set(resultData.map((w) => w.sym));
      const watchlistSyms = new Set(activeWatchlists.flatMap((w) => w.symbols));

      resultData = [...watchlistSyms]
        .filter((s) => syms.has(s))
        .map((s) => equities.get(s) as Equity);
    }

    if (activeScanner) {
      // Apply default filters of (call vol > 5k) and (total options vol > 10k)
      resultData = getEquitiesForScanner(activeScanner, resultData);
      if (activeScanner !== Scanner.CROSS_ASSET_SUMMARY) {
        resultData = resultData.filter(minVolumeThresholdFilter);
      }
    }

    return hasEquityhubAccess
      ? resultData
      : resultData.slice().sort((a, b) => {
          const aFull = hasKeyEquityFields(a) ? 1 : 0;
          const bFull = hasKeyEquityFields(b) ? 1 : 0;
          return bFull - aFull;
        });
  }, [
    activeScanner,
    activeWatchlistIds,
    watchlists,
    equities,
    hasEquityhubAccess,
  ]);

  useEffect(() => {
    async function fetchVisiblePriceData() {
      const newPrices: Map<string, number> = await getPrices([]);
      setPrices((prices) => new Map([...prices, ...newPrices]));
    }
    fetchVisiblePriceData();
  }, [getPrices, setPrices, unpricedEquities]);

  const setSelectedRow = (row: Equity) => {
    const newSym = row.sym;
    setSelectedSymbol(newSym);
    setSym(newSym, ProductType.EQUITYHUB);
  };

  const isRowDisabled = (row: Equity) => {
    return !hasEquityhubAccess && !row.live;
  };

  const onRowClick = (
    params: GridRowParams,
    _event: MuiEvent,
    _details: GridCallbackDetails,
  ) => {
    const row = params.row as Equity;
    if (isRowDisabled(row)) {
      setUpsellOpen(true);
    } else {
      setSelectedRow(row);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
        height: '100%',
        minWidth: '100%',
        margin: 'auto',
        padding: 3,
        minHeight: 400,
      }}
    >
      <EquityScannerGrid
        columns={gridCols}
        data={scannerData}
        columnGroups={gridColGroups}
        onRowClick={onRowClick}
        activeScanner={activeScanner}
        useDefaultColumnState={ehUseDefaultColumnsState}
        columnVisibilityModelState={ehScannerColumnsVisibilityState}
        scannerPinnedColumnsState={ehScannerPinnedColumnsState}
        scannerSortModelState={scannerSortModelState}
        filterModelState={ehScannerFilterModelState}
        activeWatchlistIdsState={ehActiveWatchlistsIdsState}
        columnOrderState={ehScannerColumnOrderState}
        defaultColumnOrder={scannerFields}
        customSettings={
          <Box>
            <InfoButton key="eh-levels-info" articleKey="eh-levels-info" />
            <WatchlistMultiSelect
              activeWatchlistIds={activeWatchlistIds}
              setActiveWatchlistIds={setActiveWatchlistIds}
            />
            <FullscreenBottomDrawerButton />
          </Box>
        }
        customScannerSettings={<Scanners />}
      />
      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={EQUITYHUB_UPSELL.title}
        subtitle={EQUITYHUB_UPSELL.subtitle}
        items={EQUITYHUB_UPSELL.items}
      />
    </Box>
  );
};
